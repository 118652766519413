export default {
  app_name: "WebControle",
  "pagina.man": "Manutenção",
  "pagina.gerar": "Gerar",
  "pagina.home": "Home",
  "pagina.cadastro": "Cadastro",
  "pagina.setor": "Setor",
  "pagina.departamento": "Departamento",
  "pagina.subdepartamento": "Subdepartamento",
  "pagina.local": "Local",
  "pagina.servico": "Serviço",
  "pagina.taxa": "Taxa",
  "pagina.unidade": "Unidade",
  "pagina.grupo_material": "Grupo material",
  "pagina.material": "Material",
  "pagina.bairro": "Bairro",
  "pagina.conta": "Conta",
  "pagina.forma_pagamento": "Forma de pagamento",
  "pagina.executado": "Serviço executado",
  "pagina.funcao": "Função",
  "pagina.alocacao": "Alocação",
  "pagina.pessoa": "Pessoa",
  "pagina.empresa": "Empresa",
  "pagina.indice": "Índice",
  "pagina.indice_valor": "Índice valor",
  "pagina.plano_conta": "Plano de conta",

  "pagina.movimentacao": "Movimentação",
  "pagina.movimento": "Lançamento",
  "pagina.previsto": "Previsão",
  "pagina.fechamento": "Previsão fechamento",
  "pagina.financeiro": "Financeiro",
  "pagina.titulo": "Título",
  "pagina.contrato_recebimento": "Contrato de recebimento",
  "pagina.gerencial": "Gerencial",
  "pagina.alocacao_unidade": "Alocação unidade",
  "pagina.relatorio": "Relatório",
  "pagina.acompanhamento_obra": "Acompanhamento de obra",
  "pagina.diario": "Diário",
  "pagina.solicitacao_material": "Solicitação material",
  "pagina.solicitacao": "Solicitação",
  "pagina.pedido": "Pedido",
  "pagina.cotacao": "Cotação",
  "pagina.acompanhamento_documentos": "Documentos",
  "pagina.compra": "Compra",
  "pagina.compra_solicitacao": "Solicitação",
  "pagina.compra_pedido": "Pedido",
  "pagina.acesso_grupo": "Acesso grupo",
  "pagina.permissao": "Permissão",
  "pagina.usuario": "Usuário",
  "pagina.manutencao": "Manutenção",
  "pagina.contrato": "Contratos",
  "pagina.despesa": "Despesa",
  "pagina.empreendimento": "Empreendimentos",
  "pagina.aprovacao": "Aprovação",
  "pagina.aprovacoes": "Aprovações",
  "pagina.perfil": "Perfil",

  "pagina.fisico_financeiro": "Físico Financeiro",
  "pagina.fisico_financeiro_fechamento": "Físico Financeiro Fechamento",
  "pagina.movimento_quantidade": "Lançamento com Qtde",
  "pagina.recebimento": "Recebimento",
  "pagina.pagamento": "Pagamento",
  "pagina.transacao": "Transação",

  "pagina.relatorio.fisico_financeiro": "Físico Financeiro",
  "pagina.relatorio.fisico_financeiro_fechamento": "Físico Financeiro Fechamento",
  "pagina.relatorio.movimento": "Lançamento",
  "pagina.relatorio.movimento_quantidade": "Lançamento com Qtde",
  "pagina.relatorio.recebimento": "Recebimento",
  "pagina.relatorio.contrato_recebimento": "Contrato recebimento",
  "pagina.relatorio.pagamento": "Pagamento",
  "pagina.relatorio.transacao": "Transação",
  "pagina.relatorio.despesa": "Despesas",
  "pagina.relatorio.despesa_title": "Despesas mensais",

  "label.carregando": "Carregando...",
  username: "User name",
  "overview.title": "Overview",
  "label.acessar": "Acessar",
  "label.acessar_sistema": "Acessar sistema",
  "label.lembrar_email": "Lembrar e-mail",
  "label.esqueci_senha": "Esqueci minha senha",
  "topo.title": "Topo",
  "validacao.invalido": "Valor informado é inválido",
  "validacao.cpf-invalido": "CPF informado é inválido",
  "validacao.cnpj-invalido": "CNPJ informado é inválido",
  "validacao.required": "Campo é obrigatório",
  "validacao.maior_zero": "Valor inválido (Valor deve ser maior que 0).",
  "validacao.minLength": "Caractere(s) mínimo permitido ({min})",
  "validacao.maxLength": "Caractere(s) máximo permitido ({max})",
  "validacao.min": "Valor mínimo inválido ({min})",
  "validacao.max": "Valor máximo inválido ({max})",
  "validacao.email": "E-mail inválido",
  "validacao.number": "Valor numérico inválido",
  "validacao.int": "Valor inválido, digite um valor inteiro",
  "validacao.documento_invalido": "Número de documento inválido",
  "validacao.previsao_valor_required": "É obrigatório informar valores para previsão",
  "validacao.movimento_itens_required": "É obrigatório informar itens para o movimento",
  //
  "label.condicao.equals": "Igual",
  "label.condicao.notequals": "Diferente",
  "label.condicao.in": "Entre",
  "label.condicao.gt": "Maior",
  "label.condicao.lt": "Menor",
  "label.condicao.gte": "Maior ou igual",
  "label.condicao.lte": "Menor ou igual",
  "label.condicao.like": "Contém",
  "label.condicao.startswith": "Começa com",
  "label.condicao.endswith": "Termina com",
  "label.condicao.icontains": "iContém",
  "label.condicao.istartswith": "iComeça com",
  "label.condicao.iendswith": "iTermina com",
  "label.condicao.after": "Depois",
  "label.condicao.before": "Antes",
  //
  "label.botao.salvar": "Salvar",
  "label.botao.salvar_voltar": "Salvar e voltar",
  "label.botao.gerar_pedido": "Gerar pedido",
  "label.botao.importar_xml_nfe": "Importar XML NFe",
  "label.botao.novo": "Novo",
  "label.botao.nova_cotacao": "Nova cotação",
  "label.botao.cotacao_existente": "Cotação existente",
  "label.botao.novo_item": "Novo Item",
  "label.botao.adicionar": "Adicionar",
  "label.botao.alterar": "Alterar",
  "label.botao.excluir": "Excluir",
  "label.botao.excluir_materiais": "Excluir materiais",
  "label.botao.cancelar_fechamento": "Cancelar fechamento",
  "label.botao.visualizar_fechamento": "Visualizar fechamento",
  "label.botao.gerar": "Gerar",
  "label.botao.detalhes": "Detalhes",
  "label.botao.confirmar": "Confirmar",
  "label.botao.aprovar": "Aprovar",
  "label.botao.recusar": "Recusar",
  "label.botao.cancelar": "Cancelar",
  "label.botao.limpar": "Limpar",
  "label.botao.voltar": "Voltar",
  "label.botao.fechar": "Fechar",
  "label.botao.acessar": "Acessar",
  "label.botao.exportar": "Exportar",
  "label.botao.exportar_detalhado": "Exportar detalhado",
  "label.botao.filtro": "Filtro",
  "label.botao.filtrar": "Filtrar",
  "label.botao.atualizar": "Atualizar",
  "label.botao.proximo": "Próximo",
  "label.botao.download": "Download",
  "label.botao.alterar_itens_selecionado_parcela": "Alterar parcelas selecionadas",
  //
  "label.message_data_fechamento_invalida": "Data de fechamento inválida",
  "label.message_data_maios_data_hora_atual": "Data e hora não pode ser superior a data e hora atual",
  "label.message_cep_invalido": "CEP inválido ou não informado",
  "label.mensagem_sucesso": "Registro salvo com sucesso",
  "label.mensagem_excluir_sucesso": "Registro excluído com sucesso",
  "label.mensagem_identificador_nao_informado": "Registro não pode ser alterado. Identificador não informado",
  "label.mensagem.item_edicao": "Foi identificado alteração no registro. Deseja continuar alterando o registro?",
  "label.mensagem.titulo_maior_total_movimento":
    "Valor título {tTitulo} diferente do valor total do lançamento {tMovimento}. Manter valores informado?",
  "label.mensagem.titulo_diferente_total":
    "Valor da soma do(s) título(s) {tTitulo} é diferente do valor total esperado {tTotal}.",
  "label.mensagem.valor_e_valor_pago_diferente":
    "Valor pagamento {tPag} diferente do valor total do título {tValor}. Concluir pagamento com valores informado?",
  "label.mensagem.materiais_selecionados": "Materiais selecionados",
  "label.mensagem.deletar": "Confirmar exclusão do registro?",
  "label.mensagem.apos_deletar": "Após exclusão registros não poderão ser restaurados.",
  "label.mensagem.situacao_titulo":
    "Situação do título: Pendente = Não houve pagamento | Parcial = Já foi incluído algum valor de pagamento | Pago = Valor do pagamento é maior ou igual ao valor do título",
  "label.mensagem.informar_alterar_senha": "Informe somente se desejar alterar a senha atual",
  "label.mensagem.acessar_sistema": "Acessar sistema",
  "label.mensagem.lembrar_email": "Lembrar e-mail",
  "label.mensagem.esqueci_senha": "Esqueci minha senha",
  "label.mensagem.email_usuario": "E-mail do usuário",
  "label.mensagem.identificacao_empresa": "Identificação empresa",
  "label.mensagem.vencimento_apos_data": "Data não pode ser maior que o vencimento do título",
  "label.mensagem.valor_referencia_nao_localizado": "Valor índice não localizado para essa referência",
  "label.mensagem.alterar_itens_selecionado_parcela":
    "Permite alterar a descrição (Parcela) dos pagamentos selecionadas. Para incluir valor incremental informe o caractere # na posição (Ex. Parcela #)",
  "label.mensagem.ajuste_total_ultimo_item":
    "Total dos itens diferente do total geral, acerto aplicado no útilmo item da lista.",
  "label.mensagem.ajuste_total_contrato_diferente_pagamento":
    "Valor contrato diferente do valor total do(s) pagamento(s). Para salvar com valores diferentes informar 'Sim' para o campo 'Lançar itens futuro'.",
  "label.mensagem.atualizar_valor_contrato": "Confirmar atualização do valor do contrato?",
  "label.mensagem.remover_fechamento_contrato_recebimento_item": "Confirmar Cancelamento do contrato de recebimento?",
  "label.mensagem.alocacao_padrao": "Quando for alteração, será selecionada a alocação do primeiro item.",
  "label.mensagem.alocacao_nao_informada": "É necessário informar uma alocação para continuar.",
  "label.mensagem.informe_uma_alocacao": "Informe somente uma alocação para continuar.",
  "label.mensagem.salvar_enviar_email_fornecedor": "Salvar e enviar e-mail da solicitação para o fornecedor.",
  "label.mensagem.salvar_gerar_impressao_fornecedor": "Salvar e gerar impressão da solicitação.",
  "label.mensagem.excluir_fornecedor_solicitação": "Confirmar exlcusão do fornecedor desta solicitação.",
  "label.mensagem.informe_valor_para_selecionar": "Informe o valor da cotação do item para seleciona-lo.",
  "label.mensagem.numero_documento_invalido": "Número de documento inválido.",
  "label.mensagem.gerar_pedido_item_nao_selecionado": "Existe(m) produto(s) não selecionado(s). Deseja continuar?",
  "label.mensagem.gerar_pedido_item_nao_selecionado_descricao":
    "Os produtos não selecionados voltarão para solicitação e só poderão ser incluídos em uma nova  cotação.",
  "label.mensagem.nao_pode_pular_etapa": "Existem entapas anteriores pendentes",
  "label.alterar_adicionar_observacao": "Adicionar/Alterar observação",
  "label.informe_valor": "Informe um valor",
  "label.informe_descricao": "Informe uma descrição",
  "label.manutencao_solicitacao_material": "Manutenção solicitação material",
  "label.manutenao_item": "Manutenção item",
  "label.manutenao_titulo": "Manutenção título",
  "label.manutenao_previsto": "Manutenção previsto",
  "label.manutenao_contrato_recebimento_item": "Manutenção contrato recebimento item",
  "label.minhas_aprovacoes": "Minhas aprovações",
  "label.sair": "Sair",
  "label.tipo_busca_material": "Tipo de busca",
  "label.sn_": " ",
  "label.sn_S": "Sim",
  "label.sn_N": "Não",
  "label.sim": "Sim",
  "label.nao": "Não",
  "label.situacao.Andamento": "Andamento",
  "label.situacao.Lançamento": "Lançamento",
  "label.situacao.Concluído": "Concluído",
  "label.carregar": "Carregar",
  "label.carregar_imagens": "Carregar imagens",
  "label.todas_imagens": "Todas imagens",
  "label.exibir_imagem": "Exibir imagem",
  "label.detalhes_relatorio": "Detalhes do relatório",
  "label.imagem_nao_selecionada": "Nenhuma imagem foi selecionada",
  "label.imagem_pre_visualizacao": "Pré visualização",
  "label.tipo_arquivo_nao_permitido": "Arquivo não permitido. Somente imagem é permitido.",
  "label.tamanho_arquivo_nao_permitido": "Tamanho do arquivo excede limite máximo",
  "label.area_upload": "Clique ou arraste o arquivo para esta área",
  "label.area_upload_single": "Suporte para carregar um único arquivo. Carregar somente imagens",
  "label.validando_acesso": "Validando acesso...",
  "label.atencao": "Atenção",
  "label.sucesso": "Sucesso",
  "label.geral": "Geral",
  "label.aprovacao": "Aprovação",
  "label.perfil": "Perfil",
  "label.adicionar_servico_executado": "Adicionar serviço executado",
  "label.adicionar_andamento": "Adicionar andamento",
  "label.andamento": "Andamento",
  "label.ativo": "Ativo",
  "label.inativo": "Inativo",
  "label.fisica": "Física",
  "label.juridica": "Jurídica",
  "label.tipo_item": "Tipo item",
  "label.descricao_item": "Descrição",
  "label.formato_referencia_m_A": "YYYY",
  "label.formato_referencia_m_MA": "MM/YYYY",
  "label.formato_referencia_m_DMA": "DD/MM/YYYY",
  "label.formato_referencia_": " ",
  "label.formato_referencia_A": "AAAA",
  "label.formato_referencia_MA": "MM/AAAA",
  "label.formato_referencia_DMA": "DD/MM/AAAA",
  "label.previsao_gastos": "Previsão dos gastos",
  "label.valor_previsao": "Valores previsões",
  "label.valor_pagamento": "Valor pagamento",
  "label.data_pagamento": "Data pagamento",
  "label.boletos": "Boletos",
  "label.tipo-": " ",
  "label.tipo-P": "Pagar",
  "label.tipo-R": "Receber",
  "label.tipo-C": "Cédito",
  "label.tipo-D": "Débito",
  "label.situacao-A": "Ativo",
  "label.situacao-I": "Inativo",
  "label.situacao-PENDENTE": "Pendente",
  "label.situacao-PARCIAL": "Parcial",
  "label.situacao-PAGO": "Pago",
  "label.cor": "Cor",
  "label.pago": "Pago",
  "label.parcial": "Pagamento parcial",
  "label.aguardando": "Aguardando",
  "label.pendente": "Pendente",
  "label.hoje": "Hoje",
  "label.origem": "Origem",
  "label.filtro_opcao": "Aplicar filtros",
  "label.filtros_aplicados": "Filtros aplicados",
  //
  "label.selecionados": "Selecionados",
  "label.cotar_fornecedor": "Cotação fornecedor",
  "label.enviar_email": "Enviar por e-mail",
  "label.gerar_arquivo": "Gerar arquivo",
  "label.valor_previsto": "Valor previsto",
  "label.valor_realizado": "Valor realizado",
  "label.percentual_gastos_valor": "% Gasto valor",
  "label.percentual_gastos_indice": "% Gasto índice",
  "label.senha": "Senha",
  "label.data": "Data",
  "label.empresa_id": "Empresa",
  "label.id": "#ID",
  "label.calendario": "Calendário",
  "label.empresa_alocacao": "Empresa / Alocação",
  "label.empresa": "Empresa",
  "label.indice": "Índice",
  "label.indice_orcado": "Índice orçado",
  "label.indice_realizado": "Índice realizado",
  "label.indice_orcado_fechado": "Índice orçado fechado",
  "label.indice_orcado_sem_fechamento": "Índice orçado sem fechamento",
  "label.indice_pos_fechamento": "Índice após fechamento",
  "label.indice_id": "Índice",
  "label.indice_referencia": "Referência",
  "label.indice_total_sum": "Total índice",
  "label.indice_valor": "Valor índice",
  "label.movimento_itens": "Itens",
  "label.pagamentos": "Pagamentos",
  "label.numero": "Número",
  "label.movimento_numero_nota": "Número nota",
  "label.alocacao": "Alocação",
  "label.alocacao_padrao": "Alocação padrão",
  "label.alocacao_id": "Alocação",
  "label.evento": "Evento",
  "label.observacao": "Observação",
  "label.pessoa": "Fornecedor/Cliente",
  "label.pessoa_id": "Fornecedor/Cliente",
  "label.titulos": "Títulos",
  "label.total_sum": "Total",
  "label.total_fechado": "Total fechado",
  "label.valor_fechado": "Valor fechado",
  "label.total": "Total",
  "label.indice_total": "Índice total",
  "label.created_at": "Criado em",
  "label.updated_at": "Alterado em",
  "label.descricao": "Descrição",
  "label.historico": "Histórico",
  "label.material": "Material",
  "label.quantidade_compra": "Qtde. compra",
  "label.conversao_compra": "Conv. compra",
  "label.quantidade_movimento": "Qtde. movim.",
  "label.valor_unitario": "Valor unit.",
  "label.valor": "Valor",
  "label.valor_orcado_fechado": "Valor orçado fechado",
  "label.valor_orcado_sem_fechamento": "Valor orçado sem fechamento",
  "label.valor_pos_fechamento": "Valor após fechamento",
  "label.saldo": "Saldo",
  "label.posicao_saldo": "Posição saldo",
  "label.saldo_periodo": "Saldo no período",
  "label.total_recebimento": "Total recebimento",
  "label.desconto": "Desconto",
  "label.acrescimo": "Acréscimo",
  "label.desconto_acerto": "Desconto acerto",
  "label.acrescimo_acerto": "Acréscimo acerto",
  "label.valor_pago": "Valor pago",
  "label.unidade_abr": "Un.",
  "label.unidade": "Unidade",
  "label.unidade_solicitada_abr": "Un. sol.",
  "label.unidade_solicitada": "Unidade solicitada",
  "label.unidade_x_solicitaca": "Unidade / solicitada",
  "label.plano_conta": "Plano de conta",
  "label.numero_fatura": "Nº Fatura",
  "label.vencimento": "Vencimento",
  "label.previsto_fechamento_alocacao": "Previsto data fechamento",
  "label.previsto": "Previsão",
  "label.local": "Local",
  "label.servico": "Serviço",
  "label.taxa": "Taxa",
  "label.total_titulos": "Total títulos",
  "label.total_pagamento": "Total pagamento",
  "label.setor": "Setor",
  "label.departamento": "Departamento",
  "label.subdepartamento": "Subdepartamento",
  "label.nome": "Nome",
  "label.nome_fantasia": "Nome fantasia",
  "label.grupo_material": "Grupo material",
  "label.estoque_minimo": "Estoque mínimo",
  "label.estoque_maximo": "Estoque máximo",
  "label.cep": "CEP",
  "label.endereco": "Endereço",
  "label.complemento": "Complemento",
  "label.bairro": "Bairro",
  "label.cidade": "Cidade",
  "label.situacao": "Situação",
  "label.situacao_financeiro": "Situação financeiro",
  "label.tipo": "Tipo",
  "label.cpf_cnpj": "CPF/CNPJ",
  "label.rg_ie": "RG/IE",
  "label.telefone": "Telefone",
  "label.celular": "Celular",
  "label.email": "E-mail",
  "label.site": "Site",
  "label.formato_referencia": "Formato de referência",
  "label.fixo": "Fixo",
  "label.referencia": "Referência",
  "label.previsao_inicio": "Prev. início",
  "label.previsao_termino": "Prev. término",
  "label.valor_orcado": "Valor orçado",
  "label.indice_valor_orcado": "Índice orçado",
  "label.indice_total_orcado": "Índice total orçado",
  "label.conta": "Conta",
  "label.forma_pagamento": "Forma de pagamento",
  "label.codigo_pagamento": "Código de pagamento",
  "label.identificacao": "Identificação",
  "label.alocacao_unidade": "Alocação unidade",
  "label.parcelas": "Nº. Parcela",
  "label.decimais": "Casas decimais",
  "label.parcela": "Parcela",
  "label.tab_parcelas": "Parcelas",
  "label.documentos": "Documentos",
  "label.valor_indice": "Valor índice",
  "label.valor_indice_contrato": "Valor contrato",
  "label.valor_indice_parcela": "Valor parcela",
  "label.data_indice_fechamento": "Data fechamento índice",
  "label.valor_indice_fechamento": "Valor fechamento índice",
  "label.data_fechamento": "Data fechamento",
  "label.valor_fechamento": "Valor fechamento",
  "label.itens_futuro": "Lançar itens futuro",
  "label.valor_pendente": "Valor pendente",
  "label.valor_indice_contrato_posicao_quitar": "Quitar unidade",
  "label.valor_indice_contrato_posicao": "Ficar em dia",
  "label.indice_contrato": "Índice contrato",
  "label.indice_fechado": "Índice fechado",
  "label.indice_pago": "Índice pago",
  "label.dia": "Dia",
  "label.acoes": "Ações",
  "label.encarregado": "Encarregado",
  "label.clima_manha": "Clima manhã",
  "label.clima_tarde": "Clima tarde",
  "label.nao_informado": "::Não informado::",
  "label.clima-": "::Não informado::",
  "label.clima-SOL": "SOL",
  "label.clima-CHUVA": "CHUVA",
  "label.clima-NUBLADO": "NUBLADO",
  "label.funcao": "Funções",
  "label.executado": "Serviços executados",
  "label.acesso_grupo": "Acesso grupo",
  "label.cotacao": "Cotação",
  "label.cotacoes": "Cotações",
  "label.solicitacao": "Solicitação",
  "label.solicitacoes": "Solicitações",
  "label.data_previsto": "Para",
  "label.data_entrega": "Entrega",
  "label.quantidade": "Quantidade",
  "label.quantidades": "Quantidades",
  "label.empreendimento": "Empreendimento",
  "label.codigo": "Código",
  "label.materiais": "Materiais",
  "label.comprador": "Comprador",
  "label.descricao_xml": "Descrição XML",
  "label.codigo_fornecedor": "Código fornecedor",
  "label.codigo_barra": "Código de barra",
  "label.buscar_fornecedor": "Buscar fornecedor",
  "label.fornecedores": "Fornecedores",
  "label.informacao_complementar": "Informação complementar",
  "label.selecionar": "Selecionar",
  "label.data_expiracao": "Expira em",
  "label.selecionado": "Selecionado",
  "label.buscar_material": "Buscar por material",
  "label.buscar_campo_livre": "Campo livre",
  "label.servico_executado_adicionado": "Serviço executado adicionado.",
  "label.fechar_janela_salvar": "Fechar janela após salvar?",
  "label.contrato_unidade_existente": "Contrato unidade existente",
  "label.parcelas_desbito_credito": "Parcelas + Débito - Crédito",
  "label.favoritos": "Favoritos",
  "label.detalhes": "Destalhes",
  "label.considerar_com_previsto_local": "Lançamento com Previsto e Local",
  "label.alterar_tipo_item": "Alterar tipo de item",
  "label.xml_carregado": "XML carregado com sucesso!",
  "label.erro_carregado_xml": "Não foi possível carregar o arquivo XML",
  "label.importar_xml_informar_empresa_alocacao_pessoa":
    "Para importar um arquivo XML é necessário informar Empresa, Alocação e Fornecedor/Cliente.",
  "label.existe_X_movimento_item_sem_vinculo":
    "Existem {total} itens sem vínculo. Altere os itens e informe um vículo para Material, Taxa ou Serviço.",
  "label.selecione_alocacao": "Selecione um alocação",
  "label.selecione_periodo": "Selecione um período",
  "label.relatorio_informacao_fisico_financeiro":
    "Neste relatório são cruzados os dados entre o que foi orçado com o que está sendo realizado durante a execução da obra.",
  "label.relatorio_informacao_despesa":
    "Este relatório apresenta as despesas que foram pagas no decorrer do um mês. Podendo consultar os gastos dos últimos três meses. Para mais consultas entrar em contato com o administrador.",
  "label.relatorio_informacao_movimento":
    "Este relatório apresenta em tempo real os 50 últimos movimentos realizados, sejam eles, impostos, compra de materiais ou execução de serviços. Para gerar um relatório com todos os movimentos, entre em contato com o administrador.",

  "label.inicio": "Início",
  "label.ir_inicio": "Ir para home",
  "label.usuairo_logado": "Usuário já está logado no sistema",
  "label.tags": "Tags",
  "label.sem_registro": "Nenhum registro foi localizado",
};
