import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiAlocacao, apiAlocacaoList } from 'src/services/funcoesService';
import { BACKEND_AGUARDANDO, BACKEND_ERRO, BACKEND_PROCESSANDO, BACKEND_SUCESSO, Ent_Alocacao } from 'src/services/interface';
import { AppThunk } from 'src/store';

export interface AlocacaoInitialState {
  actionAlocacaoList: BACKEND_AGUARDANDO | BACKEND_PROCESSANDO | BACKEND_SUCESSO | BACKEND_ERRO;
  actionAlocacao: BACKEND_AGUARDANDO | BACKEND_PROCESSANDO | BACKEND_SUCESSO | BACKEND_ERRO;
  errorAlocacaoList: string | null;
  errorAlocacao: string | null,
  alocacaoList: Ent_Alocacao[],
  alocacao: Ent_Alocacao | null,
}

const alocacaoInitialState: AlocacaoInitialState = {
  actionAlocacaoList: 'BACKEND_AGUARDANDO',
  actionAlocacao: 'BACKEND_AGUARDANDO',
  alocacaoList: [],
  alocacao: null,
  errorAlocacaoList: null,
  errorAlocacao: null
}

export const alocacaoSlice = createSlice({
  name: 'alocacao',
  initialState: alocacaoInitialState,
  reducers: {
    alocacaoResetAction(state) {
      state.actionAlocacao = 'BACKEND_AGUARDANDO';
      state.errorAlocacao = null;
    },
    alocacaoStart(state) {
      state.actionAlocacao = 'BACKEND_PROCESSANDO';
      state.errorAlocacao = null;
    },
    alocacaoSuccess(state, { payload }: PayloadAction<Ent_Alocacao>) {
      state.actionAlocacao = 'BACKEND_SUCESSO';
      state.alocacao = payload;
    },
    alocacaoError(state, { payload }: PayloadAction<string>) {
      state.actionAlocacao = 'BACKEND_ERRO';
      state.errorAlocacao = payload;
    },
    alocacaoListResetAction(state) {
      state.actionAlocacaoList = 'BACKEND_AGUARDANDO';
      state.errorAlocacaoList = null;
    },
    alocacaoListStart(state) {
      state.actionAlocacaoList = 'BACKEND_PROCESSANDO';
      state.errorAlocacaoList = null;
    },
    alocacaoListSuccess(state, { payload }: PayloadAction<any[]>) {
      state.actionAlocacaoList = 'BACKEND_SUCESSO';
      state.alocacaoList = payload;
    },
    alocacaoListError(state, { payload }: PayloadAction<string>) {
      state.actionAlocacaoList = 'BACKEND_ERRO';
      state.errorAlocacaoList = payload;
    }
  }
});

export const {
  alocacaoResetAction,
  alocacaoStart,
  alocacaoSuccess,
  alocacaoError,
  alocacaoListResetAction,
  alocacaoListStart,
  alocacaoListSuccess,
  alocacaoListError
} = alocacaoSlice.actions;

export const fetchAlocacaoList = (): AppThunk => async dispatch => {
  try {
    dispatch(alocacaoListStart());
    const data = await apiAlocacaoList();
    dispatch(alocacaoListSuccess(data));
  } catch (err: any) {
    dispatch(alocacaoListError(err.message));
  }
}

export const fetchAlocacao = (alocacaoRecebimentoId: number): AppThunk => async dispatch => {
  try {
    dispatch(alocacaoStart());
    const data = await apiAlocacao(alocacaoRecebimentoId);
    dispatch(alocacaoSuccess(data));
  } catch (err: any) {
    dispatch(alocacaoError(err.message));
  }
}

export default alocacaoSlice.reducer;