import React, { Component } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Despesa from "./Despesa";
import FisicoFinanceiro from "./FisicoFinanceiro";
import Movimento from "./Movimento";

class RouteRelatorio extends Component {
  render() {
    return (
      <Routes>
        <Route path="fisico_financeiro" element={<FisicoFinanceiro />} />
        <Route path="despesa" element={<Despesa />} />
        <Route path="movimento" element={<Movimento />} />
        <Route path="*" element={<Navigate to={"/home/relatorio/fisico_financeiro"} />} />
      </Routes>
    );
  }
}

export default RouteRelatorio;
