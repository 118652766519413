import React from "react";
import { useSearchParams } from "react-router-dom";
import { FileManager } from "src/components/FileManager";

const EmpreendimentoManutencao: React.FC = () => {
  const [params] = useSearchParams();
  return (
    <>
      <FileManager
        category="ALOCACOES_CLIENTE"
        origin_id={+String(params.get("id") || "0")}
        view="image-first"
        heightFileList="700px"
      />
    </>
  );
};

export default EmpreendimentoManutencao;
