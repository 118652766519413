import { Input } from "antd";
import React, { forwardRef } from "react";
import { NumericFormat } from "react-number-format";
import { converterNumeroBR, convertToFloat } from "../../util/geral";

const NumeroDecimal = forwardRef((props: any, ref: any) => {
  const {
    value,
    decimalScale,
    decimalSeparator,
    className,
    fixedDecimalScale,
    thousandSeparator,
    customInput,
    onChange,
    ...restProps
  } = props;

  const decimalEscala = isNaN(decimalScale) ? 2 : decimalScale;

  function handleValue(newValue: any) {
    if (String(newValue).trim() === "" || isNaN(newValue)) {
      onChange && onChange("");
    } else {
      const send = convertToFloat(newValue, decimalEscala);
      onChange && onChange(send);
    }
  }

  return (
    <NumericFormat
      {...restProps}
      ref={ref}
      value={converterNumeroBR(value)}
      style={{ textAlign: "right" }}
      className={className}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (e && e.target) {
          handleValue(convertToFloat(e.target.value, decimalEscala));
        } else {
          handleValue(e);
        }
      }}
      decimalScale={decimalEscala}
      fixedDecimalScale={fixedDecimalScale || true}
      decimalSeparator={decimalSeparator || ","}
      thousandSeparator={thousandSeparator || "."}
      customInput={Input}
    />
  );
});

export default NumeroDecimal;
