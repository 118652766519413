import React, { useEffect, useState } from 'react';
import { PlusOutlined, StarTwoTone, CheckOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Row, Col, Button, Card, Menu, Dropdown } from 'antd';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { RootState } from 'src/store/features/rootReducer';
import { getIcon } from 'src/util/helperComponents';
import Calendario from './Calendario';

const MenuSistema: React.FC<any> = () => {
  const intl = useIntl();
  // const dispatch = useDispatch();
  const { favorito, menu } = useSelector((state: RootState) => state.login);
  const [meuFavorito, setMeuFavorito] = useState<any[]>([]);

  useEffect(() => {
    const meus = favorito.map((f) => f.key);
    setMeuFavorito(meus);
  }, [favorito]);

  function add(key: string) {
    // dispatch(Creators.addMenuFavorito(key));
  }

  return (
    <Dropdown
      overlay={
        <Menu>
          {menu &&
            menu?.map((m) => (
              <Menu.SubMenu
                key={m.key}
                title={
                  <>
                    {getIcon(m.icon, { twoToneColor: m.color, style: { fontSize: 17 } })} {intl.formatMessage({ id: m.label })}
                  </>
                }
              >
                {m.children?.map((filho) => (
                  <Menu.Item key={filho.key} onClick={() => add(filho.key)}>
                    {meuFavorito.includes(filho.key) ? (
                      <CheckOutlined style={{ fontSize: 17 }} />
                    ) : (
                      <DoubleRightOutlined style={{ fontSize: 17 }} />
                    )}
                    {intl.formatMessage({ id: filho.label })}
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            ))}
        </Menu>
      }
      trigger={['click']}
    >
      <Button shape='circle' icon={<PlusOutlined />} />
    </Dropdown>
  );
};

const Inicio = () => {
  const intl = useIntl();
  const { favorito, menu } = useSelector((state: RootState) => state.login);

  return (
    <>
      <Card
        style={{ display: 'none' }}
        title={
          <>
            <StarTwoTone style={{ fontSize: 17 }} twoToneColor='#ecd307' /> {intl.formatMessage({ id: 'label.favoritos' })}
          </>
        }
        extra={<MenuSistema menus={menu} favoritos={favorito} />}
        className='card-body-mb-0'
      >
        <Row gutter={24}>
          {favorito?.map((menuFavorito) => (
            <Col key={menuFavorito.key} sm={12} md={8} lg={4}>
              <NavLink to={menuFavorito.url || '/'}>
                <Button
                  style={{
                    width: '100%',
                    height: 75,
                    marginBottom: 24,
                    overflow: 'hidden',
                    textOverflow: `ellipsis`,
                    whiteSpace: `nowrap`,
                  }}
                  type='default'
                >
                  <DoubleRightOutlined style={{ fontSize: 20, color: menuFavorito.color }} />
                  <br />
                  {intl.formatMessage({ id: menuFavorito.label })}
                </Button>
              </NavLink>
            </Col>
          ))}
        </Row>
      </Card>
      <Calendario />
    </>
  );
};

export default Inicio;
