import { message } from "antd";
import { obterMensagemErro } from "src/util/geral";
import api from "./api";
import {
  Ent_Alocacao,
  Ent_AprovacaoVoto,
  Ent_Calendario,
  Ent_ClienteContratoList,
  Ent_ContratoRecebimento,
  Ent_Files,
  Ent_Login,
  Ent_Me,
  Ent_Notificacao,
  Ent_SolicitacaoCotacao,
  Ent_User,
  IFileCategory,
  ItemListaCalendario,
  TDropboxTokenLogin
} from "./interface";

export async function apiLogin(email: string, password: string, identificacao: string) {
  try {
    const { data } = await api.post<Ent_Login>("login", {
      email,
      password,
      identificacao,
      versao: process.env.REACT_APP_VERSION,
      build: 'CLI',
    });
    return data;
  } catch (err: any) {
    const erro = obterMensagemErro(err);
    throw new Error(erro);
  }
}

export async function apiMe() {
  try {
    const { data } = await api.get<Ent_Me>("me");
    return data;
  } catch (err: any) {
    const erro = obterMensagemErro(err);
    throw new Error(erro);
  }
}

export async function apiMenuFavorito() {
  try {
    const { data } = await api.get<Ent_Me>("me");
    return data;
  } catch (err: any) {
    const erro = obterMensagemErro(err);
    throw new Error(erro);
  }
}

export async function apiMinhasAlocacoes() {
  try {
    const { data } = await api.get<Ent_Alocacao[]>("minhas_alocacoes");
    return data || [];
  } catch (err: any) {
    const erro = obterMensagemErro(err);
    throw new Error(erro);
  }
}

export async function apiRelatorioPrevistoRealizado(alocacaoId: number) {
  try {
    const { data } = await api.get(`relatorio/previsto_realizado/${alocacaoId}`);
    return data || [];
  } catch (err: any) {
    const erro = obterMensagemErro(err);
    throw new Error(erro);
  }
}

export async function apiRelatorioMovimento(alocacaoId: number) {
  try {
    const { data } = await api.get(`relatorio/movimento/${alocacaoId}`);
    return data || [];
  } catch (err: any) {
    const erro = obterMensagemErro(err);
    throw new Error(erro);
  }
}

export async function apiRelatorioDespesas(alocacaoId: number, periodo: string) {
  try {
    const { data } = await api.get(`relatorio/despesas/${alocacaoId}`, { params: { periodo } });
    return data || [];
  } catch (err: any) {
    const erro = obterMensagemErro(err);
    throw new Error(erro);
  }
}

export async function apiContratoList() {
  try {
    const { data } = await api.get<Ent_ClienteContratoList[]>("contrato");
    return data;
  } catch (err: any) {
    const erro = obterMensagemErro(err);
    throw new Error(erro);
  }
}

export async function apiContrato(id: number) {
  try {
    const { data } = await api.get<Ent_ContratoRecebimento>(`contrato/${id}`);
    return data;
  } catch (err: any) {
    const erro = obterMensagemErro(err);
    throw new Error(erro);
  }
}

export async function apiContratoImprimirBoleto(caminho_pdf: string) {
  try {
    const { data } = await api.get(`contrato/imprimir/boleto`, {
      params: {
        caminho_pdf,
      },
    });
    return data;
  } catch (err: any) {
    const erro = obterMensagemErro(err);
    throw new Error(erro);
  }
}

export async function apiAlocacaoList() {
  try {
    const { data } = await api.get<Ent_Alocacao[]>("alocacao");
    return data;
  } catch (err: any) {
    const erro = obterMensagemErro(err);
    throw new Error(erro);
  }
}

export async function apiAlocacao(id: number) {
  try {
    const { data } = await api.get<Ent_Alocacao>(`alocacao/${id}`);
    return data;
  } catch (err: any) {
    const erro = obterMensagemErro(err);
    throw new Error(erro);
  }
}

export async function apiCalendarioList(inicio: string, termino: string) {
  try {
    const { data } = await api.get<Array<ItemListaCalendario>>("calendario", { params: { inicio, termino } });
    return data;
  } catch (err: any) {
    const erro = obterMensagemErro(err);
    throw new Error(erro);
  }
}

export async function apiCalendario(calendarioId: number) {
  try {
    const { data } = await api.get<Ent_Calendario>(`calendario/${calendarioId}`);
    return data;
  } catch (err: any) {
    const erro = obterMensagemErro(err);
    throw new Error(erro);
  }
}

export async function apiCalendarioSalvar(calendario: Ent_Calendario) {
  try {
    let response;
    if (calendario.id && calendario.id > 0) {
      response = await api.put<Ent_Calendario>(`calendario/${calendario.id}`, calendario);
    } else {
      response = await api.post<Ent_Calendario>("calendario", calendario);
    }
    return response.data;
  } catch (err: any) {
    const erro = obterMensagemErro(err);
    throw new Error(erro);
  }
}

export async function apiDropboxNewTokenLogin() {
  try {
    const { data } = await api.post<TDropboxTokenLogin>("dropbox/new_token_login", {});
    return data;
  } catch (error) {
    const erro = obterMensagemErro(error);
    message.error(erro);
    throw error;
  }
}

export async function apiUsuarioAprovacoes() {
  try {
    const { data } = await api.get<Ent_AprovacaoVoto[]>("usuario/aprovacao");
    return data;
  } catch (error) {
    const erro = obterMensagemErro(error);
    message.error(erro);
    throw error;
  }
}

export async function apiNotificacoesMarcarLida(id: string) {
  try {
    const { data } = await api.post(`usuario/notificacoes/lida/${id}`, {});
    return data;
  } catch (error) {
    const erro = obterMensagemErro(error);
    message.error(erro);
    throw error;
  }
}

export async function apiSolicitacaoAprovacaoCotacao(id: number) {
  try {
    const { data } = await api.get<Ent_SolicitacaoCotacao>(`usuario/aprovacao/cotacao/${id}`);
    return data;
  } catch (error) {
    const erro = obterMensagemErro(error);
    message.error(erro);
    throw error;
  }
}

export async function apiAtualizarSenha(senha: string) {
  try {
    await api.post("usuario/senha", { senha });
  } catch (error) {
    const erro = obterMensagemErro(error);
    message.error(erro);
  }
}

export async function apiAtualizarNome(nome: string) {
  try {
    const { data } = await api.post<Ent_User>("usuario/nome", { nome });
    return data;
  } catch (error) {
    const erro = obterMensagemErro(error);
    message.error(erro);
  }
}

export async function apiNotificacoesNaoLidas() {
  try {
    const { data } = await api.get<Ent_Notificacao[] | undefined>("usuario/notificacoes/nao_lidas");
    return data;
  } catch (error) {
    const erro = obterMensagemErro(error);
    message.error(erro);
    throw error;
  }
}

export async function apiAprovacaoAprovar(aprovacaoVotoId: number, descricao: string) {
  try {
    const { data } = await api.post<Ent_AprovacaoVoto>(`usuario/aprovacao/aprovar/${aprovacaoVotoId}`, { descricao });
    return data;
  } catch (error) {
    const erro = obterMensagemErro(error);
    message.error(erro);
    throw error;
  }
}

export async function apiAprovacaoRecusar(aprovacaoVotoId: number, descricao: string) {
  try {
    const { data } = await api.post<Ent_AprovacaoVoto>(`usuario/aprovacao/recusar/${aprovacaoVotoId}`, { descricao });
    return data;
  } catch (error) {
    const erro = obterMensagemErro(error);
    message.error(erro);
    throw error;
  }
}
export async function apiFiles(path: string, category: IFileCategory, origin_id?: number) {
  try {
    const { data } = await api.get<Ent_Files[]>("arquivo/files", {
      params: { path, category, origin_id, with_temporary_url: "S" },
    });
    return data;
  } catch (error) {
    const erro = obterMensagemErro(error);
    message.error(erro);
    throw error;
  }
}

export async function apiFilesTemporaryUrl(file_id: string) {
  try {
    const { data } = await api.get<Ent_Files>("arquivo/upload/view_temporary", {
      params: { file_id },
    });
    return data;
  } catch (error) {
    const erro = obterMensagemErro(error);
    message.error(erro);
    throw error;
  }
}
