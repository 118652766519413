import { CloseOutlined, DownloadOutlined } from "@ant-design/icons";
import React, { Button, Col, Drawer, Empty, Row, Space } from "antd";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { apiFiles, apiFilesTemporaryUrl } from "src/services/funcoesService";
import { Ent_Files, IFileCategory } from "src/services/interface";
import { FileDetails } from "./FileDetails";
import { FileMenu } from "./FileMenu";
import { FileTitle } from "./FileTitle";
import { FileView, FileViewType } from "./FileView";
import { FileWrapper } from "./FileWrapper";

const ROOT = "/";

interface Error {
  code: number;
  message: string;
}

interface ISort {
  key: keyof Ent_Files;
  callback?: (value: any) => any;
}

interface Props {
  view?: FileViewType;
  className?: string;
  heightFileList?: string;
  category: IFileCategory;
  origin_id?: number;
}

export const FileManager = ({ category, origin_id, className, heightFileList, view = "grid" }: Props) => {
  const [visible, setVisible] = useState(false);
  const [loading] = useState(false);
  const [currentPath, setCurrentPath] = useState<Ent_Files>();
  const [selectedFile, setSelectedFile] = useState<Ent_Files[]>([]);
  const [listFiles, setListFiles] = useState<Record<string, Ent_Files[]>>({ [ROOT]: [] });
  const [paths, setPaths] = useState<Ent_Files[]>([]);
  const [preview, setPreview] = useState<Ent_Files>();

  const { formatMessage } = useIntl();

  useEffect(() => {
    (async () => {
      const data = await apiFiles(currentPath?.id || "", category, origin_id);
      setListFiles((files) => ({ ...files, [currentPath?.id || ROOT]: data }));
    })();
  }, [category, currentPath, origin_id]);

  async function handleSelectFile(file?: Ent_Files) {
    if (!file) {
      setSelectedFile([]);
      setPreview(undefined);
    } else {
      setSelectedFile([file]);
      const preview = await apiFilesTemporaryUrl(file.id);
      setPreview({ ...preview });
      setVisible(true);
    }
  }

  async function handleAddSelectionFile(file: Ent_Files) {
    if (!file) {
      setSelectedFile([]);
      setPreview(undefined);
    } else {
      setSelectedFile([file]);
      const preview = await apiFilesTemporaryUrl(file.id);
      setPreview({ ...preview });
      setVisible(true);
    }
  }

  function handleVoltarClick() {
    const newPaths = [...paths];
    newPaths.pop();
    setPaths(newPaths);
    setCurrentPath(newPaths[newPaths?.length - 1]);
    setSelectedFile([]);
  }

  function handleVoltarAteClick(path?: Ent_Files) {
    const index = paths.findIndex((pathItem) => pathItem.id == path?.id);
    const newPaths = paths.slice(0, index + 1);
    setPaths(newPaths);
    setCurrentPath(path);
    setSelectedFile([]);
  }

  async function handleShowDetailsFile() {
    if (selectedFile?.length) {
      const preview = await apiFilesTemporaryUrl(selectedFile[0].id);
      setPreview({ ...preview });
      setVisible(true);
    }
  }

  function handleCloseDrawer() {
    setVisible(false);
  }

  function sort(list: Ent_Files[] | undefined | null, columns: ISort[]) {
    if (!list) {
      return [];
    }
    const valuesColumns = (file: Ent_Files) =>
      columns.reduce(
        (init, value) => `${init}${value.callback ? value.callback(file[value.key]) : file[value.key]}`,
        ""
      );
    return list.sort((a, b) => (valuesColumns(a) < valuesColumns(b) ? -1 : 0));
  }

  return (
    <>
      <FileWrapper
        isLoading={loading}
        heightFileList={heightFileList}
        className={className}
        title={<FileTitle uploading={[]} paths={paths} handleVoltarAteClick={handleVoltarAteClick} />}
        extra={
          <FileMenu
            isLoading={loading}
            selectedFiles={selectedFile}
            onShowDetailsClick={handleShowDetailsFile}
            onOpenUploadClick={() => {}}
          />
        }
      >
        <FileView
          style={{ width: `calc(100% - ${selectedFile.length == 1 ? 190 : 0}px)`, transition: "ease 0.1s width" }}
          type={view}
          paths={paths}
          list={sort(listFiles[currentPath?.id || ROOT], [
            { key: "folder", callback: (value) => (value === "S" ? "A--" : "Z--") },
            { key: "name" },
          ])}
          onSelectFileClick={handleSelectFile}
          onAddSelectionFileClick={handleAddSelectionFile}
          onBackClick={handleVoltarClick}
          selectedFile={selectedFile}
        ></FileView>
        {selectedFile.length == 1 ? (
          <Row
            className="file-details"
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              height: heightFileList || "calc(100vh - 210px)",
              borderLeft: "1px solid #ccc",
              width: 200,
            }}
          >
            {selectedFile ? <FileDetails file={selectedFile[0]} /> : null}
          </Row>
        ) : null}
      </FileWrapper>
      <Drawer
        width={"100%"}
        placement="right"
        title={preview?.name}
        closable
        onClose={handleCloseDrawer}
        open={visible}
        contentWrapperStyle={{ maxWidth: "1240px" }}
      >
        {preview && visible ? (
          <>
            <div
              style={{
                height: "calc(100vh - 55px)",
                overflow: "auto",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="list-items-calendar"
            >
              <object
                data={preview.temporary_url}
                style={{
                  border: "none",
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                  objectPosition: "center",
                  maxWidth: 1240 - 320,
                }}
              >
                <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Empty
                    description={formatMessage({
                      id: "label.arquivo_sem_preview",
                    })}
                  />
                </div>
              </object>

              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  height: "calc(100vh - 55px)",
                  borderLeft: "1px solid #ccc",
                }}
              >
                <Row
                  style={{
                    overflowY: "scroll",
                    width: 300,
                    minWidth: 300,
                    display: "flex",
                    flex: 1,
                  }}
                >
                  <FileDetails file={preview} />
                </Row>
                <Row>
                  <Col
                    xs={24}
                    style={{
                      padding: "10px 10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Space>
                      <a key="download" href={preview.temporary_url} target="_blank" rel="noopener noreferrer">
                        <Button shape="round" type="primary">
                          <DownloadOutlined />
                          {formatMessage({ id: "label.botao.download" })}
                        </Button>
                      </a>
                      <Button
                        shape="round"
                        key="voltar"
                        icon={<CloseOutlined />}
                        onClick={handleCloseDrawer}
                        style={{ marginRight: 8 }}
                      >
                        {formatMessage({ id: "label.botao.voltar" })}
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </div>
            </div>
          </>
        ) : null}
      </Drawer>
    </>
  );
};
