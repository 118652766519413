import { FileSearchOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Descriptions, message, Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Loader from 'src/components/Loader';
import VirtualTable from 'src/components/VirtualTable';
import { apiMinhasAlocacoes, apiRelatorioMovimento } from 'src/services/funcoesService';
import { Ent_Alocacao } from 'src/services/interface';
import { convertToFloat } from 'src/util/geral';

const Movimento = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [somaTotal, setSomaTotal] = useState(0);
  const [somaIndiceTotal, setSomaIndiceTotal] = useState(0);
  const [alocacaoId, setAlocacaoId] = useState<SelectValue>();
  const [alocacoes, setAlocacoes] = useState<Ent_Alocacao[]>([]);
  const [decimais, setDecimais] = useState<number>(2);
  const [data, setData] = useState([]);

  const columns: ColumnsType<any> = [
    {
      title: intl.formatMessage({ id: 'label.data' }),
      dataIndex: 'data_lancamento',
      render: (text) => dayjs(text).format('DD/MM/YYYY'),
      width: 100,
    },
    {
      title: intl.formatMessage({ id: 'label.movimento_numero_nota' }),
      dataIndex: 'numero',
      width: 110,
    },
    {
      title: intl.formatMessage({ id: 'label.descricao' }),
      dataIndex: 'descricao_item',
      width: 300,
    },
    {
      title: intl.formatMessage({ id: 'label.setor' }),
      dataIndex: 'setor',
      width: 250,
    },
    {
      title: intl.formatMessage({ id: 'label.departamento' }),
      dataIndex: 'departamento',
      width: 250,
    },
    {
      title: intl.formatMessage({ id: 'label.subdepartamento' }),
      dataIndex: 'subdepartamento',
      width: 250,
    },
    {
      title: intl.formatMessage({ id: 'label.total' }),
      dataIndex: 'total',
      render: (text) =>
        intl.formatNumber(text, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      align: 'right',
      width: 150,
    },
    {
      title: intl.formatMessage({ id: 'label.indice_total' }),
      dataIndex: 'indice_total',
      render: (text) =>
        intl.formatNumber(text, {
          minimumFractionDigits: decimais || 5,
          maximumFractionDigits: decimais || 5,
        }),
      align: 'right',
      width: 150,
    },
  ];

  useEffect(() => {
    const buscar = async () => {
      setLoading(true);
      try {
        const data = await apiMinhasAlocacoes();
        setAlocacoes(data);
      } catch (err: any) {
        setAlocacoes([]);
      } finally {
        setLoading(false);
      }
    };
    buscar();
  }, []);

  async function buscar() {
    if (!alocacaoId || !(alocacaoId > 0)) {
      message.error(intl.formatMessage({ id: 'label.mensagem.alocacao_nao_informada' }));
      return false;
    }
    setLoading(true);
    setData([]);
    try {
      const data = await apiRelatorioMovimento(alocacaoId as number);
      if (data && data.length) {
        let total = 0;
        let indiceTotal = 0;
        let decimais = -1;
        data?.map((item: any) => {
          if (decimais < 0) {
            decimais = item.decimais;
          }
          total += convertToFloat(item.total);
          indiceTotal += convertToFloat(item.indice_total, decimais);
          return true;
        });
        setSomaTotal(convertToFloat(total));
        setSomaIndiceTotal(convertToFloat(indiceTotal, decimais));
        setData(data);
        setDecimais(decimais);
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (error) {
      setData([]);
      setLoading(false);
    }
  }

  function totalizador() {
    return (
      <Descriptions size='small' column={1} bordered style={{ width: 300, textAlign: 'end', marginTop: 20 }}>
        <Descriptions.Item
          label={intl.formatMessage({
            id: 'label.total',
          })}
        >
          {intl.formatNumber(somaTotal, {
            minimumFractionDigits: 2,
          })}
        </Descriptions.Item>
        <Descriptions.Item
          label={intl.formatMessage({
            id: 'label.indice_total',
          })}
        >
          {intl.formatNumber(somaIndiceTotal, {
            minimumFractionDigits: decimais || 5,
          })}
        </Descriptions.Item>
      </Descriptions>
    );
  }

  return (
    <>
      <Alert
        message={intl.formatMessage({ id: 'pagina.relatorio.movimento' })}
        description={intl.formatMessage({ id: 'label.relatorio_informacao_movimento' })}
        type='info'
        showIcon
      />
      {loading && <Loader />}
      <div style={loading ? { display: 'none' } : {}}>
        <Card
          className='hidden-body'
          size='small'
          title={
            <Select
              placeholder={intl.formatMessage({ id: 'label.selecione_alocacao' })}
              style={{ width: '100%' }}
              showSearch
              allowClear
              onChange={setAlocacaoId}
              size='large'
            >
              {alocacoes?.map((aloc: any) => (
                <Select.Option key={aloc.id} value={aloc.id}>
                  {aloc.nome}
                </Select.Option>
              ))}
            </Select>
          }
          extra={
            <Button
              type='primary'
              ghost
              shape='circle'
              icon={<FileSearchOutlined />}
              onClick={buscar}
              style={{ margin: 3, marginRight: 0 }}
            />
          }
        >
          <VirtualTable bordered columns={columns} dataSource={data} size='small' />
        </Card>
        {totalizador()}
      </div>
    </>
  );
};

export default Movimento;
