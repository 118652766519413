import React, { Component } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Empreendimento from ".";
import EmpreendimentoManutencao from "./Manutencao";

class RouteEmpreendimento extends Component {
  render() {
    return (
      <>
        <Routes>
          <Route index element={<Empreendimento />} />
          <Route path="man" element={<EmpreendimentoManutencao />} />
          <Route path="*" element={<Navigate to={"/home/empreendimento"} />} />
        </Routes>
        <Outlet />
      </>
    );
  }
}

export default RouteEmpreendimento;
