import { FileSearchOutlined, ProfileOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Col, Descriptions, message, Modal, Row, Select } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Loader from "src/components/Loader";
import VirtualTable from "src/components/VirtualTable";
import { apiMinhasAlocacoes, apiRelatorioDespesas } from "src/services/funcoesService";
import { Ent_Alocacao } from "src/services/interface";
import { convertToFloat } from "src/util/geral";

const Despesa = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<any[]>();
  const [somaValor, setSomaValor] = useState(0);
  const [somaValorPago, setSomaValorPago] = useState(0);
  const [periodo, setPeriodo] = useState<string>("");
  const [periodos, setPeriodos] = useState<string[]>([]);
  const [alocacaoId, setAlocacaoId] = useState<SelectValue>();
  const [alocacoes, setAlocacoes] = useState<Ent_Alocacao[]>([]);
  const [data, setData] = useState([]);

  const columnsModal: ColumnsType<any> = [
    {
      title: intl.formatMessage({ id: "label.tipo_item" }),
      dataIndex: "tipo_item",
      width: 70,
    },
    {
      title: intl.formatMessage({ id: "label.setor" }),
      dataIndex: "setor",
      width: 270,
    },
    {
      title: intl.formatMessage({ id: "label.departamento" }),
      dataIndex: "departamento",
      width: 270,
    },
    {
      title: intl.formatMessage({ id: "label.subdepartamento" }),
      dataIndex: "subdepartamento",
      width: 270,
    },
    {
      title: intl.formatMessage({ id: "label.descricao_item" }),
      dataIndex: "descricao_item",
      width: 270,
    },
    {
      title: intl.formatMessage({ id: "label.quantidade" }),
      dataIndex: "quantidade_movimento",
      render: (text) => intl.formatNumber(text || 0, { minimumFractionDigits: 2 }),
      width: 120,
      align: "right",
    },
    {
      title: intl.formatMessage({ id: "label.valor_unitario" }),
      dataIndex: "valor_unitario",
      render: (text) => intl.formatNumber(text || 0, { minimumFractionDigits: 2 }),
      width: 120,
      align: "right",
    },
    {
      title: intl.formatMessage({ id: "label.desconto" }),
      dataIndex: "desconto",
      render: (text) => intl.formatNumber(text || 0, { minimumFractionDigits: 2 }),
      width: 120,
      align: "right",
    },
    {
      title: intl.formatMessage({ id: "label.total" }),
      dataIndex: "total",
      render: (text) => intl.formatNumber(text || 0, { minimumFractionDigits: 2 }),
      width: 120,
      align: "right",
    },
  ];
  const columns: ColumnsType<any> = [
    {
      title: "#",
      render: (_, record) => (
        <Button onClick={() => setDetails(record?.movimento_itens_view || [])} icon={<ProfileOutlined />} />
      ),
      key: "opcoes",
      width: 50,
    },
    {
      title: intl.formatMessage({ id: "label.data" }),
      dataIndex: "data",
      render: (text) => dayjs(text).format("DD/MM/YYYY"),
      width: 100,
    },
    {
      title: intl.formatMessage({ id: "label.numero" }),
      dataIndex: "numero_fatura",
      width: 110,
    },
    {
      title: intl.formatMessage({ id: "label.pessoa" }),
      dataIndex: "nome_pessoa",
      width: 350,
    },
    {
      title: intl.formatMessage({ id: "label.vencimento" }),
      dataIndex: "vencimento",
      render: (text) => (text ? dayjs(text).format("DD/MM/YYYY") : ""),
      width: 100,
    },
    {
      title: intl.formatMessage({ id: "label.data_pagamento" }),
      dataIndex: "data_pagamento",
      render: (text) => (text ? dayjs(text).format("DD/MM/YYYY") : ""),
      width: 100,
    },
    {
      title: intl.formatMessage({ id: "label.valor" }),
      dataIndex: "valor",
      render: (text) =>
        intl.formatNumber(text || "0", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      align: "right",
      width: 110,
    },
    {
      title: intl.formatMessage({ id: "label.valor_pago" }),
      dataIndex: "valor_pago",
      render: (text) =>
        intl.formatNumber(text || "0", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      align: "right",
      width: 110,
    },
    {
      title: intl.formatMessage({ id: "label.situacao" }),
      dataIndex: "situacao",
      width: 100,
    },
  ];

  useEffect(() => {
    const buscar = async () => {
      setLoading(true);
      try {
        const data = await apiMinhasAlocacoes();
        setAlocacoes(data);
        setPeriodos([
          dayjs().format("MM/YYYY"),
          dayjs().subtract(1, "month").format("MM/YYYY"),
          dayjs().subtract(2, "month").format("MM/YYYY"),
        ]);
        setPeriodo(dayjs().format("MM/YYYY"));
      } catch (err: any) {
        setAlocacoes([]);
      } finally {
        setLoading(false);
      }
    };
    buscar();
  }, []);

  async function buscar() {
    if (!alocacaoId || !(alocacaoId > 0)) {
      message.error(intl.formatMessage({ id: "label.mensagem.alocacao_nao_informada" }));
      return false;
    }
    setLoading(true);
    setData([]);
    try {
      const data = await apiRelatorioDespesas(alocacaoId as number, periodo);
      if (data && data.length) {
        let valor = 0;
        let valorPago = 0;
        data.map((item: any) => {
          valor += convertToFloat(item.valor);
          valorPago += convertToFloat(item.valor_pago);
          return true;
        });
        setSomaValor(convertToFloat(valor));
        setSomaValorPago(convertToFloat(valorPago));
        setData(data);
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (error) {
      setData([]);
      setLoading(false);
    }
  }

  function totalizador() {
    return (
      <Descriptions size="small" column={1} bordered style={{ width: 300, textAlign: "end", marginTop: 20 }}>
        <Descriptions.Item
          label={intl.formatMessage({
            id: "label.valor",
          })}
        >
          {intl.formatNumber(somaValor, {
            minimumFractionDigits: 2,
          })}
        </Descriptions.Item>
        <Descriptions.Item
          label={intl.formatMessage({
            id: "label.valor_pago",
          })}
        >
          {intl.formatNumber(somaValorPago, {
            minimumFractionDigits: 2,
          })}
        </Descriptions.Item>
      </Descriptions>
    );
  }

  return (
    <>
      <Alert
        message={intl.formatMessage({ id: "pagina.relatorio.despesa_title" })}
        description={intl.formatMessage({ id: "label.relatorio_informacao_despesa" })}
        type="info"
        showIcon
      />
      {loading && <Loader />}
      <div style={loading ? { display: "none" } : {}}>
        <Card
          className="hidden-body"
          size="small"
          title={
            <Row>
              <Col xs={24} sm={16}>
                <Select
                  placeholder={intl.formatMessage({ id: "label.selecione_alocacao" })}
                  style={{ width: "100%" }}
                  showSearch
                  allowClear
                  onChange={setAlocacaoId}
                  size="large"
                >
                  {alocacoes?.map((aloc: any) => (
                    <Select.Option key={aloc.id} value={aloc.id}>
                      {aloc.nome}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col xs={24} sm={8}>
                <Select
                  placeholder={intl.formatMessage({ id: "label.selecione_periodo" })}
                  style={{ width: "100%" }}
                  onChange={setPeriodo}
                  value={periodo}
                  size="large"
                >
                  {periodos?.map((periodo: string) => (
                    <Select.Option key={periodo} value={periodo}>
                      {periodo}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          }
          extra={
            <Button
              type="primary"
              ghost
              shape="circle"
              icon={<FileSearchOutlined />}
              onClick={buscar}
              style={{ margin: 3, marginRight: 0 }}
            />
          }
        >
          <VirtualTable bordered columns={columns} dataSource={data} size="small" />
        </Card>
        {totalizador()}
      </div>
      <Modal
        open={!!details}
        onOk={() => setDetails(undefined)}
        onCancel={() => setDetails(undefined)}
        style={{ top: 10 }}
        width={"calc(100% - 100px)"}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <VirtualTable bordered columns={columnsModal} dataSource={details || []} size="small" />
      </Modal>
    </>
  );
};

export default Despesa;
