import axios, { AxiosInstance } from "axios";

export const URL_API =
  process.env.NODE_ENV !== "development"
    ? "https://api-construcao.vfpsistemas.com/cliente/"
    : "http://api.construcao.test:8888/cliente/";
// : "http://localhost:8000/cliente/";

const api: AxiosInstance = axios.create({
  baseURL: URL_API,
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => {
    if (response.headers["authorization-token"]) {
      localStorage.setItem("access_token", response.headers["authorization-token"]);
    }
    return response;
  },
  (error) => {
    const { response } = error;
    if (response) {
      if (
        response &&
        response.data &&
        response.data.error &&
        ["token_invalid", "token_not_provided", "Token not provided", "token_expired", "Token has expired"].indexOf(
          response.data.error.message
        ) > -1
      ) {
        localStorage.removeItem("usuario");
        localStorage.removeItem("access_token");
        window.location.pathname = "/login";
      }
      switch (response.status) {
        case 404:
          return Promise.reject(error);
        case 401:
          return Promise.reject(error);
        default:
          return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
