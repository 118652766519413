import { CloudUploadOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Typography, Upload, message, type UploadProps } from "antd";
import type { RcFile } from "antd/es/upload";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import api, { URL_API } from "src/services/api";
import { apiAtualizarNome, apiAtualizarSenha } from "src/services/funcoesService";
import { loginSuccess } from "src/store/features/app/loginSlice";
import { RootState } from "src/store/features/rootReducer";

const Perfil = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.login);

  const propsUpload: UploadProps = {
    name: "arquivo",
    withCredentials: true,
    action: `${URL_API}arquivo/upload/perfil`,
    showUploadList: false,
    accept: ".png,.jpeg,.jpg",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
    customRequest: async ({ file, onError, onProgress, onSuccess }) => {
      const fileObj = file as RcFile;
      try {
        const formData = new FormData();
        formData.append('file', fileObj);
        const { data } = await api.post('arquivo/upload/perfil', formData);
        if (data?.imagem) {
          dispatch(loginSuccess({ ...user!, imagem: data?.imagem }));
          message.success(formatMessage({ id: "label.mensagem_sucesso" }));
        }
      } catch (err: any) {
        onError && onError(err);
      }
      return {
        abort() {
          message.warning("Carregamento cancelado!");
        },
      };
    },
  };

  const handleChangePassword = useCallback(
    async (value: string) => {
      if (value && !value.includes("***")) {
        await apiAtualizarSenha(value);
        message.success(formatMessage({ id: "label.mensagem_sucesso" }));
      }
    },
    [formatMessage]
  );

  const handleChangeName = useCallback(
    async (value: string) => {
      if (user && value && value !== user.name) {
        const user = await apiAtualizarNome(value);
        if (user) {
          dispatch(loginSuccess(user));
          message.success(formatMessage({ id: "label.mensagem_sucesso" }));
        }
      }
    },
    [dispatch, formatMessage, user]
  );

  return (
    <div className="d-flex justify-content-center">
      <Card
        hoverable
        style={{ width: "min(100%, 340px)" }}
        cover={
          <div className="d-flex justify-content-center mt-23">
            {user?.imagem ? <Avatar size={200} src={user.imagem} /> : <Avatar size={200} icon={<UserOutlined />} />}
            <Upload {...propsUpload}>
              <Button icon={<CloudUploadOutlined />} shape="circle" />
            </Upload>
          </div>
        }
      >
        <Typography.Text type="secondary">{formatMessage({ id: "label.nome" })}</Typography.Text>
        <br />
        <Typography.Text strong editable={{ onChange: handleChangeName }}>
          {user?.name}
        </Typography.Text>
        <br />
        <br />
        <Typography.Text type="secondary">{formatMessage({ id: "label.email" })}</Typography.Text>
        <br />
        <Typography.Text type="secondary">{user?.email}</Typography.Text>
        <br />
        <br />
        <Typography.Text type="secondary">{formatMessage({ id: "label.senha" })}</Typography.Text>
        <br />
        <Typography.Text strong editable={{ onChange: handleChangePassword }}>
          ********
        </Typography.Text>
      </Card>
    </div>
  );
};

export default Perfil;
