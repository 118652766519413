import { InfoCircleTwoTone, PrinterOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  Descriptions,
  Divider,
  Row,
  Space,
  Table,
  Tabs,
  Tag,
  Typography,
  notification
} from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import FormatarData from "src/components/Data/FormatarData";
import { FileManager } from "src/components/FileManager";
import Loader from "src/components/Loader";
import { PageHeader } from "src/components/PageHeader";
import api from "src/services/api";
import { Ent_Boleto, Ent_Titulo } from "src/services/interface";
import { fetchContrato } from "src/store/features/contrato/contratoSlice";
import { RootState } from "src/store/features/rootReducer";
import { Const, obterCorSituacaoCotacao } from "src/util/geral";

const ContratoManutencao: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const intl = useIntl();
  const { formatMessage } = intl;
  const { actionContrato, contrato, errorContrato } = useSelector((state: RootState) => state.contrato);

  useEffect(() => {
    switch (actionContrato) {
      case "BACKEND_ERRO":
        if (errorContrato) {
          notification.error({
            message: formatMessage({ id: "label.atencao" }),
            description: errorContrato,
          });
        }
        break;
    }
  }, [actionContrato, errorContrato, intl]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = parseInt(params.get("id") || "0");
    dispatch(fetchContrato(id));
  }, [dispatch, location.search]);

  const columnsBoleto: ColumnsType<Ent_Boleto> = [
    {
      title: formatMessage({ id: "label.pessoa" }),
      width: 150,
      render: (_, record) => (
        <>
          <Typography.Text strong>
            {record?.boleto_pessoas?.filter((pessoa) => pessoa.tipo === Const.PAGADOR)[0]?.nome}
          </Typography.Text>
          <br />
          <Typography.Text>{record.email_enviar}</Typography.Text>
        </>
      ),
      key: "pessoa",
    },
    {
      title: formatMessage({ id: "label.vencimento" }),
      width: 70,
      dataIndex: "vencimento",
      render: (text) => <FormatarData value={text} />,
      key: "vencimento",
    },
    {
      title: formatMessage({ id: "label.valor" }),
      width: 70,
      dataIndex: "valor",
      align: "right",
      render: (text) => intl.formatNumber(text, { minimumFractionDigits: 2 }),
      key: "valor",
    },
    {
      title: formatMessage({ id: "label.email" }),
      width: 100,
      dataIndex: "email",
      render: (text) => <Tag color={obterCorSituacaoCotacao(text)}>{text}</Tag>,
      key: "email",
    },
    {
      title: formatMessage({ id: "label.situacao" }),
      width: 70,
      dataIndex: "situacao",
      render: (text) => <Tag color={obterCorSituacaoCotacao(text)}>{text}</Tag>,
      key: "situacao",
    },
    {
      title: formatMessage({ id: "label.acoes" }),
      width: 70,
      dataIndex: "id",
      render: (_, record) => (
        <Space>
          <Button
            disabled={record.situacao !== "ATIVO"}
            icon={<PrinterOutlined />}
            onClick={() => handlePrintBoleto(record)}
          />
        </Space>
      ),
      key: "situacao",
    },
  ];

  const columns: ColumnsType<Ent_Titulo> = [
    {
      title: formatMessage({ id: "label.vencimento" }),
      dataIndex: "vencimento",
      render: (text, record) => <FormatarData value={text} />,
      key: "vencimento",
    },
    {
      title: formatMessage({ id: "label.data_pagamento" }),
      dataIndex: "data_pagamento",
      render: (text) => <FormatarData value={text} />,
      key: "data_pagamento",
    },
    {
      title: formatMessage({ id: "label.valor" }),
      align: "right",
      dataIndex: "valor",
      render: (text) => formatNumber(text, 2),
      key: "valor",
    },
    {
      title: formatMessage({ id: "label.valor_pago" }),
      align: "right",
      dataIndex: "valor_pago",
      render: (text) => formatNumber(text, 2),
      key: "valor_pago",
    },
  ];

  function formatNumber(value: number, decimais: number) {
    return intl.formatNumber(value || 0, {
      minimumFractionDigits: decimais || 2,
      maximumFractionDigits: decimais || 2,
    });
  }

  function colorSituacaoTituloContrato(situacao: string, vencimento: string) {
    if (situacao === Const.SITUACAO_PAGO) {
      return "green";
    } else if (dayjs(vencimento, "YYYY-MM-DD").isBefore(dayjs())) {
      return "red";
    } else {
      return "blue";
    }
  }

  async function handlePrintBoleto(boleto: Ent_Boleto) {
    try {
      const urlDownload = `contrato/imprimir/boleto?id=${boleto.id}`;
      const { data } = await api.get(urlDownload, { responseType: 'blob' });
      const url = URL.createObjectURL(data);
      window.open(url, "_blank");
    } catch (err: any) {
      /** */
    }
  }

  return (
    <>
      {actionContrato === "BACKEND_PROCESSANDO" && <Loader />}
      <Row gutter={24} style={actionContrato === "BACKEND_PROCESSANDO" ? { display: "none" } : {}}>
        <Col xs={24}>
          <PageHeader
            style={{ padding: 0 }}
            className="site-page-header-responsive"
            title={contrato?.alocacao?.nome}
            subTitle={<Tag>{contrato?.alocacao_unidade?.nome}</Tag>}
            footer={
              <Tabs
                items={[
                  {
                    label: formatMessage({ id: "label.tab_parcelas" }),
                    key: "parcelas",
                    children: (
                      <Collapse>
                        {contrato?.contrato_recebimento_view?.map((contratoItemView) => (
                          <Collapse.Panel
                            key={contratoItemView.contrato_recebimento_item_id!}
                            header={
                              <Descriptions
                                title={
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {contratoItemView.parcela}
                                    <Tag
                                      color={colorSituacaoTituloContrato(
                                        contratoItemView.situacao!,
                                        contratoItemView.vencimento
                                      )}
                                    >
                                      {contratoItemView.situacao}
                                    </Tag>
                                  </div>
                                }
                                size="small"
                              >
                                <Descriptions.Item
                                  label={formatMessage({
                                    id: "label.indice_contrato",
                                  })}
                                >
                                  {formatNumber(
                                    contratoItemView.valor_indice_contrato || 0,
                                    contratoItemView.decimais || 2
                                  )}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label={formatMessage({
                                    id: "label.vencimento",
                                  })}
                                >
                                  <FormatarData value={contratoItemView.vencimento} />
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label={formatMessage({
                                    id: "label.indice_fechado",
                                  })}
                                >
                                  {formatNumber(contratoItemView.indice_fechado || 0, contratoItemView.decimais || 2)}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label={formatMessage({
                                    id: "label.valor_fechado",
                                  })}
                                >
                                  {formatNumber(contratoItemView.valor_fechado || 0, 2)}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label={formatMessage({
                                    id: "label.indice_pago",
                                  })}
                                >
                                  {formatNumber(contratoItemView.indice_pago || 0, contratoItemView.decimais || 2)}
                                </Descriptions.Item>
                                <Descriptions.Item
                                  label={formatMessage({
                                    id: "label.valor_pago",
                                  })}
                                >
                                  {formatNumber(contratoItemView.valor_pago || 0, 2)}
                                </Descriptions.Item>
                                {contratoItemView.informacao_complementar ? (
                                  <Descriptions.Item
                                    label={formatMessage({
                                      id: "label.informacao_complementar",
                                    })}
                                    span={3}
                                  >
                                    <InfoCircleTwoTone style={{ paddingRight: 5 }} />
                                    {contratoItemView.informacao_complementar}
                                  </Descriptions.Item>
                                ) : null}
                              </Descriptions>
                            }
                          >
                            <Col xs={24}>
                              <Table
                                rowKey={(record) => record.id!}
                                size="small"
                                pagination={false}
                                dataSource={contratoItemView.contrato_titulos}
                                columns={columns}
                                scroll={{
                                  x: "calc(100% - 1px)",
                                }}
                                expandable={{
                                  expandedRowRender: (record) =>
                                    record.boletos?.length ? (
                                      <div className="ant-table-expanded-row-area" style={{ backgroundColor: "#eee" }}>
                                        <Col xs={24}>
                                          <Divider style={{ padding: 0, margin: 0 }}>
                                            {formatMessage({ id: "label.boletos" })}
                                          </Divider>
                                          <Table
                                            rowKey={(record) => record.id!}
                                            size="small"
                                            pagination={false}
                                            dataSource={record.boletos}
                                            columns={columnsBoleto}
                                            scroll={{
                                              x: "calc(100% - 1px)",
                                            }}
                                          />
                                        </Col>
                                      </div>
                                    ) : null,
                                }}
                              />
                            </Col>
                          </Collapse.Panel>
                        ))}
                      </Collapse>
                    ),
                  },
                  {
                    label: formatMessage({ id: "label.documentos" }),
                    key: "documento",
                    children: (
                      <FileManager
                        category="CONTRATOS_RECEBIMENTO_CLIENTE"
                        origin_id={contrato?.id}
                        view="image-first"
                        heightFileList="500px"
                      />
                    ),
                  },
                ]}
              />
            }
          >
            <Descriptions size="small">
              <Descriptions.Item label={formatMessage({ id: "label.nome" })}>
                {contrato?.pessoa?.nome}
              </Descriptions.Item>
              <Descriptions.Item label={formatMessage({ id: "label.indice" })}>
                {contrato?.indice?.nome}
              </Descriptions.Item>
              <Descriptions.Item label={formatMessage({ id: "label.indice_fechado" })}>
                {formatNumber(contrato?.indice_fechado || 0, contrato?.indice?.decimais || 2)}
              </Descriptions.Item>
              <Descriptions.Item label={formatMessage({ id: "label.valor_fechado" })}>
                {formatNumber(contrato?.valor_fechado || 0, 2)}
              </Descriptions.Item>
              <Descriptions.Item label={formatMessage({ id: "label.indice_pago" })}>
                {formatNumber(contrato?.indice_pago || 0, contrato?.indice?.decimais || 2)}
              </Descriptions.Item>
              <Descriptions.Item label={formatMessage({ id: "label.valor_pago" })}>
                {formatNumber(contrato?.valor_pago || 0, 2)}
              </Descriptions.Item>
            </Descriptions>
          </PageHeader>
        </Col>
      </Row>
    </>
  );
};

export default ContratoManutencao;
