import { createIntlCache, createIntl } from 'react-intl';

import messages from 'src/assets/i18n/pt';

const cache = createIntlCache();
const intl = createIntl(
  {
    locale: 'pt',
    messages: messages
  },
  cache
);

export default intl;