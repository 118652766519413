import { Select, Spin } from "antd";
import debounce from "lodash.debounce";
import React from "react";
import { injectIntl } from "react-intl";
import api from "src/services/api";

const { Option } = Select;

interface State {
  data: [];
  loading: boolean;
}

class AutoComplete extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      data: [],
      loading: false,
    };
    debounce(this.handleSearch, 800);
  }

  componentDidMount() {
    if (this.props.initialize === true) {
      this.handleSearch("%");
    }
  }

  handleSearch = async (valor: string) => {
    let data: any = [];
    this.setState({ data, loading: true });
    try {
      const f = this.props.filter || [];
      f.map((fi: any) => {
        fi.valor = !fi.fixo ? valor : fi.valor;
        return fi;
      });
      const url: any = {
        per_page: 50,
        filtro: f,
        info: null,
      };
      if (this.props.info) {
        url.info = this.props.info;
      }
      const response = await api.get(this.props.endpoint, { params: url });
      data = response?.data?.data || [];
      this.setState({ data, loading: false });
    } catch (error) {
      this.setState({ data, loading: false });
    }
  };

  handleChange = (value: number, opc: any) => {
    if (value) {
      const obj: any = this.state.data.filter((d: any) => d.id === value);
      if (obj && obj.length > 0) {
        this.props.onChange && this.props.onChange(obj[0]);
        this.props.onChangeAfter && this.props.onChangeAfter(obj[0]);
      }
    } else {
      this.props.onChange && this.props.onChange(undefined);
      this.props.onChangeAfter && this.props.onChangeAfter(undefined);
    }
    this.setState({ data: [] });
  };

  clearData = () => {
    this.setState({ data: [] });
  };

  render() {
    const {
      intl,
      value,
      onValue,
      onMountList,
      placeholder,
      style,
      showArrow,
      defaultActiveFirstOption,
      filterOption,
      notFoundContent,
      campoValue,
      disabled,
      autoFocus,
      size,
      className,
    } = this.props;

    const { data, loading } = this.state;
    return (
      <Select
        ref={(r) => (this.props.setRefComponent ? this.props.setRefComponent(r) : null)}
        className={className || ""}
        size={size || "default"}
        showSearch
        allowClear
        disabled={disabled}
        autoFocus={autoFocus ? true : false}
        value={onValue && value ? onValue(value) : value && value[campoValue] ? value[campoValue] : undefined}
        placeholder={placeholder || ""}
        style={style}
        showArrow={showArrow || false}
        defaultActiveFirstOption={defaultActiveFirstOption || false}
        filterOption={filterOption || false}
        onSearch={this.handleSearch}
        onChange={this.handleChange}
        notFoundContent={
          loading ? <Spin size="small" /> : notFoundContent || intl.formatMessage({ id: "label.sem_registro" })
        }
      >
        {data.map((d: any) => (
          <Option value={d.id} key={d.id} title={onValue ? onValue(d) : d[campoValue]}>
            {onMountList ? onMountList(d) : onValue ? onValue(d) : d[campoValue]}
          </Option>
        ))}
      </Select>
    );
  }
}
export default injectIntl(AutoComplete, { forwardRef: true });
