import { RightOutlined } from "@ant-design/icons";
import { Card, Col, Empty, Row, Space } from "antd";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "src/components/Loader";
import { fetchAlocacaoList } from "src/store/features/alocacao/alocacaoSlice";
import { RootState } from "src/store/features/rootReducer";

const Empreendimento = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { actionAlocacaoList, alocacaoList } = useSelector((state: RootState) => state.alocacao);

  useEffect(() => {
    dispatch(fetchAlocacaoList());
  }, [dispatch]);

  return (
    <>
      {actionAlocacaoList === "BACKEND_PROCESSANDO" && <Loader />}
      <Row gutter={24} style={actionAlocacaoList === "BACKEND_PROCESSANDO" ? { display: "none" } : {}}>
        {alocacaoList.map((alocacao) => (
          <Col key={alocacao.id} xs={24} sm={12} lg={8} style={{ padding: 10, marginBottom: 15 }}>
            <Link to={`man?id=${alocacao.id}`}>
              <Card
                hoverable
                bordered
                cover={
                  <div
                    style={{
                      backgroundColor: alocacao.cor || "#FFF",
                      height: 15,
                    }}
                  />
                }
                actions={[
                  <Space>
                    <RightOutlined />
                    {intl.formatMessage({ id: "label.detalhes" })}
                  </Space>,
                ]}
              >
                <Card.Meta
                  title={<span style={{ fontSize: 20 }}>{alocacao.nome}</span>}
                  description={
                    <div className="text-overflow">
                      {`${alocacao.endereco}${alocacao.numero ? "," : ""}
                      ${alocacao.numero || ""}${alocacao.bairro ? " | " : ""}
                      ${alocacao.bairro?.nome || ""}
                      ${alocacao.cidade ? " - " : ""}
                      ${alocacao.cidade?.cidade || ""}`}
                    </div>
                  }
                />
              </Card>
            </Link>
          </Col>
        ))}
        {!alocacaoList.length && (
          <div className="in-line d-flex justify-content-center">
            <Empty />
          </div>
        )}
      </Row>
    </>
  );
};

export default Empreendimento;
