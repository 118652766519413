import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "src/services/api";
import { apiLogin, apiMe, apiNotificacoesMarcarLida, apiUsuarioAprovacoes } from "src/services/funcoesService";
import {
  BACKEND_AGUARDANDO,
  BACKEND_ERRO,
  BACKEND_PROCESSANDO,
  BACKEND_SUCESSO,
  Ent_AprovacaoVoto,
  Ent_Me,
  Ent_Menu,
  Ent_MenuItem,
  Ent_User,
  IConfiguracaoApp,
  TDropboxTokenLogin
} from "src/services/interface";
import { AppThunk } from "src/store";
import { Const } from "src/util/geral";

export interface LoginInitialState {
  actionLogin: BACKEND_AGUARDANDO | BACKEND_PROCESSANDO | BACKEND_SUCESSO | BACKEND_ERRO;
  actionMe: BACKEND_AGUARDANDO | BACKEND_PROCESSANDO | BACKEND_SUCESSO | BACKEND_ERRO;
  actionAprovacoes: BACKEND_AGUARDANDO | BACKEND_PROCESSANDO | BACKEND_SUCESSO | BACKEND_ERRO;
  error: string | null;
  errorMe: string | null;
  user: Ent_User | null;
  aprovacoes: Ent_AprovacaoVoto[] | null;
  menu: Ent_Menu[];
  favorito: Ent_Menu[];
  configuracao: IConfiguracaoApp | null;
}

const loginInitialState: LoginInitialState = {
  actionLogin: "BACKEND_AGUARDANDO",
  actionMe: "BACKEND_AGUARDANDO",
  actionAprovacoes: "BACKEND_AGUARDANDO",
  errorMe: null,
  error: null,
  user: null,
  aprovacoes: null,
  menu: [],
  favorito: [],
  configuracao: null,
};

export const loginSlice = createSlice({
  name: "login",
  initialState: loginInitialState,
  reducers: {
    meResetAction(state) {
      state.actionMe = "BACKEND_AGUARDANDO";
      state.errorMe = null;
    },
    meStart(state) {
      state.actionMe = "BACKEND_PROCESSANDO";
      state.errorMe = null;
    },
    meSuccess(state, { payload }: PayloadAction<Ent_Me>) {
      state.actionMe = "BACKEND_SUCESSO";
      state.user = payload.usuario;
      state.menu = payload.menu;
      state.favorito = payload.favorito;
      state.configuracao = payload.configuracao;
    },
    meUpdateConfiguracao(state, { payload }: PayloadAction<TDropboxTokenLogin>) {
      state.configuracao = {
        ...state.configuracao,
        dropbox: {
          access_token: payload.access_token,
          expires_in_date: payload.expires_in_date,
        },
      };
    },
    meError(state, { payload }: PayloadAction<string>) {
      state.actionMe = "BACKEND_ERRO";
      state.errorMe = payload;
    },
    loginResetAction(state) {
      state.actionLogin = "BACKEND_AGUARDANDO";
      state.error = null;
    },
    loginStart(state) {
      state.actionLogin = "BACKEND_PROCESSANDO";
      state.error = null;
    },
    loginSuccess(state, { payload }: PayloadAction<Ent_User>) {
      state.actionLogin = "BACKEND_SUCESSO";
      state.user = payload;
    },
    loginError(state, { payload }: PayloadAction<string>) {
      state.actionLogin = "BACKEND_ERRO";
      state.error = payload;
    },
    loginLogOut(state) {
      state.actionLogin = "BACKEND_PROCESSANDO";
      state.error = null;
      state.user = null;
      state.menu = [];
      state.favorito = [];
    },
    usuarioAprovacoesResetAction(state) {
      state.actionLogin = "BACKEND_AGUARDANDO";
      state.error = null;
    },
    usuarioAprovacoesStart(state) {
      state.actionLogin = "BACKEND_PROCESSANDO";
      state.error = null;
    },
    usuarioAprovacoesSuccess(state, { payload }: PayloadAction<Ent_AprovacaoVoto[]>) {
      state.actionLogin = "BACKEND_SUCESSO";
      state.aprovacoes = payload;
    },
    usuarioAprovacoesError(state, { payload }: PayloadAction<string>) {
      state.actionLogin = "BACKEND_ERRO";
      state.error = payload;
    },
  },
});

export const {
  meError,
  meResetAction,
  meStart,
  meSuccess,
  meUpdateConfiguracao,
  loginResetAction,
  loginStart,
  loginSuccess,
  loginError,
  loginLogOut,
  usuarioAprovacoesResetAction,
  usuarioAprovacoesStart,
  usuarioAprovacoesSuccess,
  usuarioAprovacoesError,
} = loginSlice.actions;

export const fetchLogin =
  (email: string, password: string, identificacao: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loginStart());
      await api.get(`/sanctum/csrf-cookie`, {
        params: {
          identificacao: identificacao,
        },
        baseURL: api.defaults.baseURL?.replace("cliente/", "api/"),
      });
      const data = await apiLogin(email, password, identificacao);
      dispatch(loginSuccess(data.usuario));
    } catch (err: any) {
      dispatch(loginError(err.message));
    }
  };

export const fetchAprovacoes = (): AppThunk => async (dispatch) => {
  try {
    dispatch(loginStart());
    const data = await apiUsuarioAprovacoes();
    dispatch(usuarioAprovacoesSuccess(data));
  } catch (err: any) {
    dispatch(usuarioAprovacoesError(err.message));
  }
};

export const fetchNotificacoesMarcarLida =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      apiNotificacoesMarcarLida(id);
    } catch (err: any) {}
  };

export const fetchMe = (): AppThunk => async (dispatch) => {
  try {
    dispatch(meStart());
    const data = await apiMe();
    const favoritos: Ent_MenuItem[] = [];
    if (data.favorito && data.menu) {
      data.favorito.map((f) => {
        for (let index = 0; index < data.menu.length; index++) {
          const menu = data.menu[index].children?.filter((m: any) => m.key === f.key_menu);
          if (menu && menu.length) {
            favoritos.push({
              color: data.menu[index].color,
              icon: data.menu[index].icon,
              key: menu[0].key,
              label: menu[0].label,
              url: menu[0].url,
            });
            break;
          }
        }
        return f;
      });
    }
    dispatch(
      meSuccess({ usuario: data.usuario, menu: data.menu, favorito: favoritos, configuracao: data.configuracao })
    );
  } catch (err: any) {
    dispatch(meError(err.message));
  }
};

export const fetchLogOut = (): AppThunk => async (dispatch) => {
  try {
    dispatch(loginStart());
    // const data = await apiLogin();
    localStorage.removeItem(Const.LOCAL_STORAGE_TOKEN);
    dispatch(loginResetAction());
  } catch (err: any) {
    dispatch(loginError(err.message));
  }
};

export default loginSlice.reducer;
