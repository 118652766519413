import { Table } from "antd";
import { ColumnsType, TableProps } from "antd/lib/table";
import classNames from "classnames";
import ResizeObserver from "rc-resize-observer";
import React, { useEffect, useRef, useState } from "react";
import { VariableSizeGrid as Grid } from "react-window";

interface PropsOwn {
  columns: ColumnsType<any>;
  className?: string;
  scroll?: {
    x: number;
    y?: number | string;
  };
}

type Props = TableProps<any> & PropsOwn;

function VirtualTable(props: Props) {
  const { columns, scroll, className } = props;
  const [tableWidth, setTableWidth] = useState(0);

  const mergedColumns: any[] = columns.map((column: any) => {
    if (column.width) {
      return column;
    }

    return {
      ...column,
      width: 200,
    };
  });
  const widthCols = tableWidth - mergedColumns.reduce((init, c) => c.width + init, 0);

  mergedColumns.push({
    title: "",
    width: widthCols > 0 ? widthCols : 35,
  });

  const gridRef = useRef<any>();
  const [connectObject] = useState<any>(() => {
    const obj = {};
    Object.defineProperty(obj, "scrollLeft", {
      get: () => null,
      set: (scrollLeft: number) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({ scrollLeft });
        }
      },
    });

    return obj;
  });

  const resetVirtualGrid = () => {
    if (gridRef.current) {
      gridRef.current.resetAfterIndices({
        columnIndex: 0,
        shouldForceUpdate: false,
      });
    }
  };

  useEffect(() => resetVirtualGrid, []);
  useEffect(() => resetVirtualGrid, [tableWidth]);

  const alignCol = (align: any) => {
    switch (align) {
      case "right":
        return "flex-end";
      case "center":
        return "center";
      default:
        return "flex-start";
    }
  };

  const renderVirtualList = (rawData: any[], { scrollbarSize, ref, onScroll }: any) => {
    ref.current = connectObject;

    return (
      <Grid
        ref={gridRef}
        className="virtual-grid"
        columnCount={mergedColumns.length}
        columnWidth={(index) => {
          const { width } = mergedColumns[index];
          return index === mergedColumns.length - 1 ? width - scrollbarSize - 1 : width;
        }}
        height={window.innerHeight - 365}
        rowCount={rawData.length}
        rowHeight={() => 40}
        width={tableWidth}
        onScroll={({ scrollLeft }) => {
          onScroll({ scrollLeft });
        }}
      >
        {({ columnIndex, rowIndex, style }: any) => {
          const text =
            columnIndex === mergedColumns.length - 1
              ? ""
              : mergedColumns[columnIndex].render
              ? mergedColumns[columnIndex].render(
                  rawData[rowIndex][mergedColumns[columnIndex].dataIndex],
                  rawData[rowIndex],
                  columnIndex
                )
              : rawData[rowIndex][mergedColumns[columnIndex].dataIndex];
          return (
            <div
              className={classNames("virtual-table-cell", {
                "virtual-table-cell-last": columnIndex === mergedColumns.length - 1,
              })}
              style={{ ...style, justifyContent: alignCol(mergedColumns[columnIndex]?.align || "left") }}
              title={text}
            >
              {text}
            </div>
          );
        }}
      </Grid>
    );
  };

  return (
    <ResizeObserver
      onResize={({ width }) => {
        setTableWidth(width);
      }}
    >
      <Table
        {...props}
        className={classNames(className, "virtual-table")}
        columns={mergedColumns}
        pagination={false}
        scroll={scroll || { y: "100%", x: "100%" }}
        tableLayout="fixed"
        components={{
          body: renderVirtualList as any,
        }}
      />
    </ResizeObserver>
  );
}

export default VirtualTable;
