import React from "react";
import { TreeSelect } from "antd";
import { TreeSelectProps } from "antd/lib/tree-select";

interface OwnProps {
  campoCodigo: string;
  campoValue: string;
  onChange?: (value: any, callback?: any) => void;
  onChangeAfter?: (value: any) => void;
}

type Props = TreeSelectProps<any> & OwnProps;

export default class TreeSelectInput extends React.PureComponent<Props> {
  onChange = async (value: any, label: any, extra: any) => {
    if (value) {
      const valor = extra.triggerNode && extra.triggerNode.props ? extra.triggerNode.props.data : undefined;
      this.props.onChange && (await this.props.onChange(valor));
      this.props.onChangeAfter && (await this.props.onChangeAfter(valor));
    } else {
      this.props.onChange && (await this.props.onChange(undefined));
      this.props.onChangeAfter && (await this.props.onChangeAfter(undefined));
    }
  };

  render() {
    const { onChange, campoCodigo, value, campoValue, ...restProps } = this.props;
    return (
      <TreeSelect
        {...restProps}
        value={value ? `${value[campoCodigo]} - ${value[campoValue]}` : ""}
        onChange={this.onChange}
      />
    );
  }
}
