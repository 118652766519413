import React, { Component } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Contrato from ".";
import ContratoManutencao from "./Manutencao";

class RouteContrato extends Component {
  render() {
    return (
      <Routes>
        <Route index element={<Contrato />} />
        <Route path="man" element={<ContratoManutencao />} />
        <Route element={<Navigate to={"/home/contrato"} />} />
      </Routes>
    );
  }
}

export default RouteContrato;
