import {
  CloseCircleOutlined,
  EyeOutlined,
  HomeOutlined,
  IdcardOutlined,
  LogoutOutlined,
  SendOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Avatar, Button, Checkbox, Col, Form, Input, Row, Space, Typography, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Const } from "src/util/geral";
import validateMessages from "src/util/validateMessages";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import api from "src/services/api";
import { IUser } from "src/services/interface";
import { fetchLogin, loginResetAction } from "src/store/features/app/loginSlice";
import { RootState } from "src/store/features/rootReducer";
import logo from "../../assets/images/logo-name.png";
import styles from "./login.module.css";

const Login = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState<IUser>();
  const [waiting, setWaiting] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { actionLogin, error } = useSelector((state: RootState) => state.login);
  const [form] = Form.useForm();

  useEffect(() => {
    (async () => {
      setWaiting(true);
      try {
        const { data } = await axios.post(`${api.defaults.baseURL}user`, {}, { withCredentials: true });
        setUser(data);
      } catch (err: any) {
        setUser(undefined);
      } finally {
        setWaiting(false);
      }
    })();
  }, []);

  useEffect(() => {
    switch (actionLogin) {
      case "BACKEND_SUCESSO":
        dispatch(loginResetAction());
        navigate("/");
        break;
      case "BACKEND_ERRO":
        if (error) {
          notification.error({
            message: intl.formatMessage({ id: "label.atencao" }),
            description: error.toString(),
          });
        }
        break;
    }
    setIsLoading(actionLogin === "BACKEND_PROCESSANDO");
  }, [actionLogin, error, intl, dispatch]);

  async function onFinish(values: any) {
    const { email, password, identificacao, lembrar } = values;
    localStorage.setItem(Const.LOCAL_STORAGE_LOGIN_IDENTIFICACAO, identificacao);
    if (lembrar) {
      localStorage.setItem(Const.LOCAL_STORAGE_LEMBRAR_EMAIL, email);
    } else {
      localStorage.removeItem(Const.LOCAL_STORAGE_LEMBRAR_EMAIL);
    }
    dispatch(fetchLogin(email, password, identificacao));
  }

  const cleanEmail = () => {
    form.setFieldsValue({ email: "" });
  };

  async function sairClick() {
    debugger
    await api.post("logout", {}, { baseURL: api.defaults.baseURL?.replace("cliente/", "api/"), });
    navigate("/", { replace: true });
  }

  const identificacaoSuffix = <CloseCircleOutlined onClick={() => form.setFieldsValue({ identificacao: "" })} />;

  const emailSuffix = <CloseCircleOutlined onClick={cleanEmail} />;

  return (
    <>
      <div className={styles["content_bg"]} />
      <div className={styles.content}>
        <div className={styles.content_form}>
          {user?.id ? (
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              className={styles["login-form"]}
              initialValues={{
                email: localStorage.getItem(Const.LOCAL_STORAGE_LEMBRAR_EMAIL) || "",
                password: "",
                identificacao: localStorage.getItem(Const.LOCAL_STORAGE_LOGIN_IDENTIFICACAO) || "",
                lembrar: localStorage.getItem(Const.LOCAL_STORAGE_LEMBRAR_EMAIL) ? true : false,
              }}
              validateMessages={validateMessages}
            >
              <div className={styles["content_login"]}>
                <div className={styles["content_logo_full"]}>
                  <img src={logo} style={{ height: 35 }} alt="" />
                </div>
                <div className={styles["content_separator_form"]}>
                  <div className={styles["content_logo_small"]}>
                    <div
                      className={styles["content_logo_small"]}
                      style={{
                        height: 50,
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "8px 8px 0 0",
                        padding: 8,
                      }}
                    >
                      <img src={logo} alt={intl.formatMessage({ id: "nome_app" })} />
                    </div>
                  </div>
                  <div className={styles["content_separator_title"]}>
                    <h3>{intl.formatMessage({ id: "label.usuairo_logado" })}</h3>
                  </div>
                  <Row gutter={24}>
                    <Col xs={24}>
                      <Space direction="vertical" style={{ width: "100%" }}>
                        <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                          <Avatar size={64} icon={<UserOutlined />} src={user.imagem} />
                          <Typography.Text
                            style={{ display: "flex", flex: 1, marginLeft: 8 }}
                            ellipsis={{ suffix: "..." }}
                          >
                            {user.name}
                          </Typography.Text>
                        </div>
                        <Row gutter={24}>
                          <Col xs={12}>
                            <Link to="/">
                              <Button type="primary" shape="round" icon={<HomeOutlined />} style={{ width: "100%" }}>
                                {intl.formatMessage({ id: "label.ir_inicio" })}
                              </Button>
                            </Link>
                          </Col>
                          <Col xs={12}>
                            <Button
                              type="primary"
                              danger
                              shape="round"
                              icon={<LogoutOutlined />}
                              style={{ width: "100%" }}
                              onClick={sairClick}
                            >
                              {intl.formatMessage({ id: "label.sair" })}
                            </Button>
                          </Col>
                        </Row>
                      </Space>
                    </Col>
                  </Row>
                </div>
              </div>
            </Form>
          ) : (
            <Form
              wrapperCol={{ span: 24 }}
              className={styles["login-form"]}
              form={form}
              autoComplete="off"
              layout="vertical"
              onFinish={onFinish}
              initialValues={{
                email: localStorage.getItem(Const.LOCAL_STORAGE_LEMBRAR_EMAIL) || "",
                password: "",
                identificacao: localStorage.getItem(Const.LOCAL_STORAGE_LOGIN_IDENTIFICACAO) || "",
                lembrar: localStorage.getItem(Const.LOCAL_STORAGE_LEMBRAR_EMAIL) ? true : false,
              }}
              validateMessages={validateMessages}
              size="large"
            >
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 24 }}>
                <img src={logo} style={{ height: 35 }} alt="" />
              </div>
              <h3>{intl.formatMessage({ id: "label.acessar_sistema" })}</h3>
              <Form.Item
                label={intl.formatMessage({ id: "label.identificacao" })}
                name="identificacao"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: "label.identificacao",
                  })}
                  prefix={<IdcardOutlined />}
                  autoComplete="new-identificacao"
                  suffix={identificacaoSuffix}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({ id: "label.email" })}
                name="email"
                rules={[
                  {
                    type: "email",
                  },
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: "label.email",
                  })}
                  prefix={<UserOutlined />}
                  autoComplete="new-email"
                  suffix={emailSuffix}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({ id: "label.senha" })}
                name="password"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.Password
                  placeholder={intl.formatMessage({ id: "label.senha" })}
                  autoComplete="new-password"
                  prefix={<EyeOutlined />}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item name="lembrar" valuePropName="checked">
                <Checkbox disabled={isLoading}>{intl.formatMessage({ id: "label.lembrar_email" })}</Checkbox>
              </Form.Item>
              <Button
                loading={isLoading}
                icon={<SendOutlined />}
                type="primary"
                htmlType="submit"
                className={styles["login-form-button"]}
              >
                {intl.formatMessage({ id: "label.acessar" })}
              </Button>
            </Form>
          )}
        </div>
      </div>
      <div className={styles["footer"]}></div>
    </>
  );
};

export default Login;
