import React, { CSSProperties, PropsWithChildren } from "react";
import { Ent_Files } from "src/services/interface";
import { FileGridView } from "./FileGridView";
import { FileImageFirstView } from "./FileImageFirstView";

interface IFileViewProps {
  paths: Ent_Files[];
  list: Ent_Files[];
  onAddSelectionFileClick: (file: Ent_Files) => void;
  onSelectFileClick: (file?: Ent_Files) => void;
  onBackClick: () => void;
  selectedFile?: Ent_Files[];
  style?: CSSProperties;
}
export type FileViewProps = PropsWithChildren<IFileViewProps>;

export type FileViewType = "grid" | "list" | "image-first";

interface Props {
  type: FileViewType;
}
export const FileView = ({ type, ...rest }: Props & FileViewProps) => {
  switch (type) {
    case "list":
      return <></>;
    case "image-first":
      return <FileImageFirstView {...rest} />;
    default:
      return <FileGridView {...rest} />;
  }
};
