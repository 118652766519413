import { CloseCircleOutlined, LoadingOutlined, SaveOutlined, WarningOutlined } from "@ant-design/icons";
import { Form, Modal } from "antd";
import { Rule } from "antd/lib/form";
import { Store } from "antd/lib/form/interface";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import validateMessages from "src/util/validateMessages";
import { InputTextAreaDebounce } from "../InputDebounce";

interface Props {
  value?: string;
  title?: string;
  label?: string;
  onConfirm?: (value: string) => Promise<boolean>;
  onCancel?: (value: string) => Promise<boolean>;
  confirmAtivo?: boolean;
  rules?: Rule[];
}

const ModalTextarea = ({
  value,
  title,
  label,
  onConfirm,
  onCancel,
  confirmAtivo,
  rules,
  children,
}: PropsWithChildren<Props>) => {
  const { formatMessage } = useIntl();
  const [editado, setEditado] = useState(confirmAtivo || false);
  const [cancelando, setCanclelando] = useState(false);
  const [confirmando, setConfirmando] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const { resetFields } = form;

  useEffect(() => {
    if (visible) {
      resetFields();
    }
  }, [resetFields, visible]);

  function handleShow() {
    setVisible(!visible);
    setEditado(confirmAtivo || false);
  }

  async function handleOk(values: Store) {
    if (confirmando || cancelando) {
      return false;
    }

    setConfirmando(true);
    try {
      if (onConfirm) {
        const close = await onConfirm(values.valor);
        if (close === false) {
          return false;
        }
      }
    } finally {
      setConfirmando(false);
    }
    handleShow();
  }

  async function handleCancel() {
    if (confirmando || cancelando) {
      return false;
    }
    const setFalse = async () => {
      if (onCancel) {
        setCanclelando(true);
        try {
          const close = await onCancel(form.getFieldValue("valor"));
          if (close === false) {
            return false;
          }
        } finally {
          setCanclelando(false);
        }
      }
      handleShow();
    };
    if (form.isFieldsTouched()) {
      Modal.confirm({
        icon: <WarningOutlined />,
        title: formatMessage({
          id: "label.mensagem.item_edicao",
        }),
        okText: formatMessage({ id: "label.sim" }),
        cancelText: formatMessage({ id: "label.nao" }),
        onCancel: () => {
          setFalse();
        },
      });
    } else {
      setFalse();
    }
  }

  function onFieldsChange() {
    setEditado(true);
  }

  return (
    <>
      <span onClick={handleShow}>{children}</span>
      <Modal
        maskClosable={false}
        okText={
          <>
            {confirmando ? <LoadingOutlined /> : <SaveOutlined />} {formatMessage({ id: "label.botao.confirmar" })}
          </>
        }
        cancelText={
          <>
            {cancelando ? <LoadingOutlined /> : <CloseCircleOutlined />} {formatMessage({ id: "label.botao.cancelar" })}
          </>
        }
        title={title || formatMessage({ id: "label.informe_descricao" })}
        open={visible}
        okButtonProps={{ disabled: !editado }}
        onOk={() => form.submit()}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={handleOk}
          onFieldsChange={onFieldsChange}
          initialValues={{ valor: value || "" }}
          validateMessages={validateMessages}
        >
          <Form.Item label={label || formatMessage({ id: "label.informe_descricao" })} name="valor" rules={rules}>
            <InputTextAreaDebounce rows={7} disabled={confirmando || cancelando} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ModalTextarea;
