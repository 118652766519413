import { FileTextOutlined } from "@ant-design/icons";
import React, { Button } from "antd";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Ent_Files } from "src/services/interface";

interface Props {
  isLoading?: boolean;
  selectedFiles?: Ent_Files[];
  onOpenUploadClick: () => void;
  onShowDetailsClick: () => void;
}
export const FileMenu = ({ selectedFiles, isLoading, onShowDetailsClick: onShowDetails }: Props) => {
  const { formatMessage } = useIntl();

  const { hasTypeFileSelected, hasTypeFolderSelected, hasManySelection } = useMemo(() => {
    const hasTypeFolderSelected = selectedFiles?.some((f) => f.folder == "S") ?? false;
    const hasTypeFileSelected = selectedFiles?.some((f) => f.folder != "S") ?? false;
    const hasManySelection = (selectedFiles?.length || 0) > 1;
    return {
      hasTypeFileSelected,
      hasTypeFolderSelected,
      hasManySelection,
    };
  }, [selectedFiles]);

  return (
    <>
      <Button
        style={{ marginRight: 10 }}
        disabled={isLoading || !hasTypeFileSelected || hasTypeFolderSelected || hasManySelection}
        shape="circle"
        icon={<FileTextOutlined />}
        onClick={onShowDetails}
        title={formatMessage({ id: "label.botao.detalhes" })}
      />
    </>
  );
};
