import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiCalendario, apiCalendarioList, apiCalendarioSalvar } from 'src/services/funcoesService';
import { BACKEND_AGUARDANDO, BACKEND_ERRO, BACKEND_PROCESSANDO, BACKEND_SUCESSO, Ent_Calendario, ItemListaCalendario } from 'src/services/interface';
import { AppThunk } from 'src/store';

export interface CalendarioInitialState {
    actionCalendarioList: BACKEND_AGUARDANDO | BACKEND_PROCESSANDO | BACKEND_SUCESSO | BACKEND_ERRO;
    actionCalendario: BACKEND_AGUARDANDO | BACKEND_PROCESSANDO | BACKEND_SUCESSO | BACKEND_ERRO;
    actionCalendarioSalvar: BACKEND_AGUARDANDO | BACKEND_PROCESSANDO | BACKEND_SUCESSO | BACKEND_ERRO;
    actionCalendarioExcluir: BACKEND_AGUARDANDO | BACKEND_PROCESSANDO | BACKEND_SUCESSO | BACKEND_ERRO;
    errorCalendarioList: string | null;
    errorCalendario: string | null,
    errorCalendarioSalvar: string | null,
    errorCalendarioExcluir: string | null,
    calendarioList: Array<ItemListaCalendario>,
    calendario: Ent_Calendario | null,
}

const calendarioInitialState: CalendarioInitialState = {
    actionCalendarioList: 'BACKEND_AGUARDANDO',
    actionCalendario: 'BACKEND_AGUARDANDO',
    actionCalendarioSalvar: 'BACKEND_AGUARDANDO',
    actionCalendarioExcluir: 'BACKEND_AGUARDANDO',
    calendarioList: [],
    calendario: null,
    errorCalendarioList: null,
    errorCalendario: null,
    errorCalendarioSalvar: null,
    errorCalendarioExcluir: null,
}

export const calendarioSlice = createSlice({
    name: 'calendario',
    initialState: calendarioInitialState,
    reducers: {
        calendarioResetAction(state) {
            state.actionCalendario = 'BACKEND_AGUARDANDO';
            state.errorCalendario = null;
        },
        calendarioCloseAction(state) {
            state.actionCalendario = 'BACKEND_AGUARDANDO';
            state.errorCalendario = null;
            state.calendario = null;
        },
        calendarioStart(state) {
            state.actionCalendario = 'BACKEND_PROCESSANDO';
            state.errorCalendario = null;
        },
        calendarioSuccess(state, { payload }: PayloadAction<Ent_Calendario>) {
            state.actionCalendario = 'BACKEND_SUCESSO';
            state.calendario = payload;
        },
        calendarioError(state, { payload }: PayloadAction<string>) {
            state.actionCalendario = 'BACKEND_ERRO';
            state.errorCalendario = payload;
        },
        calendarioListResetAction(state) {
            state.actionCalendarioList = 'BACKEND_AGUARDANDO';
            state.errorCalendarioList = null;
        },
        calendarioListStart(state) {
            state.actionCalendarioList = 'BACKEND_PROCESSANDO';
            state.errorCalendarioList = null;
        },
        calendarioListSuccess(state, { payload }: PayloadAction<any[]>) {
            state.actionCalendarioList = 'BACKEND_SUCESSO';
            state.calendarioList = payload;
        },
        calendarioListError(state, { payload }: PayloadAction<string>) {
            state.actionCalendarioList = 'BACKEND_ERRO';
            state.errorCalendarioList = payload;
        },
        calendarioSalvarResetAction(state) {
            state.actionCalendarioSalvar = 'BACKEND_AGUARDANDO';
            state.errorCalendarioSalvar = null;
            state.calendario = null;
        },
        calendarioSalvarStart(state) {
            state.actionCalendarioSalvar = 'BACKEND_PROCESSANDO';
            state.errorCalendarioSalvar = null;
        },
        calendarioSalvarSuccess(state, { payload }: PayloadAction<Ent_Calendario>) {
            state.actionCalendarioSalvar = 'BACKEND_SUCESSO';
            let novo = true;
            const lista = state.calendarioList?.map((item) => {
                if (item.id === payload.id) {
                    novo = false;
                    return payload;
                }
                return item;
            });
            if (novo) {
                lista?.push(payload);
            }
            state.calendarioList = lista;
        },
        calendarioSalvarError(state, { payload }: PayloadAction<string>) {
            state.actionCalendarioSalvar = 'BACKEND_ERRO';
            state.errorCalendarioSalvar = payload;
        },
    }
});

export const {
    calendarioResetAction,
    calendarioCloseAction,
    calendarioStart,
    calendarioSuccess,
    calendarioError,
    calendarioListResetAction,
    calendarioListStart,
    calendarioListSuccess,
    calendarioListError,
    calendarioSalvarResetAction,
    calendarioSalvarStart,
    calendarioSalvarSuccess,
    calendarioSalvarError
} = calendarioSlice.actions;

export const fetchCalendarioList = (inicio: string, termino: string): AppThunk => async dispatch => {
    try {
        dispatch(calendarioListStart());
        const data = await apiCalendarioList(inicio, termino);
        dispatch(calendarioListSuccess(data));
    } catch (err: any) {
        dispatch(calendarioListError(err.message));
    }
}

export const fetchCalendario = (calendarioId: number): AppThunk => async dispatch => {
    try {
        dispatch(calendarioStart());
        const data = await apiCalendario(calendarioId);
        dispatch(calendarioSuccess(data));
    } catch (err: any) {
        dispatch(calendarioError(err.message));
    }
}

export const fetchCalendarioSalvar = (calendario: Ent_Calendario): AppThunk => async dispatch => {
    try {
        dispatch(calendarioStart());
        const data = await apiCalendarioSalvar(calendario);
        dispatch(calendarioSalvarSuccess(data));
    } catch (err: any) {
        dispatch(calendarioError(err.message));
    }
}

export default calendarioSlice.reducer;