import {
  AudioOutlined,
  BuildOutlined,
  BuildTwoTone,
  ContainerOutlined,
  ContainerTwoTone,
  ControlOutlined,
  ControlTwoTone,
  CopyrightOutlined,
  CopyrightTwoTone,
  FileExcelFilled,
  FileImageOutlined,
  FilePdfOutlined,
  FileTwoTone,
  FileWordOutlined,
  FileZipOutlined,
  FolderOutlined,
  FolderTwoTone,
  FolderViewOutlined,
  FundOutlined,
  FundTwoTone,
  HomeOutlined,
  HomeTwoTone,
  ProfileOutlined,
  ProfileTwoTone,
  ReconciliationOutlined,
  ReconciliationTwoTone
} from "@ant-design/icons";
import React, { CSSProperties } from "react";
import { Ent_Files, IFileCategory } from "src/services/interface";

export const COLOR_FOLDER = "#1890ff";
export const COLOR_FOLDER_BACK = "#fbc56d";
export const COLOR_FOLDER_SYS = "#e27516";
export const COLOR_FOLDER_SYS_CLI = "#ccd60d";
export const COLOR_ICON = "#1890ff";

export function getIcon(icon: string, params: { twoToneColor?: string; style: CSSProperties }) {
  if (!params.twoToneColor) {
    switch (icon) {
      case "profile":
        return <ProfileOutlined style={params.style || {}} />;
      case "container":
        return <ContainerOutlined style={params.style || {}} />;
      case "reconciliation":
        return <ReconciliationOutlined style={params.style || {}} />;
      case "copyright":
        return <CopyrightOutlined style={params.style || {}} />;
      case "build":
        return <BuildOutlined style={params.style || {}} />;
      case "control":
        return <ControlOutlined style={params.style || {}} />;
      case "folder":
        return <FolderOutlined style={params.style || {}} />;
      case "fund":
        return <FundOutlined style={params.style || {}} />;
      case "home":
        return <HomeOutlined style={params.style || {}} />;
    }
  } else {
    switch (icon) {
      case "profile":
        return <ProfileTwoTone twoToneColor={params.twoToneColor} style={params.style || {}} />;
      case "container":
        return <ContainerTwoTone twoToneColor={params.twoToneColor} style={params.style || {}} />;
      case "reconciliation":
        return <ReconciliationTwoTone twoToneColor={params.twoToneColor} style={params.style || {}} />;
      case "copyright":
        return <CopyrightTwoTone twoToneColor={params.twoToneColor} style={params.style || {}} />;
      case "build":
        return <BuildTwoTone twoToneColor={params.twoToneColor} style={params.style || {}} />;
      case "control":
        return <ControlTwoTone twoToneColor={params.twoToneColor} style={params.style || {}} />;
      case "folder":
        return <FolderTwoTone twoToneColor={params.twoToneColor} style={params.style || {}} />;
      case "fund":
        return <FundTwoTone twoToneColor={params.twoToneColor} style={params.style || {}} />;
      case "home":
        return <HomeTwoTone twoToneColor={params.twoToneColor} style={params.style || {}} />;
    }
  }
}

export function getPosicaoReordenacao(valorAnterior?: number, valorPosterior?: number): number {
  const anterior: number = valorAnterior || (valorPosterior && valorPosterior > 1 ? valorPosterior - 1 : 0);
  const posterior: number = valorPosterior || anterior + 1;

  const diferenca = posterior - anterior;
  const somarAnterior = diferenca - diferenca / 4000;
  const valor = anterior + somarAnterior;
  const retorno = valor.toFixed(8);
  return parseFloat(retorno);
}

const CATEGORY_CLIENTE: IFileCategory[] = [
  "ALOCACOES_CLIENTE",
  "ALOCACOES_UNIDADE_CLIENTE",
  "CONTRATOS_RECEBIMENTO_CLIENTE",
];
export function iconFile(file: Ent_Files, style?: CSSProperties) {
  if (file.folder === "S" && file.system == "S") {
    if (CATEGORY_CLIENTE.includes(file.category)) {
      return <FolderViewOutlined style={{ ...style, fontSize: "125%", color: COLOR_FOLDER_SYS_CLI }} />;
    }
    return (
      <FolderTwoTone twoToneColor={COLOR_FOLDER_SYS} style={{ ...style, fontSize: "125%", color: COLOR_FOLDER_SYS }} />
    );
  }
  if (file.folder === "S") {
    return <FolderTwoTone twoToneColor={COLOR_FOLDER} style={{ ...style, fontSize: "125%", color: COLOR_FOLDER }} />;
  }
  const extension = String(file.extension || "");
  if (EXTENSION_IMAGE.includes(extension)) {
    return <FileImageOutlined style={{ color: "#c6cc1e", ...style }} />;
  }
  if (extension.includes("rar") || extension.includes("bz") || extension.includes("zip")) {
    return <FileZipOutlined style={{ color: "#996926", ...style }} />;
  }
  if (EXTENSION_PDF.includes(extension)) {
    return <FilePdfOutlined style={{ color: "#ef8739", ...style }} />;
  }
  if (EXTENSION_DOC.includes(extension)) {
    return <FileWordOutlined style={{ color: "#0e24e7", ...style }} />;
  }
  if (EXTENSION_EXCEL.includes(extension)) {
    return <FileExcelFilled style={{ color: "#74b806", ...style }} />;
  }
  if (EXTENSION_AUDIO.includes(extension)) {
    return <AudioOutlined style={{ color: "#e60a20", ...style }} />;
  }
  return <FileTwoTone color={COLOR_ICON} style={style} />;
}

export const EXTENSION_IMAGE = [
  "apng",
  "astc",
  "avif",
  "avifs",
  "avif",
  "avifs",
  "bmp",
  "dib",
  "cdr",
  "cgm",
  "drle",
  "emf",
  "g3",
  "fits",
  "g3",
  "gif",
  "heic",
  "heif",
  "heics",
  "heic",
  "heif",
  "heif",
  "heic",
  "heifs",
  "heic",
  "heif",
  "hej2",
  "hsj2",
  "ico",
  "ico",
  "ief",
  "jls",
  "jp2",
  "jpg2",
  "jpg",
  "jpeg",
  "jpe",
  "jp2",
  "jpg2",
  "jp2",
  "jpg2",
  "jph",
  "jhc",
  "jpm",
  "jpgm",
  "jpx",
  "jpf",
  "jxl",
  "jxr",
  "jxra",
  "jxrs",
  "jxs",
  "jxsc",
  "jxsi",
  "jxss",
  "ktx",
  "ktx2",
  "ora",
  "jpg",
  "jpeg",
  "jpe",
  "png",
  "btif",
  "pti",
  "psd",
  "rle",
  "sgi",
  "svg",
  "svg",
  "svgz",
  "svgz",
  "t38",
  "tga",
  "icb",
  "tpic",
  "vda",
  "vst",
  "tga",
  "icb",
  "tpic",
  "vda",
  "vst",
  "tif",
  "tiff",
  "tfx",
  "psd",
  "azv",
  "uvi",
  "uvvi",
  "uvg",
  "uvvg",
  "djvu",
  "djv",
  "djvu",
  "djv",
  "sub",
  "dwg",
  "dxf",
  "fbs",
  "fpx",
  "fst",
  "mmr",
  "rlc",
  "ico",
  "dds",
  "mdi",
  "wdp",
  "npx",
  "b16",
  "rp",
  "tap",
  "vtf",
  "wbmp",
  "xif",
  "pcx",
  "webp",
  "wmf",
  "3ds",
  "dng",
  "ag",
  "bmp",
  "dib",
  "eps.bz2",
  "epsi.bz2",
  "epsf.bz2",
  "cr2",
  "cr3",
  "crw",
  "cdr",
  "ras",
  "cmx",
  "dds",
  "djvu",
  "djv",
  "emf",
  "eps",
  "epsi",
  "epsf",
  "exr",
  "fits",
  "fh",
  "fhc",
  "fh4",
  "fh5",
  "fh7",
  "raf",
  "gbr",
  "gih",
  "pat",
  "eps.gz",
  "epsi.gz",
  "epsf.gz",
  "tga",
  "icb",
  "tpic",
  "vda",
  "vst",
  "icns",
  "ico",
  "ico",
  "iff",
  "ilbm",
  "lbm",
  "iff",
  "ilbm",
  "lbm",
  "jng",
  "j2c",
  "j2k",
  "jpc",
  "jp2",
  "jpg2",
  "dcr",
  "k25",
  "kdc",
  "lwo",
  "lwob",
  "lws",
  "pntg",
  "mrw",
  "sid",
  "bmp",
  "dib",
  "msod",
  "nef",
  "nrw",
  "orf",
  "raw",
  "rw2",
  "raw",
  "rw2",
  "pcx",
  "pef",
  "pcd",
  "psd",
  "pic",
  "pct",
  "pict",
  "pict1",
  "pict2",
  "pnm",
  "pbm",
  "pgm",
  "ppm",
  "psd",
  "qtif",
  "qif",
  "rgb",
  "sgi",
  "x3f",
  "sk",
  "sk1",
  "arw",
  "sr2",
  "srf",
  "sun",
  "tga",
  "icb",
  "tpic",
  "vda",
  "vst",
  "tga",
  "icb",
  "tpic",
  "vda",
  "vst",
  "cur",
  "wmf",
  "wmf",
  "xbm",
  "xcf",
  "fig",
  "xpm",
  "xpm",
  "xwd",
  "djvu",
  "djv",
];
export const EXTENSION_ZIP = ["rar", "bz", "zip"];
export const EXTENSION_PDF = ["pdf"];
export const EXTENSION_DOC = ["docx", "doc", "odt"];
export const EXTENSION_EXCEL = ["xlsx", "xlsm", "xls", "xlt", "csv", "ods", "xps"];
export const EXTENSION_AUDIO = [
  "3gpp",
  "3gp",
  "3ga",
  "3gp",
  "3gpp",
  "3ga",
  "3g2",
  "3gp2",
  "3gpp2",
  "aac",
  "adts",
  "ass",
  "ac3",
  "adp",
  "amr",
  "amr",
  "awb",
  "awb",
  "axa",
  "au",
  "snd",
  "flac",
  "imy",
  "ime",
  "m3u",
  "m3u8",
  "vlc",
  "m4a",
  "f4a",
  "mid",
  "midi",
  "kar",
  "rmi",
  "mxmf",
  "xmf",
  "mp2",
  "mp3",
  "mpga",
  "m4a",
  "mp4a",
  "f4a",
  "mp3",
  "mpga",
  "mp2",
  "mp2a",
  "m2a",
  "m3a",
  "m3u",
  "m3u8",
  "vlc",
  "ogg",
  "oga",
  "spx",
  "opus",
  "sid",
  "psid",
  "s3m",
  "pls",
  "sil",
  "tta",
  "loas",
  "xhe",
  "aa",
  "aax",
  "aax",
  "uva",
  "uvva",
  "eol",
  "dra",
  "dts",
  "dtshd",
  "lvp",
  "ra",
  "rax",
  "pya",
  "ecelp4800",
  "ecelp7470",
  "ecelp9600",
  "rip",
  "ra",
  "rax",
  "wav",
  "oga",
  "ogg",
  "wav",
  "wav",
  "weba",
  "wma",
  "aac",
  "adts",
  "ass",
  "aifc",
  "aiffc",
  "aif",
  "aiff",
  "aifc",
  "aifc",
  "aiffc",
  "amz",
  "axa",
  "ape",
  "caf",
  "dts",
  "dtshd",
  "flac",
  "oga",
  "ogg",
  "gsm",
  "aac",
  "adts",
  "ass",
  "imy",
  "ime",
  "pla",
  "it",
  "m3u",
  "m3u8",
  "vlc",
  "m4a",
  "f4a",
  "m4b",
  "f4b",
  "m4r",
  "mka",
  "mid",
  "midi",
  "kar",
  "minipsf",
  "mo3",
  "mod",
  "ult",
  "uni",
  "m15",
  "mtm",
  "669",
  "med",
  "mp2",
  "mp3",
  "mpga",
  "m3u",
  "m3u8",
  "vlc",
  "mp3",
  "mpga",
  "m3u",
  "m3u8",
  "vlc",
  "mp3",
  "mpga",
  "asx",
  "wax",
  "wvx",
  "wmx",
  "wax",
  "wma",
  "wmv",
  "mpc",
  "mpp",
  "mp+",
  "oga",
  "ogg",
  "opus",
  "oga",
  "ogg",
  "opus",
  "aa",
  "aax",
  "ram",
  "ra",
  "rax",
  "rmp",
  "psf",
  "psflib",
  "ra",
  "3gp",
  "3gpp",
  "3ga",
  "3gp",
  "3gpp",
  "3ga",
  "3gp",
  "3gpp",
  "3ga",
  "3gp",
  "3gpp",
  "3ga",
  "s3m",
  "pls",
  "shn",
  "spx",
  "oga",
  "ogg",
  "spx",
  "stm",
  "tta",
  "voc",
  "oga",
  "ogg",
  "oga",
  "ogg",
  "wav",
  "wv",
  "wvp",
  "wvc",
  "xi",
  "xm",
  "xmf",
  "xm",
  "xmf",
];
