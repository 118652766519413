import {
  BellOutlined,
  CheckOutlined,
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PoweroffOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Badge, Button, Dropdown, Layout, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useIntl } from "react-intl";
import api from "src/services/api";
import { apiNotificacoesNaoLidas } from "src/services/funcoesService";
import { Ent_Notificacao } from "src/services/interface";
import { fetchNotificacoesMarcarLida } from "src/store/features/app/loginSlice";
import { RootState } from "src/store/features/rootReducer";
import FormatarData from "../Data/FormatarData";
import redirecionarNotificacao, { iconNotificacao } from "./redirecionarNotificacao";

interface Props {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

const Header: React.FC<Props> = ({ collapsed, setCollapsed }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.login);
  const [notificacoesNaoLidas, setNotificacoesNaoLidas] = useState<Ent_Notificacao[]>([]);
  const { formatMessage } = useIntl();

  useEffect(() => {
    const buscarNotificacao = async () => {
      const n = await apiNotificacoesNaoLidas();
      setNotificacoesNaoLidas(n || []);
    };
    buscarNotificacao();
  }, []);

  async function handleClick() {
    await api.post("logout", {}, { baseURL: api.defaults.baseURL?.replace("cliente/", "api/"), });
    navigate("/login");
  }

  function redirecionar(notificacao: Ent_Notificacao) {
    const url = redirecionarNotificacao(notificacao);
    if (url) {
      dispatch(fetchNotificacoesMarcarLida(notificacao.id));
      const naoLidas = notificacoesNaoLidas?.filter((m) => m.id !== notificacao.id) || [];
      setNotificacoesNaoLidas(naoLidas);
      navigate(url);
    }
  }

  return (
    <Layout.Header className={"header-wrapper"}>
      <span className={"header-collapsed"} onClick={() => setCollapsed(!collapsed)}>
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </span>
      <div className={"header-user-info"}>
        <Dropdown
          menu={{
            className: "header-menu-notificacao",
            items: [
              ...notificacoesNaoLidas.map((m, i) => ({
                key: m.id,
                onClick: () => redirecionar(m),
                icon: (
                  <Button
                    type="primary"
                    ghost
                    shape="circle"
                    size="small"
                    style={{ marginRight: 10 }}
                    icon={iconNotificacao(m)}
                  />
                ),
                label: (
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-column">
                      {m.data.description}
                      <Typography.Text type="secondary" ellipsis style={{ maxWidth: 235 }}>
                        {m.data.message}
                      </Typography.Text>
                      <div>
                        <Typography.Text type="secondary" className="font-sz-70">
                          <FormatarData value={m.created_at} formatIn="YYYY-MM-DD HH:mm" formatOut="DD/MM/YYYY HH:mm" />
                        </Typography.Text>
                      </div>
                    </div>
                  </div>
                ),
              })),
            ],
          }}
          placement="bottomRight"
        >
          <span className={`header-dropdown-link pr-15 pl-15`}>
            <Badge count={notificacoesNaoLidas?.length || 0} className={"badge"} style={{ marginTop: -10 }}>
              <BellOutlined />
            </Badge>
          </span>
        </Dropdown>
        <Dropdown
          menu={{
            items: [
              {
                key: "aprovacoes",
                onClick: () => navigate("/home/usuario/aprovacoes"),
                icon: (
                  <Button
                    type="primary"
                    ghost
                    shape="circle"
                    size="small"
                    style={{ marginRight: 10 }}
                    icon={<CheckOutlined />}
                  />
                ),
                label: formatMessage({ id: "label.minhas_aprovacoes" }),
              },
              {
                key: "perfil",
                onClick: () => navigate("/home/usuario/perfil"),
                icon: (
                  <Button
                    type="primary"
                    ghost
                    shape="circle"
                    size="small"
                    style={{ marginRight: 10 }}
                    icon={<UserOutlined />}
                  />
                ),
                label: formatMessage({ id: "label.perfil" }),
              },
              {
                key: "sair",
                label: (
                  <>
                    <Button
                      danger
                      ghost
                      shape="circle"
                      size="small"
                      style={{ marginRight: 10 }}
                      icon={<PoweroffOutlined />}
                    />
                    {formatMessage({ id: "label.sair" })}
                  </>
                ),
                onClick: handleClick,
              },
            ],
          }}
          placement="bottomRight"
        >
          <span
            className={"header-dropdown-link"}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <UserOutlined />{" "}
            <div
              title={user?.name || ""}
              style={{
                maxWidth: "80px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {user && user.name ? user.name.split(" ")[0] : ".."}
            </div>{" "}
            <DownOutlined />
          </span>
        </Dropdown>
      </div>
    </Layout.Header>
  );
};

export default Header;
