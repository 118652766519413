import { Affix, Col, Row } from "antd";
import React from "react";

interface Props {
  offsetTop?: number;
  children: any;
}

const MenuFixo = (props: Props) => {
  return (
    <Affix offsetTop={props.offsetTop}>
      <Row
        gutter={24}
        style={{
          margin: "-20px 0px 0px -12px",
        }}
      >
        <Col xs={24} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="fixo">{props.children}</div>
        </Col>
      </Row>
    </Affix>
  );
};

export default React.memo(MenuFixo);
