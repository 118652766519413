import { Input } from "antd";
import debounce from "lodash/debounce";
import React from "react";

import Data from "../Data";
import NumeroDecimal from "../NumeroDecimal";
import TreeSelectInput from "../TreeSelectInput";
import AutoComplete from "../AutoComplete";

export const InputDebounce = DebouncedComponent(Input);
export const InputTextAreaDebounce = DebouncedComponent(Input.TextArea);
export const DataDebounce = DebouncedComponent(Data);
export const NumeroDecimalDebounce = DebouncedComponent(NumeroDecimal);
export const TreeSelectInputDebounce = DebouncedComponent(TreeSelectInput);
export const AutoCompleteDebounce = DebouncedComponent(AutoComplete);

export default function DebouncedComponent(WrappedComponent: any, config = { timeout: 400 }) {
  return class DebouncedTextField extends React.PureComponent<any, any> {
    constructor(props: any) {
      super(props);
      this.state = {
        value: this.props.value,
        valueInitial: this.props.value,
      };
      this.sendTextChange = debounce(this.sendTextChange, config.timeout);
    }

    static getDerivedStateFromProps(props: any, state: any) {
      if (props.value !== state.valueInitial) {
        return {
          value: props.value,
          valueInitial: props.value,
        };
      }
      return null;
    }

    handleTextChange = (e: any) => {
      if (e.persist) {
        e.persist();
      }
      this.setState({ value: e && e.target ? e.target.value : e });
      this.sendTextChange(e);
    };

    sendTextChange = (e: any) => {
      this.props.onChange(e);
    };

    render() {
      return <WrappedComponent {...this.props} value={this.state.value} onChange={this.handleTextChange.bind(this)} />;
    }
  };
}
