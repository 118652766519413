import React from "react";
import { MailOutlined, CopyrightOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Ent_Notificacao } from "src/services/interface";

export default function redirecionarNotificacao(notificacao: Ent_Notificacao) {
  let url = "";
  const info: any = notificacao.data.informations || {};
  switch (notificacao.type) {
    case "App\\Notifications\\AprovacaoCotacaoCriada":
      if (info.solicitacao_id) {
        url = `/home/aprovacao/cotacao?id=${info.solicitacao_id}`;
      }
      break;
    case "App\\Notifications\\AprovacaoCotacaoVotacaoPercentual":
      if (info.solicitacao_id) {
        url = `/home/compra/solicitacao/cotacao/man?id=${info.solicitacao_id}`;
      }
      break;
  }
  return url;
}

export function iconNotificacao(notificacao: Ent_Notificacao) {
  switch (notificacao.type) {
    case "App\\Notifications\\AprovacaoCotacaoCriada":
      return <CheckCircleOutlined />;
    case "App\\Notifications\\AprovacaoCotacaoVotacaoPercentual":
      return <CopyrightOutlined />;
    default:
      return <MailOutlined />;
  }
}
