import { FolderOpenTwoTone, LoadingOutlined } from "@ant-design/icons";
import React, { Breadcrumb, Space } from "antd";
import type { RcFile } from "antd/es/upload";
import { useIntl } from "react-intl";
import { Ent_Files } from "src/services/interface";
import { COLOR_FOLDER_BACK } from "src/util/helperComponents";

interface Props {
  uploading: RcFile[];
  paths: Ent_Files[];
  handleVoltarAteClick: (path?: Ent_Files) => void;
}
export const FileTitle = ({ uploading, paths, handleVoltarAteClick }: Props) => {
  const { formatMessage } = useIntl();
  return (
    <Space>
      {uploading.length ? (
        <LoadingOutlined style={{ fontSize: 17 }} />
      ) : (
        <FolderOpenTwoTone style={{ fontSize: 17 }} twoToneColor={COLOR_FOLDER_BACK} />
      )}
      <Breadcrumb>
        {[{} as Ent_Files, ...paths].map((path, index) => (
          <Breadcrumb.Item
            key={path.id || index}
            {...(index < paths.length
              ? { href: `#${paths[index - 1]?.name || formatMessage({ id: "label.inicio" })}` }
              : undefined)}
            onClick={(e) => {
              if (index < paths.length) {
                e.stopPropagation();
                e.preventDefault();
                handleVoltarAteClick(path.id ? path : undefined);
              }
            }}
          >
            {path.name || formatMessage({ id: "label.inicio" })}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </Space>
  );
};
