// import Compressor from "compressorjs";

export enum Mascara {
  CPF = '###.###.###-##',
  CNPJ = '##.###.###/####-##',
  CEP = '#####-###',
  TELEFONE = '(##) ####-####',
  CELULAR = '(##) #####-####',
}

export const validCpf = (cpf: string) => {
  cpf = String(cpf).replace(/\D/g, '');
  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;
  var soma = 0;
  var resto;
  for (let i = 1; i <= 9; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (let i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;
  return true;
};

export const validCnpj = (cnpj: string) => {
  cnpj = String(cnpj).replace(/\D/g, '');
  if (
    !cnpj ||
    cnpj.length !== 14 ||
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  )
    return false;
  var tamanho = cnpj.length - 2;
  var numeros = cnpj.substring(0, tamanho);
  var digitos = cnpj.substring(tamanho);
  var soma = 0;
  var pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(0))) return false;
  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(1))) return false;
  return true;
};

export const validCpfOrCnpj = (cpfCnpj: string) => {
  const t = String(cpfCnpj).replace(/\D/g, '');
  switch (t.length) {
    case 11:
      return validCpf(t);
    case 14:
      return validCnpj(t);
    default:
      return false;
  }
};

export const formatarCpfCpj = (cpfCnpj: string) => {
  const t = String(cpfCnpj).replace(/\D/g, '');
  return t.length > 11 ? t.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') : t.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

export const converterNumeroAM = (valor: string) => {
  if (!valor) {
    return valor;
  }
  let vlr: string = String(valor);
  vlr = vlr.replace(/[^0-9+-.,]/g, '');
  const p = vlr.indexOf('.');
  const v = vlr.indexOf(',');

  if (p > -1 && v > -1 && v > p) {
    vlr = vlr.replace(/\./g, '');
    vlr = vlr.replace(/,/g, '.');
  } else if (p > -1 && v > -1 && p > v) {
    vlr = vlr.replace(/,/g, '');
  } else if (p < 0 && v > -1) {
    vlr = vlr.replace(/,/g, '.');
  }

  return parseFloat(vlr);
};

export const converterNumeroBR = (valor: string) => {
  if (!valor) {
    return valor;
  }
  let vlr = String(converterNumeroAM(valor));
  vlr = vlr.replace(/,/g, '');
  vlr = vlr.replace(/\./g, ',');
  return vlr;
};

export const convertToFloat = (value: any, decimalCase: number = 2, defaultReturn: number = 0): number => {
  const valueAM: any = converterNumeroAM(value);
  const valueParser = parseFloat(valueAM);
  if (!isNaN(valueParser)) {
    return parseFloat(valueParser.toFixed(decimalCase));
  }
  return parseFloat(defaultReturn.toFixed(decimalCase));
};

/**
 * Returns true if numeric value is higher of 0
 * @param value
 * @returns boolean
 */
export const isValidId = (value: any): boolean => {
  return value && value > 0 ? true : false;
};

export const obterMensagemErro = (erro: any) => {
  let erroMsg = '';
  if (erro && erro.response && erro.response.data && erro.response.data.error && erro.response.data.error.message) {
    erroMsg = erro.response.data.error.message;
  } else if (erro && erro.response && erro.response.data) {
    const dataErro = erro.response.data;
    let data: any;
    try {
      if (typeof dataErro === "string") {
        if (dataErro.includes('{"error":')) {
          const listaErro = dataErro.split('{"error":');
          listaErro.map(er => {
            if (er) {
              const tmpDataStr = `{"error":${er}`;
              const tmpData = JSON.parse(tmpDataStr);
              if (!data) {
                data = [];
              }
              data = [...data, tmpData];
            }
            return true;
          })
        } else {
          data = JSON.parse(dataErro);
        }
      } else if (typeof dataErro === "object") {
        data = '';
        Object.keys(dataErro).map(key => {
          const objErro = dataErro[key];
          if (Array.isArray(objErro)) {
            objErro.map(erro => {
              Object.keys(erro).map(key => {
                if (!data.includes(erro)) {
                  data += `${erro} \n`;
                }
                return true;
              });
              return true;
            });
          } else if (typeof objErro == 'object') {
            data += dataErro[key];
            Object.keys(objErro).map(key => {
              if (!data.includes(objErro[key])) {
                data += `${objErro[key]} \n`;
              }
              return true;
            });
          } else {
            data += `${objErro} \n`;
          }
          return true;
        });
      } else {
        data = dataErro;
      }
    } catch (e) {
      data = erro.response.data;
    }
    if (Array.isArray(data)) {
      data.map(e => {
        if (e.error.message) {
          erroMsg += `${e.error.message} \n`;
        } else {
          erroMsg += JSON.stringify(e.error.message);
        }
        return true;
      })
    } else if (data && data.error && data.error.message) {
      erroMsg = data.error.message;
    } else if (typeof data === 'object') {
      Object.keys(data).map(e => {
        if (typeof data[e] !== 'object') {
          erroMsg += data[e] + '\n';
        }
        return e;
      });
    } else {
      erroMsg += data + '\n';
    }
  } else if (erro && erro.message) {
    erroMsg = erro.message;
  } else if (typeof erro === "string") {
    return erro;
  }
  return erroMsg;
};

export enum Const {
  BACKEND_AGUARDANDO = 'AGUARDANDO',
  BACKEND_PROCESSANDO = 'PROCESSANDO',
  BACKEND_SUCESSO = 'SUCESSO',
  BACKEND_ERRO = 'ERRO',

  SITUACAO_PAGO = 'PAGO',
  SITUACAO_PENDENTE = 'PENDENTE',
  PAGADOR = "PAGADOR",
  BENEFICIARIO = "BENEFICIARIO",
  

  LOCAL_STORAGE_THEME = '@webcontrole-cli/theme',
  LOCAL_STORAGE_TOKEN = '@webcontrole-cli/token',
  LOCAL_STORAGE_LEMBRAR_EMAIL = '@webcontrole-cli/lembrar_email',
  LOCAL_STORAGE_LOGIN_IDENTIFICACAO = '@webcontrole-cli/login_identificacao',
  LOCAL_STORAGE_COLLAPSED = '@webcontrole-cli/collapsed',
}

export function formatBytes(bytes: number, decimals = 2) {
  if (!bytes || bytes === 0) return '0 B';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function obterCorSituacaoAprovacao(situacao: string) {
  switch (situacao) {
    case 'EM_APROVACAO':
      return "geekblue";
    case 'APROVADO':
      return "green";
    case 'CANCELADO':
      return "red";
    case 'RECUSADO':
      return "magenta";
    default:
      return 'orange'
  }
}

export function obterCorSituacaoCotacao(situacao: string) {

  switch (situacao) {
    case 'SOLICITACAO':
      return "geekblue";
    case 'COTACAO':
      return "volcano";
    case 'ENVIADO':
      return "purple";
    case 'FINALIZADO':
      return "green";
    case 'APROVADO':
      return "green";
    case 'REQUISITADO':
      return "orange";
    case 'RECUSADO':
      return "red";
    case 'NEGADO':
      return "magenta";
    case 'APROVACAO':
      return "cyan";
    default:
      return 'orange'
  }
}

export function getUrlAprovacao(origem: string, chave: string) {
  switch (origem) {
    case 'COTACAO':
      return `/home/aprovacao/cotacao?id=${chave}`

    default:
      return '';
  }
}

export function randomIdTemp() {
  return (Math.random() + 1) * -1;
}


// export function compressImage(file: File, option: any): Promise<File> {
//   return new Promise((resolve, reject) => {
//     new Compressor(file, {
//       ...option,
//       success(result) {
//         resolve(new File([result], file.name, {
//           type: file.type,
//           lastModified: Date.now(),
//         }))
//       },
//       error(err) {
//         reject(err.message);
//       },
//     });
//   })
// }

export function getPosicaoReordenacao(valorAnterior?: number, valorPosterior?: number): number {
  const anterior: number = valorAnterior || (valorPosterior && valorPosterior > 1 ? valorPosterior - 1 : 0);
  const posterior: number = valorPosterior || anterior + 1;

  const diferenca = posterior - anterior;
  const somarAnterior = diferenca - (diferenca / 4000);
  const valor = anterior + somarAnterior;
  const retorno = valor.toFixed(8);
  return parseFloat(retorno);

  // T3
  // const getFracaoCalculo = (valor: number, aumentarFracao: boolean = false): number => {
  //   let decimais = 0;
  //   const grupo = String(valor).split('.');
  //   if (grupo.length > 1){
  //     if (grupo[1].length >= 8){
  //       decimais = parseInt(grupo[1].slice(0,-1) || '0');
  //     } else {
  //       decimais = parseInt(grupo[1] || '0');
  //     }
  //     decimais = parseFloat(`1.${decimais}`);
  //     decimais = parseInt(String(decimais).split('.')[1])
  //   }
  //   const inteiro = parseInt(grupo[0] || '0');
  //   const fracao = (aumentarFracao ? parseInt(`${decimais}1`) : decimais + 1);
  //   const valorComFracao = parseFloat(`${inteiro}.${String(fracao).padEnd(7, '0')}1`).toFixed(8);
  //   return parseFloat(valorComFracao);
  // }

  // if (typeof valorAnterior !== 'number' && typeof valorPosterior !== 'number') {
  //   return false;
  // }
  // const anterior: number = valorAnterior || (valorPosterior && valorPosterior > 1 ? valorPosterior - 1 : 0);
  // const posterior: number = valorPosterior || anterior + 1;

  // let fracao = getFracaoCalculo(anterior);
  // let retorno = (fracao).toFixed(8);
  // if (parseFloat(retorno) >= posterior){
  //   fracao = getFracaoCalculo(anterior, true);
  //   retorno = (fracao).toFixed(8);
  // }
  // if (parseFloat(retorno) >= posterior){
  //   return false;
  // }
  // return parseFloat(retorno);


  //T2
  // const diferenca = posterior - anterior;
  // const somarAnterior = diferenca - (diferenca / 2);
  // const valor = anterior + somarAnterior;
  // const retorno = valor.toFixed(8);
  // return parseFloat(retorno);

  //T1
  // let formatar = 0;
  // const getFracaoTamanho = (valor: number): number => {
  //   const grupo = String(valor).split('.');
  //   if (grupo.length < 2){
  //     return 0;
  //   }
  //   const tamanho = String(grupo[1]).length;
  //   return tamanho > 0 ? tamanho -1 : 0;
  // }
  // const getFracaoCalculo = (valor: number, aumentarFracao: boolean = false): number => {
  //   const valorStr = `0.${''.padStart(getFracaoTamanho(valor), '0')}${(aumentarFracao ? '0' : '')}1`;
  //   formatar = valorStr.length - 2;
  //   return parseFloat(valorStr);
  // }

  // if (typeof valorAnterior !== 'number' && typeof valorPosterior !== 'number'){
  //   return false;
  // }

  // const anterior: number = valorAnterior || valorPosterior || 0;
  // const posterior: number = valorPosterior || valorAnterior || 0;

  // let somaFracao = parseFloat((anterior + getFracaoCalculo(anterior)).toFixed(formatar));
  // if (somaFracao < posterior ){
  //   return parseFloat(somaFracao.toFixed(formatar));
  // }
  // somaFracao = parseFloat((anterior + getFracaoCalculo(anterior, true)).toFixed(formatar));
  // if (somaFracao < posterior ){
  //   return parseFloat(somaFracao.toFixed(formatar));
  // }
  // return false;
}