/* eslint-disable no-template-curly-in-string */
const typeTemplate = "Valor não é valido para ${type}";

export default {
    default: "Erro de validação no campo",
    required: "Campo é obrigatório",
    enum: "Valor deve ser um dos [${enum}]",
    whitespace: "Campo não pode estar vazio",
    date: {
        format: "Valor informado é inválido para a data do formato",
        parse: "Valor informado não pôde ser analisado como data",
        invalid: "Valor informado é uma data inválida",
    },
    types: {
        string: typeTemplate,
        method: typeTemplate,
        array: typeTemplate,
        object: typeTemplate,
        number: typeTemplate,
        date: typeTemplate,
        boolean: typeTemplate,
        integer: typeTemplate,
        float: typeTemplate,
        regexp: typeTemplate,
        email: typeTemplate,
        url: typeTemplate,
        hex: typeTemplate,
    },
    string: {
        len: "Valor informado deve ter exatamente ${len} caracteres",
        min: "Valor informado deve ter pelo menos ${min} caracteres",
        max: "Valor informado não pode ter mais de ${max} caracteres",
        range: "Valor informado deve ter entre ${min} e ${max} caracteres",
    },
    number: {
        len: "Valor informado deve ser igual a ${len}",
        min: "Valor informado não pode ser inferior a ${min}",
        max: "Valor informado não pode ser maior que ${max}",
        range: "Valor informado deve estar entre ${min} e ${max}",
    },
    array: {        
        len: "Valor informado deve ter exatamente ${len} comprimento",
        min: "Valor informado não pode ter menos de ${min} de comprimento",
        max: "Valor informado não pode ter mais de ${max} de comprimento",
        range: "Valor informado deve ter entre ${min} e ${max} de comprimento",
    },
    pattern: {
        mismatch: "Valor informado não corresponde ao padrão ${pattern}",
    },
}