import { ConfigProvider, Spin } from "antd";
import "antd/dist/reset.css";
import pt_BR from "antd/locale/pt_BR";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import React, { PropsWithChildren, useEffect } from "react";
import { RawIntlProvider, useIntl } from "react-intl";
import { Provider, useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
import store from "./store";
import { fetchMe } from "./store/features/app/loginSlice";
import { RootState } from "./store/features/rootReducer";
import intl from "./util/intl";

dayjs.extend(LocalizedFormat);

const PrivateRoute = ({ children }: PropsWithChildren<unknown>) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { actionMe, user } = useSelector((state: RootState) => state.login);

  useEffect(() => {
    dispatch(fetchMe());
  }, [dispatch]);

  if (actionMe === "BACKEND_AGUARDANDO" || actionMe === "BACKEND_PROCESSANDO") {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Spin size="large" />
          {intl.formatMessage({ id: "label.validando_acesso" })}
        </div>
      </div>
    );
  }
  if (actionMe === "BACKEND_SUCESSO" && user?.id) {
    return <>{children}</>;
  }
  return <Navigate to="/login" />;
};

function App() {
  return (
    <ConfigProvider
      locale={pt_BR}
      theme={{
        token: {
          borderRadius: 20,
        },
      }}
    >
      <Provider store={store}>
        <RawIntlProvider value={intl}>
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/home/*"
                element={
                  <PrivateRoute>
                    <Home />
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
          </BrowserRouter>
        </RawIntlProvider>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
