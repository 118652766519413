import React, { CSSProperties } from 'react';
import { Spin } from 'antd';
import styles from './loader.module.css';
import { useIntl } from 'react-intl';

const Loader = ({ style }: { style?: CSSProperties }) => {
  const intl = useIntl();
  return (
    <div className={styles['loader-wrapper']} style={style}>
      <div className={styles['loader-content']}>
        <Spin tip={intl.formatMessage({ id: 'label.carregando' })} />
      </div>
    </div>
  );
};

export default Loader;
