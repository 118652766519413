import {
  CheckCircleOutlined,
  CheckSquareTwoTone,
  CloseCircleOutlined,
  MessageOutlined,
  MessageTwoTone,
  StopOutlined
} from "@ant-design/icons";
import { Button, Card, Col, Descriptions, Divider, Form, Input, Row, Spin, Statistic, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Data from "src/components/Data";
import FormatarData from "src/components/Data/FormatarData";
import Loader from "src/components/Loader";
import MenuFixo from "src/components/MenuFixo";
import ModalTextarea from "src/components/ModalInput/ModalTextarea";
import { PageHeader } from "src/components/PageHeader";
import Tabela from "src/components/Tabela";
import { apiAprovacaoAprovar, apiAprovacaoRecusar } from "src/services/funcoesService";
import { Ent_SolicitacaoCotacao, Ent_SolicitacaoFornecedor, Ent_SolicitacaoMaterial } from "src/services/interface";
import { fetchAprovacaoCotacao } from "src/store/features/aprovacao/aprovacaoSlice";
import { RootState } from "src/store/features/rootReducer";
import { convertToFloat, obterCorSituacaoCotacao } from "src/util/geral";
import validateMessages from "src/util/validateMessages";

const AprovacaoCotacao: React.FC = () => {
  const { formatMessage, formatNumber } = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { cotacao: cotacaoObj, actionCotacao } = useSelector((state: RootState) => state.aprovacao);
  const [cotacao, setCotacao] = useState<Ent_SolicitacaoCotacao>();
  const [colunas, setColunas] = useState<any[]>([]);
  const [fornecedores, setFornecedores] = useState<Ent_SolicitacaoFornecedor[]>();
  const [form] = Form.useForm();

  const { resetFields } = form;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id: number = parseFloat(params.get("id") || "0");
    dispatch(fetchAprovacaoCotacao(id));
  }, [dispatch, location.search]);

  useEffect(() => {
    if (cotacaoObj) {
      const alocacao = JSON.parse(JSON.stringify(cotacaoObj));
      setCotacao(alocacao as Ent_SolicitacaoCotacao);
    }
  }, [cotacaoObj]);

  useEffect(() => {
    if (cotacao) {
      const columns: ColumnsType<Ent_SolicitacaoMaterial> = [
        {
          title: "#",
          align: "center",
          width: 50,
          dataIndex: "material.descricao".split("."),
          render: (text, record) => {
            return (
              <>
                {record.descricao ? (
                  <Tooltip title={formatMessage({ id: "label.descricao" })}>
                    <MessageTwoTone />
                  </Tooltip>
                ) : (
                  <MessageOutlined />
                )}{" "}
                {record.observacao ? (
                  <Tooltip title={formatMessage({ id: "label.observacao" })}>
                    <MessageTwoTone twoToneColor="#52c41a" />
                  </Tooltip>
                ) : (
                  <MessageOutlined />
                )}
              </>
            );
          },
          key: "desc_obs",
        },
        {
          title: formatMessage({ id: "label.data" }),
          width: 100,
          dataIndex: "created_at",
          render: (text) => <FormatarData value={text} />,
          key: "created_at",
        },
        {
          title: formatMessage({ id: "label.material" }),
          width: 350,
          dataIndex: "material.descricao".split("."),
          render: (text: string, record: Ent_SolicitacaoMaterial) =>
            record.material_id ? record.material?.descricao : record.descricao,
          key: "material",
        },
        {
          title: formatMessage({ id: "label.unidade" }),
          width: 75,
          dataIndex: "material.unidade.unidade".split("."),
          render: (text: any, record: Ent_SolicitacaoMaterial) => record.material?.unidade?.unidade,
          key: "material_unidade",
        },
        {
          title: formatMessage({ id: "label.unidade_solicitada" }),
          width: 85,
          dataIndex: "material.unidade.unidade".split("."),
          render: (text: any, record: Ent_SolicitacaoMaterial) => record.unidade?.unidade,
          key: "unidade_solicitada",
        },
        {
          title: formatMessage({ id: "label.quantidade" }),
          width: 100,
          align: "right",
          dataIndex: "quantidade",
          render: (text: any) => formatNumber(text, { minimumFractionDigits: 2 }),
          key: "quantidade",
        },
        {
          title: formatMessage({ id: "label.data_previsto" }),
          width: 100,
          dataIndex: "data_previsto",
          render: (text) => <FormatarData value={text} />,
          key: "data_previsto",
        },
      ];

      if (cotacao && cotacao.grupo_fornecedores) {
        cotacao.grupo_fornecedores.map((fornecedor) => {
          columns.push({
            title: fornecedor.nome,
            key: fornecedor.sf_id,
            children: [
              {
                title: "Valor Unit.",
                dataIndex: fornecedor.sfm_valor_unitario,
                key: fornecedor.sfm_valor_unitario,
                render: (text) =>
                  formatNumber(convertToFloat(text), {
                    minimumFractionDigits: 2,
                  }),
                align: "right",
                width: 100,
              },
              {
                title: "Valor",
                dataIndex: fornecedor.sfm_valor,
                key: fornecedor.sfm_valor,
                render: (text) =>
                  formatNumber(convertToFloat(text), {
                    minimumFractionDigits: 2,
                  }),
                align: "right",
                width: 120,
              },
              {
                title: "Data",
                dataIndex: fornecedor.sfm_data,
                render: (text) => <FormatarData value={text} />,
                key: fornecedor.sfm_data,
                width: 150,
              },
              {
                title: "Comprar?",
                dataIndex: fornecedor.sfm_selecionar,
                render: (text, record, index) => (text === "S" ? <CheckSquareTwoTone twoToneColor="#52c41a" /> : null),
                key: fornecedor.sfm_selecionar,
                align: "center",
                width: 90,
              },
            ],
          });
          return true;
        });
      }
      let fornecedores = cotacao.fornecedores;
      fornecedores?.map((forn) => {
        forn.selecionados = 0;
        forn.total = 0;
      });
      cotacao?.cotacao?.solicitacao_materiais?.map((row) => {
        cotacao?.grupo_fornecedores.map((gf) => {
          fornecedores?.map((forn) => {
            if (forn.pessoa_id === gf.id) {
              if (row[gf.sfm_selecionar] === "S") {
                forn.selecionados = (forn.selecionados || 0) + 1;
                forn.total = (forn.total || 0) + convertToFloat(row[gf.sfm_valor]);
              }
            }
          });
          return true;
        });
        return true;
      });
      setColunas(columns);
      setFornecedores(cotacao.fornecedores);
      resetFields();
    }
  }, [formatMessage, formatNumber, cotacao, resetFields]);

  function getSituacao(situacao: string) {
    return <Tag color={obterCorSituacaoCotacao(situacao)}>{situacao}</Tag>;
  }

  async function handleAprovar(value: string) {
    try {
      const aprovacaoVotoId = cotacao?.cotacao.aprovacao_voto?.id || 0;
      await apiAprovacaoAprovar(aprovacaoVotoId, value);
      setTimeout(() => {
        navigate("/home/usuario/aprovacoes");
      }, 500);
      return true;
    } catch (err: any) {
      return false;
    }
  }

  async function handleRecusar(value: string) {
    try {
      const aprovacaoVotoId = cotacao?.cotacao.aprovacao_voto?.id || 0;
      await apiAprovacaoRecusar(aprovacaoVotoId, value);
      setTimeout(() => {
        navigate("/home/usuario/aprovacoes");
      }, 500);
      return true;
    } catch (err: any) {
      return false;
    }
  }

  if (!cotacao || (!cotacao && actionCotacao === "BACKEND_PROCESSANDO")) {
    return <Loader />;
  }

  return (
    <>
      <Spin spinning={actionCotacao === "BACKEND_PROCESSANDO"}>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          initialValues={{
            codigo: cotacao?.cotacao?.id || "",
            data: cotacao?.cotacao?.created_at ? dayjs(cotacao?.cotacao.created_at) : null,
            user: cotacao?.cotacao?.user?.name || "",
            descricao: cotacao?.cotacao?.descricao || "",
            observacao: cotacao?.cotacao?.observacao || "",
            solicitacao_materiais:
              cotacao?.cotacao && cotacao?.cotacao.solicitacao_materiais ? cotacao?.cotacao.solicitacao_materiais : [],
          }}
          validateMessages={validateMessages}
        >
          <MenuFixo offsetTop={118}>
            <NavLink to={"/home/usuario/aprovacoes"}>
              <Button>
                <CloseCircleOutlined /> {formatMessage({ id: "label.botao.voltar" })}
              </Button>
            </NavLink>
            <ModalTextarea
              title={`${formatMessage({ id: "label.aprovacao" })} - ${formatMessage({ id: "label.botao.aprovar" })}`}
              confirmAtivo
              value=""
              onConfirm={(value: string) => handleAprovar(value)}
            >
              <Button type="primary" icon={<CheckCircleOutlined />}>
                {formatMessage({ id: "label.botao.aprovar" })}
              </Button>
            </ModalTextarea>
            <ModalTextarea
              title={`${formatMessage({ id: "label.aprovacao" })} - ${formatMessage({ id: "label.botao.recusar" })}`}
              value=""
              rules={[{ required: true }]}
              onConfirm={(value: string) => handleRecusar(value)}
            >
              <Button type="primary" danger icon={<StopOutlined />}>
                {formatMessage({ id: "label.botao.recusar" })}
              </Button>
            </ModalTextarea>
          </MenuFixo>
          <PageHeader
            style={{ paddingBottom: 0 }}
            ghost={false}
            title={cotacao?.cotacao?.alocacao?.nome || ""}
            tags={getSituacao(cotacao?.cotacao?.situacao || "")}
            subTitle={`${cotacao?.cotacao?.alocacao?.endereco || ""}${cotacao?.cotacao?.alocacao?.numero ? "," : ""} ${
              cotacao?.cotacao?.alocacao?.numero || ""
            }
        ${cotacao?.cotacao?.alocacao?.bairro ? " | " : ""}
        ${cotacao?.cotacao?.alocacao?.bairro?.nome || ""}
        ${cotacao?.cotacao?.alocacao?.cidade ? " - " : ""}
        ${cotacao?.cotacao?.alocacao?.cidade?.cidade || ""}`}
          />
          <Divider orientation="left">{formatMessage({ id: "label.solicitacao" })}</Divider>
          <Row gutter={16}>
            <Col xs={24} sm={4}>
              <Form.Item label={formatMessage({ id: "label.codigo" })} name="codigo">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={5}>
              <Form.Item label={formatMessage({ id: "label.data" })} name="data">
                <Data disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={7}>
              <Form.Item label={formatMessage({ id: "label.comprador" })} name="user">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item label={formatMessage({ id: "label.descricao" })} name="descricao">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item label={formatMessage({ id: "label.observacao" })} name="observacao">
                <Input.TextArea rows={4} disabled />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">{formatMessage({ id: "label.fornecedores" })}</Divider>
          <Row gutter={24} style={{ marginBottom: -12 }}>
            {fornecedores?.map((forn) => (
              <Col
                key={forn.id}
                xs={24}
                sm={12}
                lg={8}
                style={{
                  marginRight: 0,
                  marginBottom: 24,
                }}
              >
                <Card size="small" hoverable title={forn.pessoa?.nome || ""}>
                  <Row>
                    <Col span={14}>
                      <Statistic
                        title={formatMessage({ id: "label.total" })}
                        value={formatNumber(forn.total || 0, {
                          minimumFractionDigits: 2,
                        })}
                      />
                    </Col>
                    <Col span={10}>
                      <Statistic
                        title={formatMessage({
                          id: "label.selecionados",
                        })}
                        value={forn.selecionados || 0}
                        suffix={`/ ${cotacao.cotacao.solicitacao_materiais?.length || 0}`}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
          <Divider orientation="left">{formatMessage({ id: "label.materiais" })}</Divider>
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item name="solicitacao_materiais" valuePropName="data">
                <Tabela
                  fixarHeight={false}
                  rowKey={(record) => record.id}
                  expandedRowRender={(record: Ent_SolicitacaoMaterial) => (
                    <div className="ant-table-expanded-row-area">
                      <Col xs={24}>
                        <Descriptions size="small" column={1}>
                          <Descriptions.Item
                            label={formatMessage({
                              id: "label.descricao",
                            })}
                            span={1}
                          >
                            {record.descricao}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={formatMessage({
                              id: "label.observacao",
                            })}
                            span={1}
                          >
                            {record.observacao}
                          </Descriptions.Item>
                        </Descriptions>
                      </Col>
                    </div>
                  )}
                  tableLayout="fixed"
                  columns={colunas}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
};

export default AprovacaoCotacao;
