import { DatePicker } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker';
import React from 'react';

import 'dayjs/locale/pt-br';

export default class Data extends React.Component<DatePickerProps> {
  render() {
    return <DatePicker {...this.props} format={'DD/MM/YYYY'} style={{width: '100%'}} />;
  }
}
