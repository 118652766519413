import {
  CalendarTwoTone,
  CloseOutlined,
  ContactsOutlined,
  DeleteOutlined,
  DollarOutlined,
  EditOutlined,
  LinkOutlined,
  PlusOutlined,
  SaveOutlined
} from "@ant-design/icons";
import { Badge, Button, Calendar, Card, Col, Drawer, Form, Input, List, notification, Row } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Ent_Calendario, Ent_TituloView, ItemListaCalendario } from "src/services/interface";
import {
  calendarioCloseAction,
  calendarioSalvarResetAction,
  calendarioSuccess,
  fetchCalendarioList,
  fetchCalendarioSalvar
} from "src/store/features/calendario/calendarioSlice";
import { RootState } from "src/store/features/rootReducer";

import { useForm } from "antd/lib/form/Form";
import Data from "src/components/Data";
import validateMessages from "src/util/validateMessages";
import "./calendario.css";

function validateSelectFilter(item: ItemListaCalendario, date: dayjs.Dayjs) {
  const tipo = (item as Ent_Calendario).__tipo;
  switch (tipo) {
    case "TITULO":
      return (item as Ent_TituloView).vencimento === date.format("YYYY-MM-DD");
    default:
      return (item as Ent_Calendario).data === date.format("YYYY-MM-DD");
  }
}

function getEventName(item: ItemListaCalendario, intl: IntlShape) {
  const tipo = (item as Ent_Calendario).__tipo;
  switch (tipo) {
    case "TITULO":
      return `${intl.formatNumber((item as Ent_TituloView).valor, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}-${(item as Ent_TituloView).nome_alocacao}`;
    default:
      return (item as Ent_Calendario).evento;
  }
}

function getEventDescription(item: ItemListaCalendario, intl: IntlShape) {
  const tipo = (item as Ent_Calendario).__tipo;
  switch (tipo) {
    case "TITULO":
      return (
        <span className="text-overflow">
          {intl.formatMessage({ id: "label.pessoa" })}
          {": "}
          {(item as Ent_TituloView).nome_pessoa}
        </span>
      );
    default:
      return <span className="text-overflow">{(item as Ent_Calendario).descricao}</span>;
  }
}

function getEventElement(item: ItemListaCalendario, intl: IntlShape) {
  const tipo = (item as Ent_Calendario).__tipo;
  switch (tipo) {
    case "TITULO":
      return <Badge color={(item as any).cor || "#FFF"} text={getEventName(item, intl)} />;
    default:
      return (
        <span className="ant-badge ant-badge-status ant-badge-not-a-wrapper">
          <span className="ant-badge-status-text" style={{ marginLeft: 0 }}>
            {getEventName(item, intl)}
          </span>
        </span>
      );
  }
}

interface PropsOwn {
  className?: string;
}

type Props = PropsOwn;

const Calendario: React.FC<Props> = ({ className }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [visibleEvento, setVisibleEvento] = useState(false);
  const [visibleDay, setVisibleDay] = useState(false);
  const [atualMode, setAtualMode] = useState<"month" | "year" | undefined>("month");
  const [atualData, setAtualData] = useState<dayjs.Dayjs | undefined>();
  const { calendarioList, calendario, actionCalendario, actionCalendarioSalvar, errorCalendarioSalvar } = useSelector(
    (state: RootState) => state.calendario
  );
  const [form] = useForm();
  const { resetFields } = form;

  useEffect(() => {
    const dia = dayjs().date();
    const inicio = dayjs()
      .subtract(dia + 38, "days")
      .format("YYYY-MM-DD");
    const termino = dayjs()
      .add(70 - dia, "days")
      .format("YYYY-MM-DD");
    dispatch(fetchCalendarioList(inicio, termino));
  }, [dispatch]);

  useEffect(() => {
    if (calendario) {
      resetFields();
      setVisibleEvento(true);
    } else {
      setVisibleEvento(false);
    }
  }, [resetFields, calendario]);

  useEffect(() => {
    switch (actionCalendarioSalvar) {
      case "BACKEND_SUCESSO":
        dispatch(calendarioSalvarResetAction());
        notification.success({
          message: intl.formatMessage({ id: "label.sucesso" }),
          description: intl.formatMessage({ id: "label.mensagem_sucesso" }),
        });
        break;
      case "BACKEND_ERRO":
        if (errorCalendarioSalvar) {
          notification.error({
            message: intl.formatMessage({ id: "label.atencao" }),
            description: errorCalendarioSalvar,
          });
        }
        break;
    }
  }, [dispatch, actionCalendarioSalvar, errorCalendarioSalvar, intl]);

  function dateCellRender(value: dayjs.Dayjs) {
    return (
      <ul className="events">
        {calendarioList
          ?.filter?.((item) => value && validateSelectFilter(item, value))
          ?.map((item) => (
            <li
              className="evento-calendario-item"
              key={item.id}
              style={{ fontSize: 10 }}
              title={getEventName(item, intl)}
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                handleSelectEvent(item as Ent_Calendario);
              }}
            >
              {getEventElement(item, intl)}
            </li>
          ))}
      </ul>
    );
  }

  function handleVisibleDay() {
    setVisibleDay(!visibleDay);
    if (visibleDay) {
      setAtualData(undefined);
    }
  }

  function handleAddCalenario() {
    dispatch(
      calendarioSuccess({
        id: undefined,
        data: (atualData || dayjs()).format("YYYY-MM-DD"),
      } as Ent_Calendario)
    );
  }

  function handleSelectEvent(item: Ent_Calendario) {
    dispatch(calendarioSuccess(item));
  }

  function handleSelect(date?: dayjs.Dayjs) {
    if (atualMode === "year") {
      setAtualMode("month");
    } else {
      setAtualData(date);
      handleVisibleDay();
    }
  }

  function handlePanelChange(date?: dayjs.Dayjs, mode?: "month" | "year") {
    setAtualMode(mode);
    if (date) {
      const dia = date?.date();
      const inicio = dayjs(date.format("YYYY-MM-DD"))
        .subtract(dia + 38, "days")
        .format("YYYY-MM-DD");
      const termino = dayjs(date.format("YYYY-MM-DD"))
        .add(70 - dia, "days")
        .format("YYYY-MM-DD");
      dispatch(fetchCalendarioList(inicio, termino));
    }
  }

  function getActionList(item: ItemListaCalendario) {
    const tipo = (item as Ent_Calendario).__tipo;
    switch (tipo) {
      case "TITULO":
        return [
          <div className="in-line">
            <LinkOutlined />
            <div style={{ marginLeft: 8 }}>
              <Link to={`/home/financeiro/titulo/man?id=${item.id}&edit=sim`}>
                {intl.formatMessage({ id: "pagina.titulo" })}
              </Link>
            </div>
          </div>,
          <div className="in-line">
            <DollarOutlined />
            <div style={{ marginLeft: 8 }}>
              {intl.formatNumber((item as Ent_TituloView).valor, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </div>,
        ];
      default:
        const acao: any = [];
        if ((item as Ent_Calendario).calendario_grupo === "S") {
          acao.push(
            <div className="in-line">
              <ContactsOutlined />
              <div style={{ marginLeft: 8 }}>Grupo</div>
            </div>
          );
        }
        return [
          ...acao,
          <div className="in-line" onClick={() => handleSelectEvent(item as Ent_Calendario)}>
            <EditOutlined />
            <div style={{ marginLeft: 8 }}>{intl.formatMessage({ id: "label.botao.alterar" })}</div>
          </div>,
        ];
    }
  }

  function handleOk(values: any) {
    const payload: Ent_Calendario = { ...calendario, ...values, data: values.data.format("YYYY-MM-DD") };
    dispatch(fetchCalendarioSalvar(payload));
  }

  function handleExcluir() {
    // dispatch(Creators.removerCalendario(item?.id || 0));
  }

  function createListItem(lista: ItemListaCalendario[]) {
    return (
      <List
        itemLayout="vertical"
        size="small"
        dataSource={lista}
        renderItem={(item) => (
          <List.Item className="list-item-hover" key={item.id} actions={getActionList(item)}>
            <List.Item.Meta title={getEventElement(item, intl)} description={getEventDescription(item, intl)} />
          </List.Item>
        )}
      />
    );
  }

  return (
    <Card
      title={
        <>
          <CalendarTwoTone twoToneColor="#a907ec" style={{ fontSize: 17 }} />{" "}
          {intl.formatMessage({ id: "label.calendario" })}
        </>
      }
      extra={<Button shape="circle" icon={<PlusOutlined />} onClick={handleAddCalenario} />}
      className={`card-body-mb-0 ${className}`}
    >
      <Row gutter={24}>
        <Calendar
          className="calendario-status-fonte"
          mode={atualMode}
          onPanelChange={handlePanelChange}
          onSelect={handleSelect}
          dateCellRender={dateCellRender}
        />
      </Row>
      <Drawer
        width={"90%"}
        placement="right"
        title={`${intl.formatMessage({
          id: "label.calendario",
        })} - ${atualData?.format("dddd")}, ${atualData?.format("DD")} de ${atualData?.format(
          "MMMM"
        )} de ${atualData?.format("YYYY")}`}
        closable
        onClose={handleVisibleDay}
        open={visibleDay}
      >
        <div
          style={{
            height: "calc(100vh - 114px)",
            overflow: "auto",
            padding: "7px 23px 23px 23px",
          }}
          className="list-items-calendar"
        >
          {atualData && createListItem(calendarioList?.filter((item) => validateSelectFilter(item, atualData)) || [])}
        </div>
        <div
          style={{
            position: "absolute",
            left: 0,
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #e9e9e9",
            padding: "10px 16px",
            background: "#fff",
            textAlign: "right",
          }}
        >
          <Button icon={<CloseOutlined />} onClick={handleVisibleDay} style={{ marginRight: 8 }}>
            {intl.formatMessage({ id: "label.botao.voltar" })}
          </Button>
          <Button icon={<PlusOutlined />} onClick={handleAddCalenario} type="primary">
            {intl.formatMessage({ id: "label.botao.novo" })}
          </Button>
        </div>
      </Drawer>
      <Drawer
        className="sub-drawer-1"
        width={"80%"}
        placement="right"
        title={`${intl.formatMessage({
          id: "pagina.man",
        })} ${intl.formatMessage({
          id: "label.calendario",
        })}`}
        closable
        onClose={() => calendario && dispatch(calendarioCloseAction())}
        open={visibleEvento}
      >
        {calendario && (
          <>
            <div
              style={{
                height: "calc(100vh - 114px)",
                overflow: "auto",
                padding: "7px 23px 23px 23px",
              }}
            >
              {calendario?.__tipo ? (
                calendario && createListItem([calendario])
              ) : (
                <Form
                  layout="vertical"
                  autoComplete="off"
                  onFinish={handleOk}
                  form={form}
                  initialValues={{
                    data: calendario?.data ? dayjs(calendario.data) : dayjs(),
                    evento: calendario?.evento || null,
                    descricao: calendario?.descricao || null,
                  }}
                  validateMessages={validateMessages}
                >
                  <Row gutter={24}>
                    <Col xs={24} sm={8}>
                      <Form.Item
                        name="data"
                        label={intl.formatMessage({ id: "label.data" })}
                        rules={[{ required: true }]}
                      >
                        <Data disabled={!!calendario.calendario_id || actionCalendario === "BACKEND_PROCESSANDO"} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={16}>
                      <Form.Item
                        name="evento"
                        label={intl.formatMessage({ id: "label.evento" })}
                        rules={[{ required: true }, { max: 200 }]}
                      >
                        <Input disabled={!!calendario.calendario_id || actionCalendario === "BACKEND_PROCESSANDO"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col xs={24} sm={24}>
                      <Form.Item name="descricao" label={intl.formatMessage({ id: "label.descricao" })}>
                        <Input.TextArea
                          rows={7}
                          disabled={!!calendario.calendario_id || actionCalendario === "BACKEND_PROCESSANDO"}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
            {!calendario?.__tipo && (
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                  width: "100%",
                  borderTop: "1px solid #e9e9e9",
                  padding: "10px 23px",
                  background: "#fff",
                  textAlign: "right",
                }}
              >
                <Button
                  icon={<CloseOutlined />}
                  onClick={() => dispatch(calendarioCloseAction())}
                  style={{ marginRight: 8 }}
                  disabled={actionCalendario === "BACKEND_PROCESSANDO"}
                >
                  {intl.formatMessage({ id: "label.botao.cancelar" })}
                </Button>
                {calendario?.id && (
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={handleExcluir}
                    danger
                    disabled={!!calendario.calendario_id || actionCalendario === "BACKEND_PROCESSANDO"}
                    style={{ marginRight: 8 }}
                  >
                    {intl.formatMessage({ id: "label.botao.excluir" })}
                  </Button>
                )}
                <Button
                  loading={actionCalendario === "BACKEND_PROCESSANDO"}
                  disabled={!!calendario.calendario_id || actionCalendario === "BACKEND_PROCESSANDO"}
                  icon={<SaveOutlined />}
                  onClick={() => form.submit()}
                  type="primary"
                >
                  {intl.formatMessage({ id: "label.botao.salvar" })}
                </Button>
              </div>
            )}
          </>
        )}
      </Drawer>
    </Card>
  );
};

export default Calendario;
