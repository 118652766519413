import { FolderOpenTwoTone } from "@ant-design/icons";
import React, { Checkbox, Col, Image, Row, Typography } from "antd";
import dayjs from "dayjs";
import { MouseEvent } from "react";
import { useIntl } from "react-intl";
import { Ent_Files } from "src/services/interface";
import { formatBytes } from "src/util/geral";
import { COLOR_FOLDER_BACK, EXTENSION_IMAGE, iconFile } from "src/util/helperComponents";
import { FileViewProps } from "./FileView";

const colResponsiveSize = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 8,
  xl: 6,
  xxl: 3,
};

export const FileGridView = ({
  list,
  selectedFile,
  paths,
  onSelectFileClick,
  onAddSelectionFileClick,
  onBackClick,
  style,
  children,
}: FileViewProps) => {
  const { formatMessage } = useIntl();

  function onSelectClick(event: MouseEvent<HTMLElement>, file: Ent_Files) {
    event.stopPropagation();
    event.preventDefault();
    if (event.shiftKey) {
      onSelectionClick(event, file);
    } else {
      onSelectFileClick(file);
    }
  }

  function onSelectionClick(event: MouseEvent<HTMLElement>, file: Ent_Files) {
    event.stopPropagation();
    event.preventDefault();
    onAddSelectionFileClick(file);
  }

  function onBack(event: MouseEvent<HTMLElement>) {
    event.preventDefault();
    event.stopPropagation();
    onBackClick();
  }

  return (
    <Row style={style} gutter={[8, 8]} className="list-file" onClick={() => onSelectFileClick(undefined)}>
      <Col xs={24}>
        <Row>
          {paths.length ? (
            <Col {...colResponsiveSize} onClick={onBack}>
              <Col className="file">
                <div className="file-icon">
                  <FolderOpenTwoTone twoToneColor={COLOR_FOLDER_BACK} style={{ fontSize: "125%" }} />
                </div>
                <div className="file-description">
                  <Typography.Text
                    title={formatMessage({ id: "label.botao.voltar" })}
                    strong
                    ellipsis
                    style={{ fontSize: "90%", textAlign: "center" }}
                  >
                    {formatMessage({ id: "label.botao.voltar" })}
                  </Typography.Text>
                </div>
              </Col>
            </Col>
          ) : null}
          {list?.length
            ? list.map((file) => (
                <Col {...colResponsiveSize} key={file.id} onClick={(e) => onSelectClick(e, file)}>
                  <Checkbox
                    className="file-check"
                    checked={!!selectedFile?.find((f) => f.id === file.id)}
                    onClick={(e) => onSelectionClick(e, file)}
                  />
                  <Col className={`file ${!!selectedFile?.find((f) => f.id === file.id) ? "selected-file" : ""}`}>
                    {file.folder !== "S" && EXTENSION_IMAGE.includes(file.extension || "") && file.temporary_url ? (
                      <Image
                        className="file-icon"
                        wrapperStyle={{ width: "100%" }}
                        style={{ objectFit: "contain" }}
                        src={file.temporary_url}
                      />
                    ) : (
                      <div className="file-icon">{iconFile(file)}</div>
                    )}
                    <div className="file-description">
                      <Typography.Text
                        title={file.name}
                        strong
                        ellipsis
                        style={{ fontSize: "90%", textAlign: "center" }}
                      >
                        {file.name}
                      </Typography.Text>
                      {file.size ? (
                        <div className="d-flex justify-content-space-between font-sz-70 nowrap">
                          <span title={file.name} className="text-overflow">
                            {dayjs(file.updated_at).format("L LT")}
                          </span>
                          <span>{file.size > 0 ? formatBytes(file.size) : ""}</span>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Col>
              ))
            : null}
        </Row>
      </Col>
      {children}
    </Row>
  );
};
