import { Breadcrumb, Drawer, Layout } from "antd";
import React, { useEffect, useState } from "react";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";

import { useIntl } from "react-intl";
import Header from "src/components/Header";
import Sidebar from "src/components/Sidebar";
import { Const } from "src/util/geral";
import AprovacaoCotacao from "../Aprovacao/Cotacao";
import RouteContrato from "../Cliente/Contrato/RouteContrato";
import RouteEmpreendimento from "../Cliente/Empreendimento/RouteContrato";
import RouteRelatorio from "../Cliente/Relatorio/RouteRelatorio";
import Inicio from "../Dashboard/Inicio";
import styles from "./home.module.css";
import MinhasAprovacoes from "./MinhasAprovacoes";
import Perfil from "./Perfil";

const { enquireScreen, unenquireScreen } = require("enquire-js");

const Bread = () => {
  const intl = useIntl();
  const location = useLocation();
  const styleBreadcrumb: {} = {
    backgroundColor: "rgba(229, 230, 232, 0.61)",
    padding: "6px 24px",
    boxShadow: "0 1px 4px rgba(0, 21, 41, .08)",
    whiteSpace: "nowrap",
    overflowX: "auto",
  };

  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const ultimo = pathSnippets.length - 1;
  const extraBreadcrumbItems = pathSnippets.map((_: any, index: number) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return (
      <Breadcrumb.Item key={url}>
        {index < ultimo ? (
          <Link to={url}>{intl.formatMessage({ id: `pagina.${pathSnippets[index]}` })}</Link>
        ) : (
          intl.formatMessage({ id: `pagina.${pathSnippets[index]}` })
        )}
      </Breadcrumb.Item>
    );
  });
  const breadcrumbItems = extraBreadcrumbItems;

  return <Breadcrumb style={styleBreadcrumb}>{breadcrumbItems}</Breadcrumb>;
};

const Home = () => {
  let mode = "inline";
  const [isMobile, setIsMobile] = useState(false);
  const [collapsedMobile, setCollapsedMobile] = useState(true);
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem(Const.LOCAL_STORAGE_COLLAPSED) === "true" ? true : false
  );

  useEffect(() => {
    const enquireHandler = enquireScreen((mobile: boolean) => {
      if (isMobile !== mobile) {
        setIsMobile(mobile);
      }
    });

    return () => unenquireScreen(enquireHandler);
  });

  function setCollapsedValue(collapsed: boolean) {
    if (isMobile) {
      setCollapsedMobile(collapsed);
    } else {
      setCollapsed(collapsed);
      localStorage.setItem(Const.LOCAL_STORAGE_COLLAPSED, String(collapsed));
    }
  }
  return (
    <Layout>
      {isMobile ? (
        <Drawer
          maskClosable
          closable={false}
          onClose={() => setCollapsedValue(!collapsedMobile)}
          open={!collapsedMobile}
          placement="left"
          width={270}
          style={{
            padding: 0,
            height: "100vh",
          }}
        >
          <Sidebar isMobile={true} collapsed={false} setCollapsed={setCollapsedValue} mode={mode} />
        </Drawer>
      ) : (
        <Sidebar isMobile={false} collapsed={collapsed} setCollapsed={setCollapsedValue} mode={mode} />
      )}
      <Layout className={`${styles["content-wrapper"]}`}>
        <Header collapsed={isMobile ? collapsedMobile : collapsed} setCollapsed={setCollapsedValue} />
        <Layout.Content>
          <Bread />
          <div className={`${styles.content}`}>
            <Routes>
              <Route index element={<Inicio />} />
              <Route path="empreendimento/*" element={<RouteEmpreendimento />} />
              <Route path="contrato/*" element={<RouteContrato />} />
              <Route path="relatorio/*" element={<RouteRelatorio />} />
              <Route path="aprovacao/cotacao" element={<AprovacaoCotacao />} />
              <Route path="usuario/perfil" element={<Perfil />} />
              <Route path="usuario/aprovacoes" element={<MinhasAprovacoes />} />
              <Route path="*" element={<Navigate to={"/home"} />} />
            </Routes>
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};
export default Home;
