import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiContrato, apiContratoList } from 'src/services/funcoesService';
import { BACKEND_AGUARDANDO, BACKEND_ERRO, BACKEND_PROCESSANDO, BACKEND_SUCESSO, Ent_ClienteContratoList, Ent_ContratoRecebimento } from 'src/services/interface';
import { AppThunk } from 'src/store';

export interface ContratoInitialState {
    actionContratoList: BACKEND_AGUARDANDO | BACKEND_PROCESSANDO | BACKEND_SUCESSO | BACKEND_ERRO;
    actionContrato: BACKEND_AGUARDANDO | BACKEND_PROCESSANDO | BACKEND_SUCESSO | BACKEND_ERRO;
    errorContratoList: string | null;
    errorContrato: string | null,
    contratoList: Ent_ClienteContratoList[],
    contrato: Ent_ContratoRecebimento | null,
}

const contratoInitialState: ContratoInitialState = {
    actionContratoList: 'BACKEND_AGUARDANDO',
    actionContrato: 'BACKEND_AGUARDANDO',
    contratoList: [],
    contrato: null,
    errorContratoList: null,
    errorContrato: null
}

export const contratoSlice = createSlice({
    name: 'contrato',
    initialState: contratoInitialState,
    reducers: {
        contratoResetAction(state) {
            state.actionContrato = 'BACKEND_AGUARDANDO';
            state.errorContrato = null;
        },
        contratoStart(state) {
            state.actionContrato = 'BACKEND_PROCESSANDO';
            state.errorContrato = null;
        },
        contratoSuccess(state, { payload }: PayloadAction<Ent_ContratoRecebimento>) {
            state.actionContrato = 'BACKEND_SUCESSO';
            state.contrato = payload;
        },
        contratoError(state, { payload }: PayloadAction<string>) {
            state.actionContrato = 'BACKEND_ERRO';
            state.errorContrato = payload;
        },
        contratoListResetAction(state) {
            state.actionContratoList = 'BACKEND_AGUARDANDO';
            state.errorContratoList = null;
        },
        contratoListStart(state) {
            state.actionContratoList = 'BACKEND_PROCESSANDO';
            state.errorContratoList = null;
        },
        contratoListSuccess(state, { payload }: PayloadAction<any[]>) {
            state.actionContratoList = 'BACKEND_SUCESSO';
            state.contratoList = payload;
        },
        contratoListError(state, { payload }: PayloadAction<string>) {
            state.actionContratoList = 'BACKEND_ERRO';
            state.errorContratoList = payload;
        }
    }
});

export const {
    contratoResetAction,
    contratoStart,
    contratoSuccess,
    contratoError,
    contratoListResetAction,
    contratoListStart,
    contratoListSuccess,
    contratoListError
} = contratoSlice.actions;

export const fetchContratoList = (): AppThunk => async dispatch => {
    try {
        dispatch(contratoListStart());
        const data = await apiContratoList();
        dispatch(contratoListSuccess(data));
    } catch (err: any) {
        dispatch(contratoListError(err.message));
    }
}

export const fetchContrato = (contratoRecebimentoId: number): AppThunk => async dispatch => {
    try {
        dispatch(contratoStart());
        const data = await apiContrato(contratoRecebimentoId);
        dispatch(contratoSuccess(data));
    } catch (err: any) {
        dispatch(contratoError(err.message));
    }
}

export default contratoSlice.reducer;