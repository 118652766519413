import { ArrowLeftOutlined } from "@ant-design/icons";
import { AvatarProps, Breadcrumb, BreadcrumbProps, Button, Col, Row, Space, TagType, Typography } from "antd";
import React, { PropsWithChildren } from "react";

interface Props {
  backIcon?: React.ReactNode;
  prefixCls?: string;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  style?: React.CSSProperties;
  breadcrumb?: BreadcrumbProps | React.ReactElement<typeof Breadcrumb>;
  breadcrumbRender?: (props: Props, defaultDom: React.ReactNode) => React.ReactNode;
  tags?: React.ReactElement<TagType> | React.ReactElement<TagType>[];
  footer?: React.ReactNode;
  extra?: React.ReactNode;
  avatar?: AvatarProps;
  onBack?: (e?: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
  ghost?: boolean;
}

export const PageHeader = ({
  backIcon,
  prefixCls,
  title,
  subTitle,
  style,
  breadcrumb,
  breadcrumbRender,
  tags,
  footer,
  extra,
  avatar,
  onBack,
  className,
  ghost,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <Row>
      <Col xs={24}>
        <Row>
          <Col xs={24}>
            <Space>
              {backIcon && <Button icon={<ArrowLeftOutlined />} onClick={() => onBack?.()} />}
              {title && (
                <Typography.Title level={4} style={{ marginBottom: 0 }}>
                  {title}
                </Typography.Title>
              )}
              {subTitle && (
                <Typography.Text type="secondary" style={{ marginBottom: 0 }}>
                  {subTitle}
                </Typography.Text>
              )}
              {tags && tags}
              <div style={{ width: "100%" }}></div>
              {extra}
            </Space>
          </Col>
        </Row>
        {children && (
          <Row>
            <Col xs={24}>{children}</Col>
          </Row>
        )}
        {footer && (
          <Row>
            <Col xs={24}>{footer}</Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};
