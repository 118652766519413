import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Button, Carousel, Col, Row, Spin } from "antd";
import React, { useMemo, useRef } from "react";
import { EXTENSION_IMAGE } from "src/util/helperComponents";
import { FileGridView } from "./FileGridView";
import { FileViewProps } from "./FileView";

export const FileImageFirstView = ({ list, style, onSelectFileClick, children, ...rest }: FileViewProps) => {
  const refCousel = useRef<any>();

  const { images, files } = useMemo(() => {
    const images = list?.filter((file) => EXTENSION_IMAGE.includes(file.extension || "")) || [];
    const files = list?.filter((file) => !EXTENSION_IMAGE.includes(file.extension || "")) || [];
    return { images, files };
  }, [list]);

  function moveCarousel(to: "<" | ">") {
    if (refCousel.current) {
      switch (to) {
        case "<":
          refCousel.current.prev();
          break;
        case ">":
          refCousel.current.next();
          break;
      }
    }
  }

  return (
    <Row
      style={{ ...(style || {}), height: "100%" }}
      gutter={[8, 8]}
      className="list-file"
      onClick={() => onSelectFileClick(undefined)}
    >
      {images.length ? (
        <Col xs={24}>
          <Carousel ref={refCousel} style={{ width: "100%" }}>
            {images.map((file) => (
              <div key={file.id}>
                <Spin spinning={!file.temporary_url}>
                  <div
                    style={{
                      backgroundImage: `url(${file.temporary_url})`,
                    }}
                  ></div>
                </Spin>
              </div>
            ))}
          </Carousel>
          <div className="move-carousel">
            <Button shape="circle" type="primary" onClick={() => moveCarousel("<")} icon={<LeftCircleOutlined />} />
            <Button shape="circle" type="primary" onClick={() => moveCarousel(">")} icon={<RightCircleOutlined />} />
          </div>
        </Col>
      ) : null}
      <Col xs={24} style={{ height: "100%" }}>
        <FileGridView list={files} onSelectFileClick={onSelectFileClick} {...rest} />
      </Col>
      {children}
    </Row>
  );
};
