import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiSolicitacaoAprovacaoCotacao } from 'src/services/funcoesService';
import { BACKEND_AGUARDANDO, BACKEND_ERRO, BACKEND_PROCESSANDO, BACKEND_SUCESSO, Ent_SolicitacaoCotacao } from 'src/services/interface';
import { AppThunk } from 'src/store';

export interface AprovacaoInitialState {
  actionCotacao: BACKEND_AGUARDANDO | BACKEND_PROCESSANDO | BACKEND_SUCESSO | BACKEND_ERRO;
  errorCotacao: string | null,
  cotacao: Ent_SolicitacaoCotacao | null,
}

const aprovacaoInitialState: AprovacaoInitialState = {
  actionCotacao: 'BACKEND_AGUARDANDO',
  errorCotacao: null,
  cotacao: null,
}

export const aprovacaoSlice = createSlice({
  name: 'aprovacao',
  initialState: aprovacaoInitialState,
  reducers: {
    cotacaoResetAction(state) {
      state.actionCotacao = 'BACKEND_AGUARDANDO';
      state.errorCotacao = null;
    },
    cotacaoStart(state) {
      state.actionCotacao = 'BACKEND_PROCESSANDO';
      state.errorCotacao = null;
    },
    cotacaoSuccess(state, { payload }: PayloadAction<Ent_SolicitacaoCotacao>) {
      state.actionCotacao = 'BACKEND_SUCESSO';
      state.cotacao = payload;
    },
    cotacaoError(state, { payload }: PayloadAction<string>) {
      state.actionCotacao = 'BACKEND_ERRO';
      state.errorCotacao = payload;
    },
  }
});

export const {
  cotacaoResetAction,
  cotacaoStart,
  cotacaoSuccess,
  cotacaoError,
} = aprovacaoSlice.actions;

export const fetchAprovacaoCotacao = (id: number): AppThunk => async dispatch => {
  try {
    dispatch(cotacaoStart());
    const data = await apiSolicitacaoAprovacaoCotacao(id);
    dispatch(cotacaoSuccess(data));
  } catch (err: any) {
    dispatch(cotacaoError(err.message));
  }
}

export default aprovacaoSlice.reducer;