import React, { Col, Divider, Tag, Typography } from "antd";
import dayjs from "dayjs";
import { useIntl } from "react-intl";
import { Ent_Files } from "src/services/interface";
import { formatBytes } from "src/util/geral";
import { iconFile } from "src/util/helperComponents";

interface Props {
  file: Ent_Files;
  onDeleteTag?: (item: string) => Promise<void>;
  onAddTag?: (item: string) => Promise<void>;
}
export const FileDetails = ({ file, onAddTag, onDeleteTag }: Props) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Col xs={24} style={{ display: "flex", flexDirection: "column", padding: 8, marginLeft: "10px solid #ccc" }}>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          {iconFile(file, { fontSize: 100, marginBottom: 16 })}
        </div>
        <Typography.Text style={{ fontSize: "90%" }}>{formatMessage({ id: "label.nome" })}</Typography.Text>
        <Typography.Text strong style={{ fontSize: "90%" }}>
          {file.name}
        </Typography.Text>
        <Divider style={{ marginTop: 4, marginBottom: 4 }} />

        <Typography.Text style={{ fontSize: "90%" }}>{formatMessage({ id: "label.tags" })}</Typography.Text>
        {file?.tags?.map((tag) => (
          <Tag>{tag}</Tag>
        ))}
        <Divider style={{ marginTop: 4, marginBottom: 4 }} />

        <Typography.Text style={{ fontSize: "90%" }}>{formatMessage({ id: "label.observacao" })}</Typography.Text>
        <Typography.Text strong style={{ fontSize: "90%" }}>
          {file?.label}
        </Typography.Text>
        <Divider style={{ marginTop: 4, marginBottom: 4 }} />

        {file?.size ? (
          <>
            <Typography.Text style={{ fontSize: "90%" }}>{formatMessage({ id: "label.tamanho" })}</Typography.Text>
            <Typography.Text strong style={{ fontSize: "90%" }}>
              {formatBytes(file?.size || 0)}
            </Typography.Text>
            <Divider style={{ marginTop: 4, marginBottom: 4 }} />
          </>
        ) : null}

        <Typography.Text style={{ fontSize: "90%" }}>{formatMessage({ id: "label.updated_at" })}</Typography.Text>
        <Typography.Text strong style={{ fontSize: "90%" }}>
          {dayjs(file?.updated_at).format("L LT")}
        </Typography.Text>
        <Divider style={{ marginTop: 4, marginBottom: 4 }} />

        <Typography.Text style={{ fontSize: "90%" }}>{formatMessage({ id: "label.created_at" })}</Typography.Text>
        <Typography.Text strong style={{ fontSize: "90%" }}>
          {dayjs(file?.created_at).format("L LT")}
        </Typography.Text>
        <Divider style={{ marginTop: 4, marginBottom: 4 }} />

        <Typography.Text style={{ fontSize: "90%" }}>{formatMessage({ id: "label.descricao" })}</Typography.Text>
        <Typography.Text strong style={{ fontSize: "90%" }}>
          {file?.description}
        </Typography.Text>
        <Divider style={{ marginTop: 4, marginBottom: 4 }} />
      </Col>
    </>
  );
};
