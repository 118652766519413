import { DoubleRightOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Const } from "src/util/geral";

import { RootState } from "src/store/features/rootReducer";
import { getIcon } from "src/util/helperComponents";
import logoIco from "../../assets/images/logo-icon.png";
import logo from "../../assets/images/logo-name.png";
import ScrollBar from "../ScrollBar";
import styles from "./sidebar.module.css";

const { Sider } = Layout;

const Sidebar = ({ isMobile, collapsed, setCollapsed, mode }: any) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [themeStyle, setThemeStyle] = useState<string>(localStorage.getItem(Const.LOCAL_STORAGE_THEME) || "light");
  const p = window.location.pathname;

  const { menu } = useSelector((state: RootState) => state.login);

  function changeTheme() {
    const theme = themeStyle === "dark" ? "light" : "dark";
    localStorage.setItem(Const.LOCAL_STORAGE_THEME, theme);
    setThemeStyle(theme);
  }

  return (
    <Sider
      theme="light"
      onCollapse={setCollapsed}
      collapsible
      collapsed={collapsed}
      width={270}
      trigger={null}
      collapsedWidth={90}
      style={{
        boxShadow: "rgb(149, 159, 166) 0px 0px 5px 0px",
      }}
    >
      <div className="logo">
        <Link to="/">
          {collapsed ? (
            <>
              <img src={logoIco} alt="logo" style={{ marginLeft: 15, marginRight: 9 }} />
            </>
          ) : (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <img src={logo} alt="logo" style={{ marginLeft: 9, marginRight: 9 }} />
              <div style={{ fontSize: 9, display: "flex", flexDirection: "column" }}>
                <div style={{ height: 10 }}>Versão</div>
                <div style={{ height: 10 }}>{process.env.REACT_APP_VERSION}</div>
              </div>{" "}
            </div>
          )}
        </Link>
      </div>
      <div className={styles.menuContainer}>
        <ScrollBar
          options={{
            suppressScrollX: true,
          }}
        >
          <Menu
            theme={"light"}
            defaultOpenKeys={["empresa"]}
            selectedKeys={[p]}
            mode={mode || "inline"}
            items={menu.map((item) => {
              if (Array.isArray(item.children)) {
                return {
                  key: item.key,
                  icon: getIcon(item.icon, { twoToneColor: item.color, style: { fontSize: 20 } }),
                  label: intl.formatMessage({ id: item.label }),
                  children: item.children.map((subItem) => ({
                    key: subItem.key,
                    onClick: () => {
                      isMobile && setCollapsed(!collapsed);
                      navigate(subItem.url);
                    },
                    icon: <DoubleRightOutlined />,
                    label: intl.formatMessage({ id: subItem.label }),
                  })),
                };
              } else {
                return {
                  key: item.key,
                  onClick: () => {
                    isMobile && setCollapsed(!collapsed);
                    item?.url && navigate(item?.url);
                  },
                  icon: getIcon(item.icon, { twoToneColor: item.color || "#CCC", style: { fontSize: 20 } }),
                  label: intl.formatMessage({ id: item.label }),
                };
              }
            })}
          />
        </ScrollBar>
      </div>
    </Sider>
  );
};

export default Sidebar;
