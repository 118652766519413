import { SyncOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Tag, Spin } from "antd";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { Ent_AprovacaoVoto } from "src/services/interface";
import { getUrlAprovacao, obterCorSituacaoAprovacao } from "src/util/geral";
import { ColumnsType } from "antd/lib/table";
import FormatarData from "src/components/Data/FormatarData";
import MenuFixo from "src/components/MenuFixo";
import { RootState } from "src/store/features/rootReducer";
import { fetchAprovacoes } from "src/store/features/app/loginSlice";
import Tabela from "src/components/Tabela";

const MinhasAprovacoes = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { aprovacoes, actionAprovacoes } = useSelector((state: RootState) => state.login);

  useEffect(() => {
    dispatch(fetchAprovacoes());
  }, [dispatch]);

  const columns: ColumnsType<Ent_AprovacaoVoto> = [
    {
      title: formatMessage({ id: "label.data" }),
      width: 110,
      dataIndex: "created_at",
      render: (text) => <FormatarData value={text} />,
      key: "created_at",
    },
    {
      title: formatMessage({ id: "label.descricao" }),
      dataIndex: "descricao",
      key: "descricao",
    },
    {
      title: formatMessage({ id: "label.data_expiracao" }),
      width: 110,
      dataIndex: "expiracao",
      render: (text) => <FormatarData value={text} />,
      key: "expiracao",
    },
    {
      title: formatMessage({ id: "label.situacao" }),
      width: 130,
      dataIndex: "situacao",
      render: (text: any) => <Tag color={obterCorSituacaoAprovacao(text)}>{text}</Tag>,
      key: "situacao",
    },
    {
      title: "Ações",
      key: "operation",
      width: 110,
      fixed: "right",
      render: (_, record) => (
        <Button.Group>
          <NavLink to={getUrlAprovacao(record.aprovacao?.origem!, record.aprovacao?.chave!)}>
            <Button type="primary">
              <EyeOutlined />
            </Button>
          </NavLink>
        </Button.Group>
      ),
    },
  ];

  function handleAtualizar() {
    dispatch(fetchAprovacoes());
  }

  return (
    <Spin spinning={actionAprovacoes === "BACKEND_PROCESSANDO"}>
      <MenuFixo offsetTop={118}>
        <Button type="primary" onClick={handleAtualizar} icon={<SyncOutlined />}>
          {formatMessage({ id: "label.botao.atualizar" })}
        </Button>
      </MenuFixo>
      <Tabela fixarHeight={false} columns={columns} data={aprovacoes || []} />
    </Spin>
  );
};

export default MinhasAprovacoes;
