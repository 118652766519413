import { combineReducers } from '@reduxjs/toolkit';
import loginSlice from './app/loginSlice';
import contratoSlice from './contrato/contratoSlice';
import calendarioSlice from './calendario/calendarioSlice';
import alocacaoSlice from './alocacao/alocacaoSlice';
import aprovacaoSlice from './aprovacao/aprovacaoSlice';

const rootReducer = combineReducers({
  login: loginSlice,
  contrato: contratoSlice,
  calendario: calendarioSlice,
  alocacao: alocacaoSlice,
  aprovacao: aprovacaoSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;