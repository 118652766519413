import { HomeTwoTone, RightOutlined } from "@ant-design/icons";
import { Card, Col, Row, Space, Statistic } from "antd";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "src/components/Loader";
import { fetchContratoList } from "src/store/features/contrato/contratoSlice";
import { RootState } from "src/store/features/rootReducer";

const Contrato = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { actionContratoList, contratoList } = useSelector((state: RootState) => state.contrato);

  useEffect(() => {
    dispatch(fetchContratoList());
  }, [dispatch]);

  return (
    <>
      {actionContratoList === "BACKEND_PROCESSANDO" && <Loader />}
      <Row gutter={24} style={actionContratoList === "BACKEND_PROCESSANDO" ? { display: "none" } : {}}>
        {contratoList.map((contrato) => (
          <Col key={contrato.id} xs={24} sm={12} style={{ marginBottom: 23 }}>
            <Link to={`man?id=${contrato.id}`}>
              <Card
                title={contrato.nome_alocacao}
                hoverable
                bordered
                extra={<HomeTwoTone />}
                actions={[
                  <Space>
                    <RightOutlined />
                    {intl.formatMessage({ id: "label.detalhes" })}
                  </Space>,
                ]}
              >
                <Row gutter={24}>
                  <Col span={12}>
                    <Statistic
                      title="Unidade"
                      formatter={(value) => value}
                      value={String(contrato.nome_alocacao_unidade)}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      title="Valor contrato"
                      value={intl.formatNumber(contrato.indice_contrato, {
                        maximumFractionDigits: contrato.decimais,
                        minimumFractionDigits: contrato.decimais,
                      })}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      title="Índice Pago"
                      value={intl.formatNumber(contrato.indice_pago, {
                        maximumFractionDigits: contrato.decimais,
                        minimumFractionDigits: contrato.decimais,
                      })}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      title="Valor Pago"
                      valueStyle={{ color: "#3f8600" }}
                      value={intl.formatNumber(contrato.valor_pago, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      title="Vencido"
                      valueStyle={{ color: "#cf1322" }}
                      value={intl.formatNumber(contrato.valor_indice_contrato_posicao, {
                        maximumFractionDigits: contrato.decimais,
                        minimumFractionDigits: contrato.decimais,
                      })}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      title="Pendente"
                      value={intl.formatNumber(contrato.valor_indice_contrato_posicao_quitar, {
                        maximumFractionDigits: contrato.decimais,
                        minimumFractionDigits: contrato.decimais,
                      })}
                    />
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Contrato;
