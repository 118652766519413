import { Table } from "antd";
import { PaginationConfig } from "antd/es/pagination";
import { TableProps } from "antd/lib/table";
import React from "react";

const locale: any = {
  filterTitle: "Filtro",
  filterConfirm: "OK",
  filterReset: "Reiniciar",
  emptyText: "Sem registro",
};

interface TableProp {
  tableId?: string;
  data?: any;
  total?: number;
  current?: number;
  pageSize?: number;
  fixarHeight: boolean;
  ajusteFixar?: number;
  onChangePage?: (page: number, pageSize: number) => void;
}

type Props = TableProp & TableProps<any>;

export default class Tabela extends React.PureComponent<Props> {
  render() {
    const {
      columns,
      data,
      total,
      current,
      pageSize,
      onChangePage,
      fixarHeight,
      rowKey,
      ajusteFixar,
      ...restProps
    }: any = this.props;
    const pageSizeP = parseFloat(String(pageSize));
    const pagination: PaginationConfig | false =
      total > 0 && current > 0 && pageSizeP > 0
        ? {
            total: total,
            current: current,
            pageSize: parseFloat(String(pageSize)),
            onChange: (page: number, pageSize?: number) => onChangePage(page, pageSize || 15),
          }
        : false;

    return (
      <Table
        {...restProps}
        rowKey={rowKey ? rowKey : (record: any) => (record?.id ? record?.id : Math.random())}
        pagination={pagination}
        bordered
        size="small"
        columns={columns}
        dataSource={data || []}
        locale={locale}
        scroll={{
          x: "calc(100% - 1px)",
          y: fixarHeight ? `calc(100vh - ${277 + (ajusteFixar || 0)}px)` : "",
        }}
      />
    );
  }
}
