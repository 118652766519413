import type { CardProps } from "antd";
import { Card, Spin } from "antd";
import React, { PropsWithChildren } from "react";

interface Props {
  isLoading: boolean;
  heightFileList?: string;
  className?: string;
}
export const FileWrapper = ({
  isLoading,
  heightFileList,
  className,
  children,
  ...rest
}: PropsWithChildren<Props> & CardProps) => {
  return (
    <Spin spinning={isLoading}>
      <Card {...rest} className={`card-body-mb-10 card-body-mt-10 ${className || ""}`} bodyStyle={{ padding: 10 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: heightFileList || "calc(100vh - 210px)",
            overflow: "hidden",
          }}
        >
          {children}
        </div>
      </Card>
    </Spin>
  );
};
