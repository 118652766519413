import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

const FormatarData = function(
  { value = "", formatIn = "YYYY-MM-DD", formatOut = "DD/MM/YYYY" },
  ref: any
) {
  const [data, setData] = useState('');
  useEffect(() => {
    const dt = dayjs(value, formatIn);
    if (dt.isValid()) {
      setData(dt.format(formatOut));
    } else {
      setData("");
    }
  }, [value, formatIn, formatOut]);

  return <>{data}</>;
};

export default React.forwardRef(FormatarData);
