import { FileSearchOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Descriptions, message, Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Loader from 'src/components/Loader';
import VirtualTable from 'src/components/VirtualTable';
import { apiMinhasAlocacoes, apiRelatorioPrevistoRealizado } from 'src/services/funcoesService';
import { Ent_Alocacao } from 'src/services/interface';
import { convertToFloat } from 'src/util/geral';

const FisicoFinanceiro = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [somaIndiceOrcado, setSomaIndiceOrcado] = useState(0);
  const [somaIndice, setSomaIndice] = useState(0);
  const [somaValorOrcado, setSomaValorOrcado] = useState(0);
  const [somaValor, setSomaValor] = useState(0);
  const [alocacaoId, setAlocacaoId] = useState<SelectValue>();
  const [alocacoes, setAlocacoes] = useState<Ent_Alocacao[]>([]);
  const [alocacao, setAlocacao] = useState<Ent_Alocacao>();
  const [data, setData] = useState([]);

  const columns: ColumnsType<any> = [
    {
      title: intl.formatMessage({ id: 'label.setor' }),
      dataIndex: 'nome_setor',
      width: 250,
    },
    {
      title: intl.formatMessage({ id: 'label.departamento' }),
      dataIndex: 'nome_departamento',
      width: 250,
    },
    {
      title: intl.formatMessage({ id: 'label.subdepartamento' }),
      dataIndex: 'nome_subdepartamento',
      width: 250,
    },
    {
      title: intl.formatMessage({ id: 'label.indice_orcado' }),
      dataIndex: 'indice_valor_orcado',
      render: (text) =>
        intl.formatNumber(text, {
          minimumFractionDigits: alocacao?.indice?.decimais || 5,
          maximumFractionDigits: alocacao?.indice?.decimais || 5,
        }),
      align: 'right',
      width: 150,
    },
    {
      title: intl.formatMessage({ id: 'label.indice_realizado' }),
      dataIndex: 'indice_total',
      render: (text) =>
        intl.formatNumber(text, {
          minimumFractionDigits: alocacao?.indice?.decimais || 5,
          maximumFractionDigits: alocacao?.indice?.decimais || 5,
        }),
      align: 'right',
      width: 150,
    },
  ];

  useEffect(() => {
    const buscar = async () => {
      setLoading(true);
      try {
        const data = await apiMinhasAlocacoes();
        setAlocacoes(data);
      } catch (err: any) {
        setAlocacoes([]);
      } finally {
        setLoading(false);
      }
    };
    buscar();
  }, []);

  async function buscar() {
    if (!alocacaoId || !(alocacaoId > 0)) {
      message.error(intl.formatMessage({ id: 'label.mensagem.alocacao_nao_informada' }));
      return false;
    }
    setLoading(true);
    setData([]);
    try {
      const data = await apiRelatorioPrevistoRealizado(alocacaoId as number);
      if (data && data.list && data.list.length) {
        let indiceOrcado = 0;
        let indice = 0;
        let valorOrcado = 0;
        let valor = 0;
        data.list?.map((item: any) => {
          indiceOrcado += convertToFloat(item.indice_valor_orcado);
          valorOrcado += convertToFloat(item.valor_orcado);
          indice += convertToFloat(item.indice_total);
          valor += convertToFloat(item.total);
          return true;
        });
        setSomaIndiceOrcado(convertToFloat(indiceOrcado));
        setSomaValorOrcado(convertToFloat(valorOrcado));
        setSomaIndice(convertToFloat(indice));
        setSomaValor(convertToFloat(valor));
        setData(data.list);
        setAlocacao(data.alocacao);
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (error) {
      setData([]);
      setLoading(false);
    }
  }

  function totalizador() {
    return (
      <Descriptions size='small' column={1} bordered style={{ width: 300, textAlign: 'end', marginTop: 20 }}>
        <Descriptions.Item
          label={intl.formatMessage({
            id: 'label.indice_valor_orcado',
          })}
        >
          {intl.formatNumber(somaIndiceOrcado, {
            minimumFractionDigits: alocacao?.indice?.decimais || 2,
          })}
        </Descriptions.Item>
        <Descriptions.Item
          label={intl.formatMessage({
            id: 'label.valor_orcado',
          })}
        >
          {intl.formatNumber(somaValorOrcado, {
            minimumFractionDigits: 2,
          })}
        </Descriptions.Item>
        <Descriptions.Item
          label={intl.formatMessage({
            id: 'label.indice',
          })}
        >
          {intl.formatNumber(somaIndice, {
            minimumFractionDigits: alocacao?.indice?.decimais || 2,
          })}
        </Descriptions.Item>
        <Descriptions.Item
          label={intl.formatMessage({
            id: 'label.valor',
          })}
        >
          {intl.formatNumber(somaValor, {
            minimumFractionDigits: 2,
          })}
        </Descriptions.Item>
      </Descriptions>
    );
  }

  return (
    <>
      <Alert
        message={intl.formatMessage({ id: 'pagina.relatorio.fisico_financeiro' })}
        description={intl.formatMessage({ id: 'label.relatorio_informacao_fisico_financeiro' })}
        type='info'
        showIcon
      />
      {loading && <Loader />}
      <div style={loading ? { display: 'none' } : {}}>
        <Card
          className='hidden-body'
          size='small'
          title={
            <Select
              placeholder={intl.formatMessage({ id: 'label.selecione_alocacao' })}
              style={{ width: '100%' }}
              showSearch
              allowClear
              onChange={setAlocacaoId}
              size='large'
            >
              {alocacoes?.map((aloc: any) => (
                <Select.Option key={aloc.id} value={aloc.id}>
                  {aloc.nome}
                </Select.Option>
              ))}
            </Select>
          }
          extra={
            <Button
              type='primary'
              ghost
              shape='circle'
              icon={<FileSearchOutlined />}
              onClick={buscar}
              style={{ margin: 3, marginRight: 0 }}
            />
          }
        >
          <VirtualTable bordered columns={columns} dataSource={data} size='small' />
        </Card>
        {totalizador()}
      </div>
    </>
  );
};

export default FisicoFinanceiro;
